import { useAlert } from "@/app/contexts/AlertContext"
import { FastFillText } from "@/app/features/reports/components/fast-fill-text"
import { TestSuites } from "@/app/features/reports/types/test-specification"
import convertFormToTestItem from "@/app/features/reports/utils/convertFormToTestItem"
import convertInspectionToFormValues from "@/app/features/reports/utils/convertInspectionToFormValues"
import { FileUploader } from "@/components/image-upload/FileUploader"
import { MediaInfo } from "@/components/image-upload/types"
import RaytdResultPicker from "@/components/raytd/raytd-result-picker"
import { Badge } from "@/components/ui/badge"
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { ScrollArea } from "@/components/ui/scroll-area"
import findRegistrationIssues from "@/utils/findRegistrationIssues"
import { getDefaultFieldSettings } from '@/utils/getDefaultFieldSettings'
import { Assessment, AssetEntity, TestSpecificationsEntity, useAddTestSpecificationFastFillMutation, useCreateTestItemMutation, useGetElementGroupsQuery, useUpdateTestItemMutation } from '@app.raytd.com/store'
import { skipToken } from "@reduxjs/toolkit/query"
import { AnimatePresence, motion } from "framer-motion"
import { Loader2 } from 'lucide-react'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { FormProvider, useForm, UseFormReturn, useFormState, useWatch } from "react-hook-form"
import { TestItem, TestSuite } from 'store/src/lib/tests/entity'
import { v4 as uuid } from 'uuid'
import { useInspectionData } from "../hooks/useInspectionData"
import { useSpacesData } from "../hooks/useSpacesData"
import defaultValues from "../types/form-default-values"
import { type TestFormValues, type TestStatus } from '../types/test-form'
import filterActiveCustomFields from '../utils/filterActiveCustomFields'
import { useDynamicResolver, useEnchancedDynamicResolver } from '../utils/testFormValidator'
import { AssetDisplay } from "./asset-display"
import AuthorTimestamp from "./author-timestamp"
import { CustomFieldsContainer } from "./custom-fields-container"
import { ElementSelector } from "./element-selector"
import { FormLabelWithIndicator } from './form-label-with-indicator'
import TestSpecificationSelector from "./hierarchical-test-select"
import { InspectionSelector } from "./inspection-selector"
import { SpaceSelector } from "./space-selector"
import { StatusIndicator, getStatusLabel } from "./status-indicator"
import InspectionFormSkeleton from './test-loading-skeleton'
import { toast } from "sonner"
import { useFormErrorNavigation } from "@/app/features/reports/components/form-error-nav"
import { cn } from "@/lib/utils"

const getTestSuiteForSpecification = (testSuites, specificationId) => {
  console.debug('selectedTestSpecificationId', specificationId);

  for (const suite of testSuites) {
    const specification = suite.specifications.find(spec => spec.id === specificationId);
    if (specification) {
      return { suite: suite, specification: specification } as unknown as { suite: TestSuite, specification: TestSpecificationsEntity };
    }
  }
  return undefined;
}

interface TestEditFormProps {
  inspections: Assessment[],
  testItem: TestItem,
  assets: AssetEntity[],
  testSuites: TestSuites[],
  onSubmit: (data: TestFormValues) => void
  onDirtyChange?: (isDirty: boolean) => void
  initialData?: Partial<TestFormValues>
  saveStatus?: string
}

const TestEditForm = forwardRef<UseFormReturn<TestFormValues>, TestEditFormProps>(({
  inspections,
  assets,
  testSuites,
  testItem,
  onSubmit,
  onDirtyChange,
  initialData,
  saveStatus
}, ref) => {

  const [testState, setTestState] = useState({
    selectedTestSpecificationId: null as number | null,
    isTestSelected: false
  });

  useEffect(() => {
    console.log('TestEditForm mounted');
    
    return () => {
      console.log('TestEditForm unmounted');
    };
  }, []);

  const { showAlertDialog } = useAlert();

  const formId = useRef<string>(undefined);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [isExistingTest, setIsExistingTest] = useState(false);

  const [createTest, { isLoading: isCreatingTest }] = useCreateTestItemMutation();
  const [updateTest, { isLoading: isUpdating }] = useUpdateTestItemMutation();

  // const resolver = useDynamicResolver(testSuites);
  const resolver = useEnchancedDynamicResolver(testSuites);

  const methods = useForm<TestFormValues>({
    // resolver: createThrottledResolver(createDynamicResolver(testSuites)),
    resolver: resolver,
    defaultValues: {
      ...defaultValues
    },
    mode: "onChange",
  });

  const asset_id = useWatch({
    control: methods.control,
    name: 'assetId',
  });

  const { groupedInspections } = useInspectionData(inspections as any, assets);
  const { spaces: organizedSpaces, isLoading: isSpacesLoading } = useSpacesData(asset_id);

  console.debug('dialog', { organizedSpaces, asset_id, groupedInspections });

  const isLoadingTestSuiteData = false;

  const selectedTestSuite = useMemo(() => {
    if (!testSuites) return undefined;
    console.debug('selectedTestSuite updated', { testSuites, testState });
    return getTestSuiteForSpecification(testSuites, testState.selectedTestSpecificationId);
  }, [testSuites, testState.selectedTestSpecificationId]);

  useEffect(() => {
    if (selectedTestSuite) {
      console.debug('Selected test suite', selectedTestSuite);
      methods.trigger();
    }
  }, [selectedTestSuite]);

  const assessmentId = useWatch({
    control: methods.control,
    name: "assessmentId",
  });

  const selectedAssessment = useMemo(() => {
    return inspections.find(i => i.id === assessmentId);
  }, [inspections, assessmentId]);

  // useEffect(() => {
  //   console.debug('form state dirty', methods.formState.dirtyFields);
  // }, [methods.formState.dirtyFields]);

  // const { data: assessmentElementGroups, isLoading: elementGroupsAreLoading } = useGetElementTreesQuery(
  //   selectedAssessment?.elementGroupId ?? skipToken
  // );

  const { data: assessmentElementTree, isLoading: elementTreeIsLoading } = useGetElementGroupsQuery(
    selectedAssessment?.elementGroupId ?? skipToken
  );

  const elementGroups = useMemo(() => {
    if (!assessmentElementTree || !selectedTestSuite || !selectedAssessment) {
      return [];
    }

    const allowedGroups = Object.values(assessmentElementTree).filter(group =>
      group.test_suite_types?.includes(selectedTestSuite.suite.type)
    );

    console.debug('Allowed groups', allowedGroups);

    return allowedGroups;

    // const treeIds = allowedGroups.map(group => group.id);
    // return treeIds;
  }, [assessmentElementTree, selectedTestSuite, selectedAssessment]);

  const [isFormReady, setIsFormReady] = useState(false);

  const {
    currentErrorIndex, errorCount, handleNextError, handlePrevError, ErrorNavigation
  } = useFormErrorNavigation({ methods, formRef, isFormReady });

  // Add this effect to track when the form is fully ready
  useEffect(() => {
    if (
      !isLoadingTestSuiteData &&
      selectedTestSuite &&
      !isSpacesLoading &&
      !elementTreeIsLoading &&
      formRef.current
    ) {
      // Give React a chance to finish rendering all fields
      const timeoutId = setTimeout(() => {
        setIsFormReady(true);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isLoadingTestSuiteData, isSpacesLoading, elementTreeIsLoading, selectedTestSuite]);

  const resetForm = useCallback((initialData: Partial<TestFormValues & { formId: string; }> = {}) => {

    const testSuite = getTestSuiteForSpecification(testSuites, initialData?.test_specification_id);
    console.debug('Resetting form', { initialData, testSuite });

    if (testSuite) {
      const custom = filterActiveCustomFields(initialData, testSuite.suite);

      console.debug('Resetting form with custom fields', { custom });

      methods.reset({
        ...defaultValues,
        ...custom,

      });

    } else {

      methods.reset({
        ...defaultValues,
        ...initialData,

      });
    }

    setTestState({
      selectedTestSpecificationId: initialData?.test_specification_id ? Number(initialData?.test_specification_id) : null,
      isTestSelected: !!initialData?.test_specification_id
    });

    formId.current = initialData?.formId ?? uuid();

    methods.trigger();

  }, [methods, testSuites]);

  const handleSave = useCallback(async (data: TestFormValues) => {

    const saveValues = {
      ...convertFormToTestItem(data),
      assessmentTestId: testItem?.assessmentTestId ?? undefined,
      formId: formId.current
    }
    console.debug('Saving:', data.status, { formId, draftData: data, saveValues });

    try {

      let result = undefined;

      if (isExistingTest) {

        result = await updateTest(saveValues).unwrap();
        console.debug('Test updated', result);
        toast.success('Test updated');

      } else {
        result = await createTest(saveValues).unwrap();
        console.debug('Test created', result);
        toast.success('Test created');

      }
      resetForm(convertInspectionToFormValues(result));
      onSubmit?.(result);
    } catch (error) {

      console.debug('Error saving test', error);
      showAlertDialog({
        title: 'Error saving test',
        description: 'An error occurred while saving the test. Please try again later.',
        confirmText: 'OK',
        cancelText: undefined
      });
    }
  }, [methods, testItem, isExistingTest, createTest, resetForm, onSubmit, showAlertDialog]);

  const handleStatusChange = useCallback((status: TestStatus) => {
    methods.setValue("status", status);

    if (status === 'published' || status === 'qa') {
      methods.handleSubmit(handleSave)();
    } else {
      const data = methods.getValues();
      handleSave(data);
    }

  }, [methods, handleSave]);

  const handleTestSelect = useCallback((value: number | null) => {
    setTestState(prevState => ({
      ...prevState,
      selectedTestSpecificationId: value,
      isTestSelected: !!value
    }));
    if (value) {
      methods.setValue("test_specification_id", value);
    } else {
      methods.setValue("test_specification_id", undefined);
    }
  }, [methods]);

  // useImperativeHandle(ref, () => ({
  //   resetForm: resetForm
  // }), [resetForm]);

  useImperativeHandle(ref, () => methods);

  useEffect(() => {
    onDirtyChange?.(isFormDirty);
  }, [isFormDirty, onDirtyChange]);

  useEffect(() => {

    if (isFormDirty) {
      console.debug('Form is dirty skipping update');
      return;
    }

    if (testItem) {

      const initialData = convertInspectionToFormValues(testItem);
      console.debug('testItem initialData', initialData);
      resetForm(initialData);

    } else {
      console.debug('testItem 2');
      resetForm();
    }

    setIsExistingTest(!!testItem?.assessmentTestId);

    console.debug('Form reset', { testItem, initialData });
    methods.trigger();

  }, [testItem, methods, resetForm, isFormDirty]);

  const formState = useFormState({
    control: methods.control
  });

  useEffect(() => {
    const { errors, isValid, dirtyFields, touchedFields, isDirty } = formState;
    const values = methods.getValues();

    const requiredFieldsFilled = !!values.assessmentId &&
      !!values.test_specification_id &&
      !!values.assetId;

    const issues = findRegistrationIssues(formState.defaultValues, values);

    if (Object.keys(issues)?.length > 0) {
      console.debug('Registration issues', issues);
    }

    console.debug('Are reqd fields filled', {
      isValid, requiredFieldsFilled, errors,
      isDirty, dirtyFields,
      values: values,
      defaultValues: formState.defaultValues
    });

    setIsFormValid(isValid);
    setIsFormDirty(isDirty);
    setAreRequiredFieldsFilled(requiredFieldsFilled);

  }, [formState, methods]);

  const [addTestSpecificationFastFill, { isLoading: isAddingFastFill }] = useAddTestSpecificationFastFillMutation();

  const handleFastFillAdd = useCallback(async (type: 'observations' | 'recommendations', text: string) => {
    if (!selectedTestSuite.suite.allow_add_fast_fills) {
      toast.error('Fast fill is not allowed for this test suite');
      return;
    }
    try {
      await addTestSpecificationFastFill({
        testSpecificationId: selectedTestSuite.specification.id,
        data: {
          type,
          description: text
        }
      }).unwrap();

      toast.success('Fast fill added');
    } catch (err) {
      console.error('Error adding fast fill', err);
      toast.error('Error adding fast fill');
    }

  }, [selectedTestSuite, addTestSpecificationFastFill]);

  const getFieldVisibility = useCallback((fieldName: string) => {
    if (!selectedTestSuite || !selectedTestSuite.suite.settings) return true;
    const defaultSettings = getDefaultFieldSettings(fieldName, selectedTestSuite?.suite?.type);
    const field = selectedTestSuite.suite.settings.find(s => s.field_name === fieldName);
    // return field ? (field.active ?? true) : true;
    return field ? field.active : defaultSettings.active;
  }, [selectedTestSuite]);

  const getFieldRequirement = useCallback((fieldName: string) => {

    if (['assessmentId', 'assetId', 'test_specification_id'].includes(fieldName)) return true;

    if (!selectedTestSuite || !selectedTestSuite.suite.settings) return false;
    const defaultSettings = getDefaultFieldSettings(fieldName, selectedTestSuite?.suite?.type);
    const field = selectedTestSuite.suite.settings.find(s => s.field_name === fieldName);
    return field ? field.required : defaultSettings.required
  }, [selectedTestSuite]);


  const preventEnterSubmit = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter" && e.target instanceof HTMLInputElement) {
      e.preventDefault();
    }
  }, []);

  const handleTriggerValidation = useCallback(() => {
    methods.trigger();
  }, [methods]);

  const handleMediaChange = useCallback((updatedMedia: MediaInfo[]) => {
    const currentFiles = methods.getValues('images').filter(img => img instanceof File);
    methods.setValue('images', [...updatedMedia, ...currentFiles], {
      shouldValidate: true,
      shouldDirty: true
    });
  }, [methods]);

  if (isSpacesLoading || elementTreeIsLoading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <InspectionFormSkeleton />
      </motion.div>
    )
  }

  return (
    (
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "100%" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: .2 }}
        className="h-full"
      >
        <FormProvider {...methods}>
          <Form {...methods}>
            <form ref={formRef}
              className="flex flex-col h-full"
              onKeyDown={preventEnterSubmit}
              onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="pb-0 flex flex-row justify-between items-center pt-6 px-6">
                <div className="text-2xl font-semibold leading-none tracking-tight">
                  {isExistingTest ? `Test (${getStatusLabel(testItem?.status as TestStatus)})` : 'New Test '}
                </div>
                {isFormDirty && (<Badge className="ml-2" variant="secondary" onClick={() => methods.trigger()}>Modified</Badge>)}

                <StatusIndicator
                  currentStatus={methods.watch("status")}
                  onStatusChange={handleStatusChange}
                  saveStatus={saveStatus}
                  isFormValid={isFormValid}
                  areRequiredFieldsFilled={areRequiredFieldsFilled} />
              </div>

              <ErrorNavigation />

              <div className={cn(
                "border-b border-zinc-200 py-4 ",
                !testItem?.assessmentTestId && "py-1"
              )}>

                {testItem?.assessmentTestId && (
                  <div className="flex flex-row justify-between gap-2 px-6 text-zinc-600">

                    <AuthorTimestamp
                      author={testItem.creator}
                      timestamp={testItem.created_at}
                      label="Created by"
                    />

                    {testItem.created_at !== testItem.updated_at && (
                      <AuthorTimestamp
                        author={testItem.updater}
                        timestamp={testItem.updated_at}
                        label="Last updated"
                      />
                    )}

                  </div>
                )}

              </div>

              <ScrollArea className="flex-1">
                <div className=" p-6 space-y-6">
                  <InspectionSelector
                    name="assessmentId"
                    label="Inspection"
                    required={true}
                    inspections={groupedInspections}
                    editable={!isExistingTest} />

                  <AssetDisplay
                    name="assetId"
                    label="Asset"
                    required={true}
                    inspections={groupedInspections} />

                  <TestSpecificationSelector
                    name="test_specification_id"
                    label="Test"
                    testSuites={testSuites}
                    onSelect={handleTestSelect}
                    labelComponent={<FormLabelWithIndicator label="Test" isValid={!!methods.watch("test_specification_id")} required={true} />} />

                  {isLoadingTestSuiteData && (
                    <div className="flex justify-center items-center py-4">
                      <Loader2 className="h-6 w-6 animate-spin" />
                    </div>
                  )}

                  <AnimatePresence>
                    {selectedTestSuite && areRequiredFieldsFilled && !isLoadingTestSuiteData && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: .5 }}
                        className="space-y-6"
                      >
                        {/* Render form fields based on visibility and requirement */}

                        {getFieldVisibility('location') && (
                          <FormField
                            control={methods.control}
                            name="building_id"
                            render={({ field, fieldState }) => (
                              <FormItem>
                                <FormLabelWithIndicator
                                  label="Location"
                                  isValid={!fieldState.invalid}
                                  required={getFieldRequirement('location')}
                                />
                                <FormControl>
                                  <SpaceSelector
                                    spaces={organizedSpaces as any}
                                    disabled={!!!asset_id}
                                    isLoading={false}
                                    defaultValues={{
                                      building_id: field.value,
                                      level_id: methods.watch('level_id'),
                                      area_id: methods.watch('area_id')
                                    }}
                                    onSelect={handleTriggerValidation}
                                  />

                                </FormControl>
                              </FormItem>
                            )} />
                        )}

                        {getFieldVisibility('element') && (
                          <FormField
                            control={methods.control}
                            name="element"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabelWithIndicator label="Element" isValid={!!field.value} required={getFieldRequirement('element')} />
                                <FormControl>
                                  <ElementSelector
                                    name="element"
                                    elements={elementGroups}
                                    onSelect={(value) => field.onChange(value)}
                                    isLoading={false} />
                                </FormControl>
                              </FormItem>
                            )} />
                        )}

                        {getFieldVisibility('element_name') && (
                          <FormField
                            control={methods.control}
                            name="element_name"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabelWithIndicator label="Element Name" isValid={!!field.value} required={getFieldRequirement('element_name')} />
                                <FormControl>
                                  <Input {...field} placeholder="Enter element name" />
                                </FormControl>
                              </FormItem>
                            )} />
                        )}

                        {getFieldVisibility('element_id') && (
                          <FormField
                            control={methods.control}
                            name="element_id"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabelWithIndicator label="Element ID" isValid={!!field.value} required={getFieldRequirement('element_id')} />
                                <FormControl>
                                  <Input {...field} placeholder="Enter element ID" />
                                </FormControl>
                              </FormItem>
                            )} />
                        )}

                        {getFieldVisibility('rating') && (

                          <FormField
                            control={methods.control}
                            name="result"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabelWithIndicator label="Result" isValid={!!field.value} required={getFieldRequirement('rating')} />
                                <RaytdResultPicker
                                  name="result"
                                  onChange={(value) => field.onChange(value)}
                                  onBlur={field.onBlur}
                                  value={field.value}
                                  testSuite={selectedTestSuite.suite} />
                              </FormItem>
                            )} />

                        )}

                        {getFieldVisibility('observations') && (
                          <FormField
                            control={methods.control}
                            name="observations"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabelWithIndicator label="Observations" isValid={!!field.value} required={getFieldRequirement('observations')} />
                                <FormControl>

                                  <FastFillText
                                    control={methods.control}
                                    name="observations"
                                    fastFills={selectedTestSuite?.specification?.observations ?? []}
                                    enableFastFill={selectedTestSuite?.suite.type === 'compliance'}
                                    allowAdd={selectedTestSuite?.suite.allow_add_fast_fills}
                                    onCreateOption={(value) => handleFastFillAdd('observations', value)}
                                  />
                                </FormControl>
                              </FormItem>
                            )} />
                        )}

                        {getFieldVisibility('recommendations') && (
                          <FormField
                            control={methods.control}
                            name="recommendations"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabelWithIndicator label="Recommendations" isValid={!!field.value} required={getFieldRequirement('recommendations')} />
                                <FormControl>
                                  <FastFillText
                                    control={methods.control}
                                    name="recommendations"
                                    fastFills={selectedTestSuite?.specification?.recommendations ?? []}
                                    enableFastFill={selectedTestSuite?.suite.type === 'compliance'}
                                    allowAdd={selectedTestSuite?.suite.allow_add_fast_fills}
                                    onCreateOption={(value) => handleFastFillAdd('recommendations', value)}
                                  />
                                </FormControl>
                              </FormItem>
                            )} />

                        )}

                        {getFieldVisibility('photographs') && (

                          <FormField
                            control={methods.control}
                            name="images"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabelWithIndicator
                                  label="Photographs"
                                  // isValid={!!methods.watch("images")} 
                                  isValid={!methods.getFieldState("images").invalid}
                                  required={getFieldRequirement('photographs')}
                                />
                                <FormControl>

                                  <FileUploader
                                    ref={field.ref}
                                    formId={formId.current}
                                    value={field.value.filter((img): img is MediaInfo => 'id' in img)}
                                    // onChange={field.onChange}
                                    onChange={handleMediaChange}
                                    maxFiles={50}
                                    maxSize={20000000}
                                    acceptedTypes={["image/jpeg", "image/jpg", "image/png"]}
                                    disabled={methods.formState.isSubmitting}
                                  />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        )}



                        {testState.selectedTestSpecificationId && (
                          <CustomFieldsContainer
                            name="custom"
                            testSuite={selectedTestSuite.suite} />
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </ScrollArea>

            </form>
          </Form>
        </FormProvider>
      </motion.div>
    )
  );
});

export { TestEditForm }

