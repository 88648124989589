import { ExcludedTestsBadge } from "@/app/features/reports/components/excluded-tests-badge"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { ArrowUpDown } from 'lucide-react'
import React, { SetStateAction, useMemo, useState } from 'react'
import { FilterCounts, Filters, FilterTypes, SelectedFilters } from '../types/filters'
import { FilterDrawer } from './filter-drawer'
import { useReportViewContext } from "@/app/features/reports/components/report-viewer-context"

interface FilterSectionProps {
  showSearch?: boolean;
}

export function FilterSection({
  showSearch = true
}: FilterSectionProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { filters: { filteredCounts, unfilteredCounts, updateFilters, selectedFilters, facets: filters } } = useReportViewContext();

  const activeFilters = useMemo(() => {
    return Object.entries(selectedFilters)
      .flatMap(([category, filters]) => filters.map(filter => `${category}:${filter}`)).length > 0
  }, [selectedFilters]);

  return (
    <div className="space-y-4 flex justify-center">
      <div className="flex items-center space-x-4">
        <div className="flex flex-1">

        </div>

        <div className="flex items-center space-x-2">


          <Button variant="outline" size="icon">
            <ArrowUpDown className="h-4 w-4" />
          </Button>
          <Button
            variant={activeFilters ? "default" : "outline"}
            onClick={() => setIsDrawerOpen(true)}
          >
            Filter
          </Button>
          {showSearch && <Input placeholder="Search..." className="w-[200px]" />}
        </div>
      </div>

      <FilterDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        selectedFilters={selectedFilters}
        setSelectedFilters={updateFilters}
        filters={filters}
        filterCounts={unfilteredCounts}
        filteredCounts={filteredCounts}
      />
    </div>
  )
}
