import { FilterKeys } from '@/app/features/reports/types/filters';
import { FilterItem, ReportTestItem } from '../utils/entities'
import sortByName from './sortByName'

type PropertyKey<T> = keyof T;
type DisplayValueFn = (value: any) => string;

interface FacetOptions {
  key: FilterKeys
  idProperty?: PropertyKey<ReportTestItem>
  nameProperty?: PropertyKey<ReportTestItem>
  emptyTitle?: string
  displayValue?: DisplayValueFn
  customOrder?: string[]
}

const createFilterItem = (
  key: FilterKeys,
  id: string,
  name: string | null,
  emptyTitle: string,
  displayValue?: DisplayValueFn
): FilterItem => ({
  key,
  id,
  name: displayValue ? displayValue(name) : (name ?? emptyTitle),
  //name: name ?? emptyTitle,
  children: [],
  count: 1
})

const convertSinglePropertyToFilters = (
  testItems: ReportTestItem[],
  {
    key = 'status',
    idProperty,
    nameProperty,
    emptyTitle = '(None)',
    displayValue,
    customOrder
  }: FacetOptions
): FilterItem[] => {
  if (!testItems?.length) return []

  const filters = testItems.reduce((acc, item) => {
    const id = (idProperty ? item[idProperty] : item[key])?.toString().toLowerCase() ?? 'null'
    const title = nameProperty ? item[nameProperty] : item[key]
    
    const existing = acc.get(id)
    if (existing) {
      existing.count++
      return acc
    }

    acc.set(id, createFilterItem(
      key, 
      id, 
      title as string | null, 
      emptyTitle, 
      displayValue
    ))
    
    return acc
  }, new Map<string, FilterItem>())

  if (customOrder) {
    return customOrder.map((id) => filters.get(id)).filter((f) => f) as FilterItem[]
  }

  return Array.from(filters.values()).sort(sortByName)
}

export default convertSinglePropertyToFilters;