import React, { ReactNode } from 'react';
import { useReportViewContext } from "@/app/features/reports/components/report-viewer-context";
import { cn } from "@/lib/utils";

interface HighlightTextProps {
    children: ReactNode;
    className?: string;
}

const HighlightText: React.FC<HighlightTextProps> = ({ children, className }) => {
    const { filters: { filterInput } } = useReportViewContext();
    const isFilterActive = filterInput && filterInput.length > 0;

    const highlightNode = (node: ReactNode): ReactNode => {
        // If no filter is active, return the original node
        if (!isFilterActive) return node;

        // Handle string nodes
        if (typeof node === 'string') {
            const lines = (node as string)?.replace(/\\n/g, '\n').trim();
            const parts = lines.split(new RegExp(`(${filterInput})`, 'gi'));
            return parts.map((part, index) => 
                part.toLowerCase() === filterInput.toLowerCase() 
                    ? <span key={index} className={cn("bg-yellow-300 py-0.5 px-1 rounded-md", className)}>{part}</span>
                    : part
            );
        }

        // Handle React elements
        if (React.isValidElement(node)) {
            return React.cloneElement(node, {
                ...node.props,
                children: highlightNode(node.props.children)
            });
        }

        // Handle arrays of nodes
        if (Array.isArray(node)) {
            return node.map((child, index) => (
                <React.Fragment key={index}>
                    {highlightNode(child)}
                </React.Fragment>
            ));
        }

        // Return unchanged for other types
        return node;
    };

    return <>{highlightNode(children)}</>;
};

export default HighlightText;