import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

interface TestTileSkeletonProps {
  className?: string;
  width?: string;
}

export const TestTileSkeleton = ({ className, width = '100%' }: TestTileSkeletonProps) => {
  return (
    <div className={cn("p-4 border rounded-lg space-y-4", className)} style={{ width: width }}>
      {/* Header with number and path */}
      <div className="flex items-center gap-2 mb-4">
        <Skeleton className="h-6 w-12" /> {/* Number */}
        <div className="flex items-center gap-1">
          <Skeleton className="h-6 w-20" /> {/* Ground */}
          <Skeleton className="h-6 w-20" /> {/* Footpath */}
        </div>
        <Skeleton className="h-6 w-16 ml-auto" /> {/* Score */}
      </div>

      {/* Test category and title */}
      <div className="space-y-2">
        <Skeleton className="h-4 w-64" /> {/* Test category */}
        <Skeleton className="h-5 w-full" /> {/* Test title */}
      </div>

      {/* Observations and Recommendations */}
      <div className="space-y-4">
        <div className="space-y-2">
          <Skeleton className="h-4 w-24" /> {/* Observations label */}
          <Skeleton className="h-4 w-full" /> {/* Observation text */}
        </div>
        <div className="space-y-2">
          <Skeleton className="h-4 w-32" /> {/* Recommendations label */}
          <Skeleton className="h-4 w-full" /> {/* Recommendation text */}
        </div>
      </div>

      {/* Photo placeholder */}
      <Skeleton className="h-24 w-24 rounded-md" />

      {/* Footer with controls */}
      <div className="flex items-center justify-between mt-4">
        <Skeleton className="h-8 w-16" /> {/* Edit button */}
        <div className="flex items-center gap-2">
          <Skeleton className="h-8 w-20" /> {/* Toggle */}
          <Skeleton className="h-8 w-40" /> {/* Category tag */}
          <Skeleton className="h-8 w-8 rounded-full" /> {/* Avatar */}
          <Skeleton className="h-8 w-16" /> {/* QA button */}
        </div>
      </div>
    </div>
  );
};
