import * as z from "zod"

export const ImageSchema = z.object({
  id: z.number().optional(),
  uuid: z.string().optional(),
  isTemporary: z.boolean(),
  name: z.string(),
  size: z.number(),
  mime_type: z.string(),
  uri: z.string(),
  thumbnail_url: z.string().optional(),
  preview_url: z.string().optional(),
  deleted: z.boolean().optional()
});

export const testFormSchema = z.object({
  formId: z.string().optional().nullable(),
  assessmentId: z.number().min(1, "Inspection is required"),
  test_specification_id: z.number().min(1, "Test is required"),
  assetId: z.number().min(1, "Asset is required"),
  location: z.string().min(1, "Location is required"),
  building_id: z.number().optional(),
  level_id: z.number().optional(),
  area_id: z.number().optional(),
  element: z.number().min(1, "Element is required"),
  element_id: z.string().min(1, "Element Identifier is required"),
  element_name: z.string().min(1, "Element Name is required"),
  observations: z.string().optional(),
  recommendations: z.string().optional(),
  result: z.enum(["very_poor", "poor", "fair", "good", "very_good"]).optional(),
  status: z.enum(["archived", "draft", "qa", "published"]),
  images: z.array(ImageSchema).optional(),
  custom: z.record(z.string(), z.string()) // Use z.record for dynamic keys

})

export type TestFormValues = z.infer<typeof testFormSchema>

export type TestStatus = "archived" | "draft" | "qa" | "published"

