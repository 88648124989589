import { cn } from '@/lib/utils';
import React, { ReactNode, useCallback } from 'react';
import TextWithBreaks from '@/components/raytd/convert-line-break';
import { useReportViewContext } from '../report-viewer-context';
import HighlightText from './highlight-text';


type TextTileProps = {
    title: string;
    children: ReactNode;
    newLines?: boolean;
    className?: string;
}

const TextTile: React.FC<TextTileProps> = React.memo(({ title, children, className, newLines = false }) => {

    const renderContent = useCallback(() => {

        // if (typeof children === 'string') {
        //     return <HighlightText>{children}</HighlightText>;
        // }

        return <HighlightText>{children}</HighlightText>;
    }, [children]);

    return (
        <div className={cn("flex flex-col gap-0.5 break-inside-avoid-column",
            newLines ? 'whitespace-pre-wrap' : 'whitespace-normal',
            className
        )}>
            <div className="text-xs text-zinc-500 tracking-tight">
                {title}
            </div>
            <div className="text-sm text-zinc-950">
                {newLines
                    ? <TextWithBreaks>{renderContent()}</TextWithBreaks>
                    : renderContent()
                }
            </div>
        </div>
    );
});

export default TextTile;