import { LoadingState } from '@/app/features/reports/components/loading-state';
import useReportData, { ReportFilters } from '@/app/features/reports/hooks/useReportData';
import ErrorState from '@/components/raytd/error-state';
import React, { createContext, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { ReportEntity } from 'store/src/lib/services/types';
import { BookMark, ExpandedItemsState, ReportGroupModes, ReportHeaderModes, ReportViewModes } from '../types';
import { FilterCounts } from '../types/filters';
import { MissingData } from '../types/missing-data';
import { ReportItem, ReportTestItem } from '../utils/entities';
import { collectAllGroups } from '@/app/features/reports/ReportView';
import { countAllGroups, countAllHeaders } from '@/app/features/reports/utils/itemCountUtils';

type ReportViewerContextValue = {
    // data: ReportItem[];
    // report: ReportEntity;
    activeItems: string[];
    setActiveItems: (items: string[]) => void;
    // expandedItems: Set<string>;
    // setExpandedItems: React.Dispatch<SetStateAction<Set<string>>>;
    // expandedTiles?: Set<number>;
    // setExpandedTiles?: React.Dispatch<SetStateAction<Set<number>>>;
    // expandAll: () => void;
    // collapseAll: () => void;
    // // switchHeaders: () => void;
    // headerMode: ReportHeaderModes;
    // setIsHeaderFixed: React.Dispatch<SetStateAction<boolean>>;
    // isHeaderFixed: boolean;
    // groupMode: ReportGroupModes;
    // headerRefs: React.MutableRefObject<{ [key: string]: HTMLDivElement | null }>;
    // contentRef: React.MutableRefObject<HTMLDivElement | null>;
    // viewMode: ReportViewModes;
   // filterInput?: string;
    isScrolling?: boolean;
    //clearFilterInput?: () => void;
    showTestEditor?: (id: number) => void;
    // filteredCounts?: FilterCounts,
    // unfilteredCounts?: FilterCounts,
    // missingData?: MissingData;
    showFastFillQA?: boolean;
    showMissingData?: boolean;
    // isEditMode?: boolean;
    // setEditMode?: (editing: boolean) => void;
    // bulkEditMode?: boolean;
    // selectedItems: Set<string>;
    // setSelectedItems: React.Dispatch<SetStateAction<Set<string>>>;
};

//create context for report viewer
export const ReportViewerContext = createContext<ReportViewerContextValue>(undefined);

const useReportViewerContext = () => {
    return React.useContext(ReportViewerContext);
}

export default useReportViewerContext;

export interface ReportDisplayContext {
    viewMode: ReportViewModes;
    headerMode: ReportHeaderModes;
    switchHeaders: () => void;
    isHeaderFixed: boolean;
    setIsHeaderFixed: (fixed: boolean) => void;
}

const defaultReportDisplayContext: ReportDisplayContext = {
    viewMode: 'details',
    headerMode: 'normal',
    switchHeaders: () => {},
    isHeaderFixed: false,
    setIsHeaderFixed: () => {}
}

const ReportDisplayContext = createContext<ReportDisplayContext>(defaultReportDisplayContext);

export const useReportDisplayContext = () => {
    return React.useContext(ReportDisplayContext);
}

interface ReportDisplayProviderProps {
    children: React.ReactNode;
}

export const ReportDisplayProvider: React.FC<ReportDisplayProviderProps> = ({ children }) => {
    const [viewMode, setViewMode] = useState<ReportViewModes>('details');
    const [headerMode, setHeaderMode] = useState<ReportHeaderModes>('normal');
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);

    const switchHeaderMode = useCallback(() => {
        setHeaderMode(prev => prev === 'normal' ? 'switch' : 'normal');
    }, []);

    const value = useMemo(() => ({
        viewMode,
        setViewMode,
        headerMode,
        switchHeaders: switchHeaderMode,
        isHeaderFixed,
        setIsHeaderFixed
    }), [viewMode, headerMode, isHeaderFixed, switchHeaderMode]);

    return (
        <ReportDisplayContext.Provider value={value}>
            {children}
        </ReportDisplayContext.Provider>
    );
};

export interface ReportSelectionContext {
    bulkEditMode: boolean;
    setBulkEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    selectedItems: Set<string>;
    setSelectedItems: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export const ReportSelectionContext = createContext<ReportSelectionContext>(null);

export const useReportSelectionContext = () => {
    return React.useContext(ReportSelectionContext);
}

interface ReportSelectionProviderProps {
    children: React.ReactNode;
}

export const ReportSelectionProvider: React.FC<ReportSelectionProviderProps> = ({ children }) => {
    const [bulkEditMode, setBulkEditMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

    const value = useMemo(() => ({
        bulkEditMode,
        setBulkEditMode,
        selectedItems,
        setSelectedItems
    }), [bulkEditMode, selectedItems]);

    return (
        <ReportSelectionContext.Provider value={value}>
            {children}
        </ReportSelectionContext.Provider>
    );
};

// Main context for core report data and state
export interface ReportViewContext {
    filteredData: ReportTestItem[];
    data: ReportItem[];
    report: ReportEntity;
    reportTotal: number;
    visibleTestCount: number;
    missingData: MissingData;
    missingDataCount: number;
    missingSegments: number;
    bookmarks: BookMark[];
    isEditMode: boolean;
    setEditMode: (mode: boolean) => void;
    groupMode: ReportGroupModes;
    setGroupMode: (mode: ReportGroupModes) => void;
    isLoading: boolean;
    filters: ReportFilters; //TODO: fix this type
    updateGroupedData: (groupMode: ReportGroupModes, data: ReportTestItem[]) => ReportTestItem[] | ReportItem[];
}


const defaultReportViewContext: ReportViewContext = {
    filteredData: [],
    data: [],
    report: null,
    reportTotal: 0,
    visibleTestCount: 0,
    missingData: null,
    missingDataCount: 0,
    missingSegments: 0,
    bookmarks: [],
    isEditMode: false,
    setEditMode: () => {},
    groupMode: 'location',
    setGroupMode: () => {},
    isLoading: false,
    filters: null,
    updateGroupedData: () => [],
}

export const ReportViewContext = createContext<ReportViewContext>(defaultReportViewContext);


interface ReportViewProviderProps {
    children: React.ReactNode;
    reportId: string; // Pass necessary props
}

export const ReportViewProvider: React.FC<ReportViewProviderProps> = ({ children, reportId }) => {
    // Move relevant state and data fetching here
    const [editMode, setEditMode] = useState(true);
    const [groupMode, setGroupMode] = useState<ReportGroupModes>('location');

    const {
        enrichedTestItems: allData, //we only get the total number of tests
        filteredData,
        report,
        isLoading,
        error,
        filters,
        bookmarks,
        missingData,
        missingDataCount,
        missingSegments,
        updateGroupedData,
    } = useReportData(reportId, editMode);

    const data = useMemo(() => {
        const groupedData = updateGroupedData(groupMode, filteredData);
        return groupedData as ReportItem[];
    }, [filteredData, groupMode, updateGroupedData]);

    const value = useMemo(() => ({
        data, //the data that is displayed in the report
        filteredData, //the raw test items data that is filtered
        report,
        visibleTestCount: filteredData?.length,
        reportTotal: allData?.length,
        bookmarks,
        updateGroupedData,
        filters,
        isEditMode: editMode,
        setEditMode,
        groupMode,
        setGroupMode,
        isLoading,
        error,
        missingData,
        missingDataCount,
        missingSegments,
    }), [filteredData, report, filters, editMode, groupMode, data]);

    if (error) {
        return <ErrorState message={error.toString()} />;
    }

    if (isLoading) {
        return <LoadingState />;
    }

    return (
        <ReportViewContext.Provider value={value}>
            {children}
        </ReportViewContext.Provider>
    );
};

export const useReportViewContext = () => {
    return React.useContext(ReportViewContext);
}

// Context for grouping and expansion state
export interface ReportGroupingContext {
    groupMode: ReportGroupModes;
    expandedItems: Set<string>;
    setExpandedItems: React.Dispatch<React.SetStateAction<Set<string>>>;
    expandedTiles: Set<number>;
    setExpandedTiles: React.Dispatch<React.SetStateAction<Set<number>>>;
    expandAll: () => void;
    collapseAll: () => void;
    allExpanded: true | false | 'partial' | 'headers';
    allTilesExpanded: boolean;
    expandAllTiles: () => void;
    collapseAllTiles: () => void;
}

export const ReportGroupingContext = createContext<ReportGroupingContext>(null!);

export const useReportGroupingContext = () => {
    const context = React.useContext(ReportGroupingContext);
    if (!context) {
        throw new Error('useReportGroupingContext must be used within a ReportGroupingProvider');
    }
    return context;
}

interface ReportGroupingProviderProps {
    children: React.ReactNode;
}

interface ReportGroupingProviderProps {
    children: React.ReactNode;
}

export const ReportGroupingProvider: React.FC<ReportGroupingProviderProps> = ({ children }) => {

    const { data, filteredData, groupMode: currentGroupMode } = useReportViewContext();

    const [expandedItems, setExpandedItems] = useState<ExpandedItemsState>({
        element: new Set(),
        location: new Set(),
        test: new Set(),
        rating: new Set()
    });
    const [expandedTiles, setExpandedTiles] = useState<Set<number>>(new Set());
    const [groupMode, setGroupMode] = useState<ReportGroupModes>('location');
    const [allExpanded, setAllExpanded] = useState<true | false | 'partial' | 'headers'>(false);

    const calculatedTotals = useMemo(() => ({
        groups: data?.length > 0 ? countAllGroups(data) : 0,
        headers: data?.length > 0 ? countAllHeaders(data) : 0,
        visibleTests: filteredData?.length || 0
    }), [data, filteredData]);

    const collapseAll = useCallback(() => {
        if (allExpanded === true) {
            if (expandedTiles.size > 0) {
                setExpandedTiles(new Set());
            } else {
                //collapse to headers

                let allIds = new Set<string>();
                allIds = collectAllGroups(data, allIds, false);
                setExpandedItems((prev) => ({
                    ...prev,
                    [groupMode]: allIds,
                }));
            }
            return;
        }

        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: new Set(),
        }));
    }, [data, groupMode, allExpanded, expandedTiles]);

    const expandAll = useCallback(() => {

        if (allExpanded === true) {
            if (calculatedTotals.visibleTests !== expandedTiles.size) {
                setExpandedTiles(new Set(filteredData.map((item) => item.assessmentTestId)));
            } else {
                collapseAll();
            }
            return;
        }

        let allIds = new Set<string>();

        if (allExpanded === false) {
            allIds = collectAllGroups(data, allIds, false);
        } else if (allExpanded === 'headers' || allExpanded === 'partial') {
            allIds = collectAllGroups(data);
        }

        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: allIds,
        }));
    }, [data, groupMode, allExpanded, calculatedTotals.visibleTests, expandedTiles]);

    const expandAllTiles = useCallback(() => {
        if (allExpanded === false) {
            expandAll();
        }
        setExpandedTiles(new Set(filteredData.map((item) => item.assessmentTestId)));
    }, [filteredData, allExpanded, expandAll]);

    const collapseAllTiles = useCallback(() => {
        setExpandedTiles(new Set());
    }, []);

    const allTilesExpanded = filteredData?.length === expandedTiles.size;

    const setGroupExpandedItems = useCallback((value: Set<string>) => {
        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: value,
        }));
    }, [groupMode]);

    useEffect(() => {
        const totalExpandedItems = expandedItems[groupMode].size;
        const expandMode =
            totalExpandedItems === 0 ? false :
                totalExpandedItems === calculatedTotals.groups ? true :
                    totalExpandedItems === calculatedTotals.headers ? 'headers' : 'partial';
        setAllExpanded(expandMode);
        console.debug('allExpanded', { expandMode, totalExpandedItems, calculatedTotals });

    }, [expandedItems, groupMode]);

    const expandedIds = expandedItems[groupMode];

    const value = useMemo(() => ({
        groupMode,
        setGroupMode,
        expandedItems: expandedItems[groupMode],
        setExpandedItems: setGroupExpandedItems,
        expandedTiles,
        setExpandedTiles,
        expandAll,
        collapseAll,
        allExpanded,
        allTilesExpanded,
        expandAllTiles,
        collapseAllTiles
    }), [groupMode, expandedItems, expandedTiles, allExpanded, allTilesExpanded]);

    return (
        <ReportGroupingContext.Provider value={value}>
            {children}
        </ReportGroupingContext.Provider>
    );
};




interface ReportProvidersProps {
    children: React.ReactNode;
    reportId: string;
}

export const ReportProviders: React.FC<ReportProvidersProps> = ({ children, reportId }) => {
    return (
        <ReportViewProvider reportId={reportId}>
            <ReportDisplayProvider>
                <ReportGroupingProvider>
                    {/* <ReportFiltersProvider> */}
                    <ReportSelectionProvider>
                        {children}
                    </ReportSelectionProvider>
                    {/* </ReportFiltersProvider> */}
                </ReportGroupingProvider>
            </ReportDisplayProvider>
        </ReportViewProvider>
    );
};