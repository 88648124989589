import { ReportItem, ReportItemContent, ReportTestItem } from './entities';
import convertTestToReportItem from './convertTestItem';
import { customCompare } from './sorter';
export default function convertSpacesToTree(
  testItems: ReportTestItem[]
): ReportItem[] {
  console.time('convertSpacesToTree');

  const assetMap: { [key: string]: ReportItem } = {};

  testItems.forEach((item) => {
    const assetId = item.assetId.toString();
    const assetTitle = item.asset_name || '(No Asset Name)';

    // Ensure asset exists in map
    if (!assetMap[assetId]) {
      assetMap[assetId] = {
        id: assetId,
        title: assetTitle,
        content: [],
        children: [],
      };
    }

    const asset = assetMap[assetId];

    // Handle null or undefined for building, level, and area descriptions
    const buildingId = item.buildingId ? `${item.buildingId}` : '(No Building)';
    const buildingTitle = item.building_description || '(No Building)';
    const levelId = item.levelId
      ? `${item.buildingId}.${item.levelId}`
      : '(No Level)';
    const levelTitle = item.level_description || '(No Level)';
    const areaId = item.areaId
      ? `${item.buildingId}.${item.levelId}.${item.areaId}`
      : '(No Space)';
    const areaTitle = item.area_description || '(No Space)';

    let building = asset.children?.find((b) => b.id === buildingId);

    if (!building) {
      building = {
        id: buildingId,
        title: buildingTitle,
        content: [],
        children: [],
      };
      asset.children?.push(building);
    }

    // If no building ID, add to asset content directly
    if (!item.buildingId) {
      const contentItem: ReportItemContent = convertTestToReportItem(item);
      building.content?.push({
        ...contentItem,
        groupId: assetId,
      });
      return;
    }

    // Ensure the level exists under the building
    let level = building.children?.find((l) => l.id === levelId);
    if (!level) {
      level = {
        id: levelId,
        title: levelTitle,
        content: [],
        children: [],
      };
      building.children?.push(level);
    }

    // If the level has no area, push directly into level content
    if (!item.levelId) {
      const groupId = `${item.buildingId}`;
      const contentItem: ReportItemContent = convertTestToReportItem(item);
      level.content?.push({
        ...contentItem,
        groupId,
      });
      return;
    }

    // Ensure the area exists under the level
    let area = level.children?.find((a) => a.id === areaId);
    if (!area) {
      area = {
        id: areaId,
        title: areaTitle,
        content: [],
      };
      level.children?.push(area);
    }

    // If the area exists, push content into the area
    const groupId = `${item.buildingId}.${item.levelId}.${item.areaId}`;
    const contentItem: ReportItemContent = convertTestToReportItem(item);
    area.content?.push({
      ...contentItem,
      groupId,
    });
  });

  const sortedAssets = Object.values(assetMap).sort((a, b) =>
    customCompare(a.title, b.title)
  );

  sortedAssets.forEach((asset) => {
    asset.children?.sort((a, b) => customCompare(a.title, b.title));
    asset.children?.forEach((building) => {
      building.children?.sort((a, b) => customCompare(a.title, b.title));
      building.children?.forEach((level) => {
        level.children?.sort((a, b) => customCompare(a.title, b.title));
      });
    });
  });

  // Count items (same as before)
  function countItems(item: ReportItem): number {
    if (item.content) {
      return item.content.length;
    }
    if (item.children) {
      item.count = item.children.reduce(
        (sum, child) => sum + countItems(child),
        0
      );
      return item.count;
    }
    return 0;
  }

  sortedAssets.forEach((asset) => {
    countItems(asset);
  });

  console.timeEnd('convertSpacesToTree');
  return sortedAssets;
}
