import { Button } from '@/components/ui/button';
import { DoubleArrowDownIcon, DoubleArrowUpIcon } from '@radix-ui/react-icons';
import React from 'react';

type TileExpanderProps = {
    handleExpandTile: () => void;
    isTileExpanded: boolean;
}

const TileExpander: React.FC<TileExpanderProps> = ({ handleExpandTile, isTileExpanded }) => {
    return (
        <div className="flex flex-1 flex-row text-sm p-0 gap-2 items-center">
            <div className="h-[1px] bg-zinc-200 flex-1"></div>
            <Button
                variant='link'
                className="gap-2    "
                onClick={handleExpandTile}
            >
                {isTileExpanded ? (
                    <>
                        <DoubleArrowUpIcon className="h-4 w-4 text-zinc-500" />
                        <span className="text-zinc-500 font-normal tracking-tight">Show less</span>
                    </>
                ) : (
                    <>
                        <DoubleArrowDownIcon className="h-4 w-4 text-zinc-500" />
                        <span className="text-zinc-500 font-normal tracking-tight">Show more</span>
                    </>
                )}

            </Button>
            <div className="h-[1px] bg-zinc-200 flex-1"></div>
        </div>
    )
}

export default TileExpander;