import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import React from "react";
import OptionToggle from './option-toggle';

type EditModeToggleProps = {
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
}

const EditModeToggle: React.FC<EditModeToggleProps> = ({ editMode, setEditMode }) => {
    return (
        <div className="flex items-center space-x-2">
            <OptionToggle
                options={[{ description: 'Edit', value: 'edit' }, { description: 'Preview', value: 'preview' }]}
                value={editMode ? 'edit' : 'preview'}
                onChange={(value) => setEditMode(value === 'edit')}
            />

        </div>
    )
}

EditModeToggle.displayName = 'EditModeToggle'

export default React.memo(EditModeToggle)