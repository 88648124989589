import { FastFillText } from "@/app/features/reports/components/fast-fill-text";
import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { CustomFieldProps } from '../custom-fields-container';
import { FormLabelWithIndicator } from "../form-label-with-indicator";

type CustomTextAreaFieldProps = CustomFieldProps & {
    options: { id: string; displayName: string }[]

}

export function CustomTextAreaField({
    control,
    name,
    label,
    description,
    showDescription,
    info,
    required,
    options,
    isValid,
    showFormMessage,
}: CustomTextAreaFieldProps) {
    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem>
                    <FormLabelWithIndicator
                        label={label}
                        isValid={isValid}
                        required={required}
                        info={info}
                    />
                    <FormControl>

                        <FastFillText
                            control={control}
                            name={name}
                            fastFills={options.map((opt) => ({ id: parseFloat(opt.id), description: opt.displayName })) ?? []}
                            enableFastFill
                        />

                    </FormControl>
                    {showDescription && (<FormDescription>{description}</FormDescription>)}
                    {showFormMessage && <FormMessage />}
                </FormItem>
            )}
        />
    )
}
