import { FilterItem, ReportTestItem } from '../utils/entities';
import sortByName from './sortByName';
import { createFacet, FacetChild, FacetConfig, FacetCounts } from './facets';
const createSpaceFacetConfig = (): FacetConfig<ReportTestItem, FacetChild> => ({
  key: 'space',
  getId: (item) => item.assetId.toString(),
  getName: (item) => item.asset_name || '(No Asset)',
  getChildren: (item) => {
    const children: FacetChild[] = [];

    // Building level
    if (item.buildingId || children.length === 0) {
      const buildingId = `${item.assetId}.${
        item.buildingId ?? 'null'
      }`;
      const building: FacetChild = {
        id: buildingId,
        key: 'space',
        name: item.building_description || '(No Building)',
        count: 1,
        extraProps: {
          children: [], // Initialize children array
        },
      };

      // Level
      if (item.levelId || !building.children?.length) {
        const levelId = `${buildingId}.${item.levelId ?? 'null'}`;
        const level: FacetChild = {
          id: levelId,
          key: 'space',
          name: item.level_description || '(No Level)',
          count: 1,
          extraProps: {
            key: 'space',
            children: [], // Initialize children array
          },
        };

        // Area
        if (item.areaId || !level.children?.length) {
          const areaId = `${levelId}.${item.areaId ?? 'null'}`;
          const area: FacetChild = {
            id: areaId,
            key: 'space',
            name: item.area_description || '(No Space)',
            count: 1,
            extraProps: {
              children: [], // Empty array for leaf nodes
            },
          };
          level.extraProps.children = [area];
        }
        building.extraProps.children = [level];
      }
      children.push(building);
    }

    return children;
  },
  extraProps: (item) => ({
    assetId: item.assetId,
  }),
});

const convertTestsToSpaces = (
  filteredItems: ReportTestItem[],
  allItems: ReportTestItem[],
  counts?: FacetCounts
): FilterItem[] => {
  if (!allItems?.length) return [];

  const config = createSpaceFacetConfig();
  const facets = createFacet(filteredItems, allItems, config, counts);

  // Recursively build the hierarchy
  const buildHierarchy = (item: FilterItem): FilterItem => {
    if (item.children?.length) {
      item.children = item.children
        .map((child) => ({
          ...child,
          key: child.extraProps?.key ?? child.key,
          children:
            child.extraProps?.children?.map((grandChild) => ({
              ...grandChild,
              key: grandChild.extraProps?.key ?? grandChild.key,
              children:
                grandChild.extraProps?.children?.map((greatGrandChild) => ({
                  ...greatGrandChild,
                  key: greatGrandChild.extraProps?.key ?? greatGrandChild.key,
                  children: [],
                  count:
                    counts?.filtered?.[greatGrandChild.id] ??
                    greatGrandChild.count,
                  totalCount:
                    counts?.total?.[greatGrandChild.id] ??
                    greatGrandChild.count,
                })) ?? [],
              count: counts?.filtered?.[grandChild.id] ?? grandChild.count,
              totalCount: counts?.total?.[grandChild.id] ?? grandChild.count,
            })) ?? [],
          count: counts?.filtered?.[child.id] ?? child.count,
          totalCount: counts?.total?.[child.id] ?? child.count,
        }))
        .filter(Boolean)
        .sort(sortByName);
    }
    return item;
  };

  return facets.map(buildHierarchy).sort(sortByName);
};

export default convertTestsToSpaces;
