import { ReportTestItem } from '@/app/features/reports/utils/entities';

export interface Filter {
  matches: (item: ReportTestItem) => boolean;
}

export const createFilter = (filter: Filter) => filter;

// Base ID filter factory
const createIdFilter = <K extends keyof ReportTestItem>(
  key: K,
  id: string
): Filter => ({
  matches: (item) => item[key] === Number(id),
});

// Individual filters
export const filterByAsset = (assetId: string) =>
  createIdFilter('assetId', assetId);

export const filterByBuilding = (buildingId: string) =>
  createIdFilter('buildingId', buildingId);

export const filterByLevel = (levelId: string) =>
  createIdFilter('levelId', levelId);

export const filterByArea = (areaId: string) =>
  createIdFilter('areaId', areaId);

//filterByLocation accepts a string of $buildingId-$levelId-$areaId where each part is optional
export const filterByLocation = (location: string) => {
  const parts = location.split('.');
  const ids = {
    asset: parts[0],
    building: parts[1],
    level: parts[2],
    area: parts[3],
  };

  return createFilter({
    matches: (item) => {
      // Check most specific (area) first
      if (ids.area) {
        if (item.areaId !== Number(ids.area)) {
          return false;
        }
        // If area matches, implicitly check its parent IDs
        if (
          item.levelId !== Number(ids.level) ||
          item.buildingId !== Number(ids.building) ||
          item.assetId !== Number(ids.asset)
        ) {
          return false;
        }
        return true;
      }

      // Check level if specified
      if (ids.level) {
        if (item.levelId !== Number(ids.level)) {
          return false;
        }
        // Check level's parents
        if (
          item.buildingId !== Number(ids.building) ||
          item.assetId !== Number(ids.asset)
        ) {
          return false;
        }
        return true;
      }

      // Check building if specified
      if (ids.building) {
        if (item.buildingId !== Number(ids.building)) {
          return false;
        }
        // Check building's parent
        if (item.assetId !== Number(ids.asset)) {
          return false;
        }
        return true;
      }

      // Check asset as last resort
      if (ids.asset) {
        return item.assetId === Number(ids.asset);
      }

      return true; // No filters specified
    },
  });
};

export const createElementFilter = (facets: Set<string>): Filter => ({
  matches: (item: ReportTestItem) => {
    // Handle items with no element
    if (item.element?.path?.length < 1) {
      return facets.has('null');
    }

    const path = item.element.path;
    let currentPath = '';

    // Check from most specific to least specific
    for (let i = path.length - 1; i >= 0; i--) {
      // Build the full path from root to current level
      currentPath = path
        .slice(0, i + 1)
        .map(p => p.id)
        .join('.');

      // If this level's path is in the facets, it's a match
      if (facets.has(currentPath)) {
        return true;
      }
    }

    return false;
  },
});


///type SelectedFilters = Record<FilterTypes, string[]>;

export const applyFilters = (items: ReportTestItem[], filters: Filter[]) => {
  return items.filter((item) =>
    filters.every((filter) => filter.matches(item))
  );
};

export const createPropertyFilter = <K extends keyof ReportTestItem>(
  property: K
) => (facets: Set<string>): Filter => ({
  matches: (item: ReportTestItem) => {
    const value = String(item[property])?.toLowerCase();
    return value === undefined || value === null
      ? facets.has('null')
      : facets.has(value);
  },
});


export const createStatusFilter = createPropertyFilter('status');
export const createElementNameFilter = createPropertyFilter('element_name');
export const createElementIdFilter = createPropertyFilter('element_id');

export const createTestSuiteFilter = (suites: Set<string>): Filter => ({
  matches: (item) => {
    const matches = [
      `${item.testSpecification.testSuiteId}`,
      `${item.testSpecification.testSuiteId}.${item.testSpecification.category}`,
      `${item.testSpecification.testSuiteId}.${item.testSpecification.category}.${item.testSpecification.subcategory}`,
      `${item.testSpecification.testSuiteId}.${item.testSpecification.category}.${item.testSpecification.subcategory}.${item.testSpecification.id}`,
    ];
    return matches.some((match) => suites.has(match));
  },
});

export const createResultFilter = (results: Set<string>): Filter => ({
  matches: (item) => {
    const matches = [
      `${item.testSpecification.testSuiteId}`,
      `${item.testSpecification.testSuiteId}.${item.result}`,
    ];
    return matches.some((match) => results.has(match));
  },
});

export const createExclusionFilter = (filters: Set<string>): Filter => ({
  matches: (item) => {
    const matches = [
      `${item.switches?.include !== true ? 'manuallyexcluded' : null}`,
      `${item.status.toLowerCase() === 'draft' ? 'status.draft' : null}`,
      `${item.status.toLowerCase() === 'qa' ? 'status.qa' : null}`,
      `${item.status.toLowerCase() === 'archived' ? 'status.archived' : null}`,
      `${item.status.toLowerCase() !== 'published' ? 'status' : null}`,
      `${item.hasFastFillQA ? 'unmatchedsegments' : null}`,
      `${item.hasMissingData ? 'missingdata' : null}`,
      `${item.exclusionReason === 'included' ? 'included' : null}`,
    ]

    console.debug('matches', {filters, matches});

    return matches.some((match) => filters.has(match));

  }
});

export const createCompositeFilter = (filters: Filter[]): Filter => ({
  matches: (item) => filters.every((filter) => filter.matches(item)),
});

export const createSearchFilter = (search: string): Filter => ({
  matches: (item) => {
    const searchTerm = search.toLowerCase();
    
    // Helper function to safely convert any value to searchable string
    const toString = (value: any): string => {
      if (value === null || value === undefined) return '';
      if (typeof value === 'object') return JSON.stringify(value);
      return String(value);
    };

    // Search through all direct properties of the item
    const hasMatch = Object.values(item).some(value => 
      toString(value).toLowerCase().includes(searchTerm)
    );

    // Also search through element properties if they exist
    const hasElementMatch = item.element && Object.values(item.element).some(value =>
      toString(value).toLowerCase().includes(searchTerm)
    );

    // Search through test specification properties
    const hasTestSpecMatch = Object.values(item.testSpecification).some(value =>
      toString(value).toLowerCase().includes(searchTerm)
    );

    return hasMatch || hasElementMatch || hasTestSpecMatch;
  },
});
