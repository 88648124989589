import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion"
import { cn } from "@/lib/utils"
import { Check } from 'lucide-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FilterCountBadge } from './filter-drawer'
import { FilterItem } from '@/app/features/reports/utils/entities'
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { FilterCounts, FilterKeys, Filters, FilterTypes, SelectedFilters } from '../types/filters';
import extractFilterCount, { extractCountForKey } from '../utils/extractFilterCount';
import CountPill from "@/app/features/reports/components/excluded-count-pill"
import { Count } from "@/app/features/reports/utils/calculateFilterCounts"

export interface HierarchicalSelectorProps {
    name: FilterTypes;
    description: string;
    data: Filters
    selectedFilters: SelectedFilters,
    onSelectionChange: (name, selectedIds: string) => void
    filterCounts?: FilterCounts;
    filteredCounts?: FilterCounts;
    renderCustom?: (item: FilterItem) => React.ReactNode
}

export function HierarchicalSelector({ name, description, data: filters, selectedFilters,
    onSelectionChange: onSelection,
    filterCounts, filteredCounts, renderCustom }: HierarchicalSelectorProps) {
    const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set())
    const [searchTerm, setSearchTerm] = useState('')
    const [currentPath, setCurrentPath] = useState<{ id: string; name: string }[]>([])

    const selectedIds = useMemo(() => selectedFilters[name]?.[0]?.split(',') || [], [selectedFilters[name]]);
    const [internalSelectedIds, setInternalSelectedIds] = useState<Set<string>>(new Set([]))

    const data = filters[name];

    const findItemById = useCallback((items: FilterItem[], id: string): FilterItem | null => {
        for (const item of items) {
            if (item.id === id) return item
            if (item.children) {
                const found = findItemById(item.children, id)
                if (found) return found
            }
        }
        return null
    }, []);

    const onSelectionChange = useCallback((selectedIds: string[]) => {
        onSelection(name, selectedIds.join(','))
    }, [onSelection, name]);

    const expandSelectedIds = useCallback((items: FilterItem[], selectedSet: Set<string>): Set<string> => {
        const expandedSet = new Set(selectedSet)
        const traverse = (items: FilterItem[]) => {
            for (const item of items) {
                if (selectedSet.has(item.id)) {
                    // If this item is selected, add all its descendants
                    const addAllDescendants = (subItems: FilterItem[]) => {
                        for (const subItem of subItems) {
                            expandedSet.add(subItem.id)
                            if (subItem.children) {
                                addAllDescendants(subItem.children)
                            }
                        }
                    }
                    if (item.children) {
                        addAllDescendants(item.children)
                    }
                } else if (item.children) {
                    // If this item is not selected, check its children
                    traverse(item.children)
                }
            }
        }
        traverse(items)
        // return expandedSet
        return new Set([...expandedSet].filter(item => item));
    }, [])

    useEffect(() => {
        const expandedSelectedIds = expandSelectedIds(data, new Set(selectedIds))
        setInternalSelectedIds(expandedSelectedIds)
    }, [data, selectedIds, expandSelectedIds])

    const isItemSelected = useCallback((item: FilterItem): boolean => {
        return internalSelectedIds.has(item.id)
    }, [internalSelectedIds])

    const hasSelectedDescendant = useCallback((item: FilterItem): boolean => {
        if (item.children) {
            return item.children.some(child =>
                isItemSelected(child) || hasSelectedDescendant(child)
            )
        }
        return false
    }, [isItemSelected])

    const isPartiallySelected = useCallback((item: FilterItem): boolean => {
        return !isItemSelected(item) && hasSelectedDescendant(item)
    }, [isItemSelected, hasSelectedDescendant])

    const getMostInclusiveSelectedItems = useCallback((items: FilterItem[], selectedSet: Set<string>): string[] => {
        const result: string[] = [];
        const traverse = (items: FilterItem[]) => {
            for (const item of items) {
                if (selectedSet.has(item.id)) {
                    result.push(item.id);
                    // Skip checking children if parent is selected
                    continue;
                }
                if (item.children) {
                    traverse(item.children);
                }
            }
        };
        traverse(items);
        return result;
    }, []);

    const handleSelect = useCallback((id: string) => {
        const newSelectedIds = new Set(internalSelectedIds);
        const item = findItemById(data, id);

        const toggleSelection = (items: FilterItem[], select: boolean) => {
            items.forEach(item => {
                if (select) {
                    newSelectedIds.add(item.id);
                } else {
                    newSelectedIds.delete(item.id);
                }
                if (item.children) {
                    toggleSelection(item.children, select);
                }
            });
        };

        if (item) {
            const isCurrentlySelected = newSelectedIds.has(id);
            toggleSelection([item], !isCurrentlySelected);

            // Update parent selection status
            const updateParentSelection = (items: FilterItem[], targetId: string) => {
                for (const item of items) {
                    if (item.children && item.children.some(child => child.id === targetId)) {
                        const allChildrenSelected = item.children.every(child => newSelectedIds.has(child.id));
                        const someChildrenSelected = item.children.some(child => newSelectedIds.has(child.id) || hasSelectedDescendant(child));

                        if (allChildrenSelected) {
                            newSelectedIds.add(item.id);
                        } else if (someChildrenSelected) {
                            newSelectedIds.delete(item.id); // Ensure parent is not fully selected
                        } else {
                            newSelectedIds.delete(item.id);
                        }

                        updateParentSelection(data, item.id);
                        break;
                    } else if (item.children) {
                        updateParentSelection(item.children, targetId);
                    }
                }
            };

            updateParentSelection(data, id);
        }

        setInternalSelectedIds(newSelectedIds);
        onSelectionChange(getMostInclusiveSelectedItems(data, newSelectedIds));
    }, [data, internalSelectedIds, onSelectionChange, findItemById, hasSelectedDescendant, getMostInclusiveSelectedItems]);

    const toggleExpand = useCallback((id: string) => {
        setExpandedIds(prev => {
            const newExpandedIds = new Set(prev);
            if (newExpandedIds.has(id)) {
                newExpandedIds.delete(id);
            } else {
                newExpandedIds.add(id);
            }
            return newExpandedIds;
        });
    }, []);

    const selectAll = useCallback(() => {
        const allIds = new Set<string>()
        const collectIds = (items: FilterItem[]) => {
            items.forEach(item => {
                allIds.add(item.id)
                if (item.children) collectIds(item.children)
            })
        }
        collectIds(data)
        setInternalSelectedIds(allIds)
        onSelectionChange(getMostInclusiveSelectedItems(data, allIds))
    }, [data, onSelectionChange, getMostInclusiveSelectedItems])

    const deselectAll = useCallback(() => {
        setInternalSelectedIds(new Set())
        onSelectionChange([])
    }, [onSelectionChange])

    const expandAll = useCallback(() => {
        const allIds = new Set<string>()
        const collectIds = (items: FilterItem[]) => {
            items.forEach(item => {
                if (item.children) {
                    allIds.add(item.id)
                    collectIds(item.children)
                }
            })
        }
        collectIds(data)
        setExpandedIds(allIds)
    }, [data])

    const collapseAll = useCallback(() => {
        setExpandedIds(new Set())
    }, [])

    const toggleRootSelection = useCallback(() => {
        if (internalSelectedIds.size === 0) {
            selectAll();
        } else {
            deselectAll();
        }
    }, [internalSelectedIds, selectAll, deselectAll]);

    const toggleRootExpansion = useCallback(() => {
        if (expandedIds.size === 0) {
            expandAll();
        } else {
            collapseAll();
        }
    }, [expandedIds, expandAll, collapseAll]);

    const getTotalItemCount = useCallback((items: FilterItem[]): number => {
        return items.reduce((count, item) => {
            return count + 1 + (item.children ? getTotalItemCount(item.children) : 0);
        }, 0);
    }, []);

    const getRootSelectionState = useCallback(() => {
        const totalCount = getTotalItemCount(data);
        const selectedCount = internalSelectedIds.size;

        if (selectedCount === 0) return 'none';
        if (selectedCount === totalCount) return 'all';

        const hasPartialSelection = (items: FilterItem[]): boolean => {
            for (const item of items) {
                if (isPartiallySelected(item)) return true;
                if (item.children && hasPartialSelection(item.children)) return true;
            }
            return false;
        };

        return hasPartialSelection(data) ? 'partial' : 'all';
    }, [data, internalSelectedIds, getTotalItemCount, isPartiallySelected]);

    const rootSelectionState = useMemo(() => getRootSelectionState(), [getRootSelectionState]);

    const filteredData = useMemo(() => {
        if (!searchTerm) return data
        const filterItems = (items: FilterItem[]): FilterItem[] => {
            return items.reduce((acc: FilterItem[], item) => {
                if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    acc.push(item)
                } else if (item.children) {
                    const filteredChildren = filterItems(item.children)
                    if (filteredChildren.length > 0) {
                        acc.push({ ...item, children: filteredChildren })
                    }
                }
                return acc
            }, [])
        }
        return filterItems(data)
    }, [data, searchTerm])

    const updatePath = useCallback((item: FilterItem) => {
        const newPath = [...currentPath]
        const existingIndex = newPath.findIndex(pathItem => pathItem.id === item.id)
        if (existingIndex !== -1) {
            // If the item is already in the path, remove all items after it
            newPath.splice(existingIndex + 1)
        } else {
            // Otherwise, add the new item to the path
            newPath.push({ id: item.id, name: item.name })
        }
        setCurrentPath(newPath)
    }, [currentPath])

    const navigateTo = useCallback((id: string) => {
        const newPath = currentPath.slice(0, currentPath.findIndex(item => item.id === id) + 1)
        setCurrentPath(newPath)
        // Expand all parent items in the path
        setExpandedIds(new Set(newPath.map(item => item.id)))
    }, [currentPath])

    const getTotalFilteredCount = useCallback((items: FilterItem[]): number => {
        return items.reduce((count, item) => {
            const filteredCount = extractFilterCount(filterCounts, item, 'filtered');
            return count + filteredCount;
        }, 0);
    }, [filterCounts]);

    const getTotalUnfilteredCount = useCallback((items: FilterItem[]): number => {
        return items.reduce((count, item) => {
            const unfilteredCount = extractFilterCount(filterCounts, item, 'total');
            return count + unfilteredCount;
        }, 0);
    }, [filterCounts]);

    const renderItems = useCallback((items: FilterItem[], depth = 0) => {

        return items.map((item) => {
            const isSelected = isItemSelected(item)
            const isPartial = isPartiallySelected(item)
            const isExpanded = expandedIds.has(item.id)
            const hasChildren = item.children && item.children.length > 0

            // Handle special cases
            //console.debug('Rendering item:', { name, item, filterCounts, filteredCounts });
            const isSpecialCase = item.name.startsWith('(No ')
            const itemStyle = isSpecialCase ? 'italic text-muted-foreground' : ''

            const unfilteredCount = filterCounts ? extractFilterCount(filterCounts, item, 'total') : 0;
            const filteredCount = filterCounts ? extractFilterCount(filterCounts, item, 'filtered') : 0;
            const missingCounts = filterCounts ? extractFilterCount(filterCounts, item, 'missingData') : 0;
            const qaCount = filterCounts ? extractFilterCount(filterCounts, item, 'unmatchedSegments') : 0;

            // /console.debug('Rendering item:', { key: item.key, id: item.id, filteredCount, unfilteredCount });

            return (
                <div key={item.id} className={cn("ml-1", depth === 0 && "ml-0")}>
                    {hasChildren ? (
                        <Accordion
                            type="single"
                            collapsible
                            value={isExpanded ? item.id : undefined}
                            onValueChange={(value) => {
                                if (value === item.id) {
                                    toggleExpand(item.id);
                                    updatePath(item);
                                } else {
                                    toggleExpand(item.id);
                                }
                            }}
                        >
                            <AccordionItem value={item.id} className="border-none">
                                <div className="flex items-center space-x-1 py-1">
                                    <AccordionTrigger
                                        className="hover:no-underline py-0 pr-2 flex-none"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleExpand(item.id);
                                            updatePath(item);
                                        }}
                                    >
                                        {/* The AccordionTrigger already includes a chevron */}
                                    </AccordionTrigger>
                                    <FilterCheckbox
                                        id={item.id}
                                        checked={isSelected}
                                        className={cn(
                                            isPartial && "bg-primary/25 text-primary-foreground"
                                        )}
                                        onCheckedChange={() => handleSelect(item.id)}
                                        onClick={(e) => e.stopPropagation()}
                                        isPartial={isPartial}
                                    />
                                    <ListItem
                                        item={item}
                                        isSelected={isSelected}
                                        counts={extractCountForKey(filteredCounts, item)}
                                        className={itemStyle}
                                        customComponent={renderCustom && renderCustom(item)}
                                    />
                                </div>
                                <AccordionContent className="pb-0 pt-1 ml-3">
                                    {renderItems(item.children, depth + 1)}
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    ) : (
                        <div className="flex items-center space-x-2 py-2 ml-6">
                            <FilterCheckbox
                                id={item.id}
                                checked={isSelected}
                                className={cn(
                                    isPartial && "bg-primary/25 text-primary-foreground"
                                )}
                                onCheckedChange={() => handleSelect(item.id)}
                                isPartial={false}
                            />
                            <ListItem
                                item={item}
                                isSelected={isSelected}
                                counts={extractCountForKey(filteredCounts, item)}
                                className={itemStyle} />
                        </div>
                    )}
                </div>
            )
        })
    }, [isItemSelected, isPartiallySelected, expandedIds, handleSelect, toggleExpand, updatePath])

    const [expanded, setExpanded] = useState(false)

    return (
        <div className="space-y-2">
            <Accordion
                type="single"
                collapsible
                value={expanded ? 'all' : undefined}
            >
                <AccordionItem value={'all'} className="border-none">
                    <div className="flex items-center space-x-0 py-2 border-y border-zinc-300 bg-zinc-200">
                        <AccordionTrigger
                            className="hover:no-underline py-0 pr-2 flex-none"
                            onClick={(e) => {
                                e.preventDefault();
                                setExpanded(prev => !prev);

                            }}
                        >

                        </AccordionTrigger>
                        <FilterCheckbox
                            id={'root'}
                            checked={rootSelectionState === 'all'}
                            className={cn(
                                rootSelectionState === 'partial' && "bg-primary/25 text-primary-foreground"
                            )}
                            onCheckedChange={toggleRootSelection}
                            onClick={(e) => e.stopPropagation()}
                            isPartial={rootSelectionState === 'partial'}
                        />

                        {/* <span
                            className="text-sm font-medium leading-none cursor-pointer pl-2"
                            onClick={toggleRootExpansion}
                        >
                            {name}
                            <span className="text-xs text-muted-foreground">({getTotalUnfilteredCount(data)})</span>
                        </span> */}
                        <ListItem
                            className="text-sm font-medium leading-none cursor-pointer pl-2"
                            item={{ name: description }}
                            isSelected={rootSelectionState === 'all'}
                            counts={{
                                total: getTotalUnfilteredCount(data),
                                filtered: getTotalFilteredCount(data),
                                missingData: 0,
                                unmatchedSegments: 0,
                                status: 0,
                                excluded: 0
                            }}
                        />
                    </div>
                    <AccordionContent className="pb-0 pt-1 ml-3">
                        {renderItems(filteredData)}
                    </AccordionContent>
                </AccordionItem>

            </Accordion>
            {/* <div className="flex space-x-1">
                <Button onClick={selectAll} size="sm" variant="link" hidden={true}>Select All</Button>
                <Button onClick={deselectAll} size="sm" variant="link">Deselect All</Button>
                <Button onClick={expandAll} size="icon" variant="ghost"><ChevronsLeftRightIcon className="h-5 w-5 text-muted-foreground" /></Button>
                <Button onClick={collapseAll} size="icon" variant="ghost"><ChevronUpDownIcon className="h-5 w-5 text-muted-foreground" /></Button>
            </div> */}
            {/* <div className="relative">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                    placeholder="Search items..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-8"
                />
            </div> */}
            {/* <Breadcrumb path={currentPath} onNavigate={navigateTo} /> */}
            {/* <ScrollArea className="h-full w-full p-0">
                {renderItems(filteredData)}
            </ScrollArea> */}
        </div>
    )
}

type ListItemProps = {
    item: Pick<FilterItem, 'name'>
    isSelected: boolean
    counts: Count;
    // missingCount: number;
    // qaCount: number;
    // filteredCount: number
    // unfilteredCount: number
    customComponent?: React.ReactNode
    className?: string
}

const ListItem: React.FC<ListItemProps> = React.memo(({
    item,
    isSelected,
    counts,
    // filteredCount,
    // unfilteredCount,
    customComponent,
    className
}) => {
    //console.debug('ListItem', counts);
    return (
        <div className="flex-1 flex items-center justify-between px-2">
            <div
                className={cn("text-xs font-medium space-x-1 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70", className)}
            >
                {item.name} <span className="text-foreground font-light text-xs">({counts?.total ?? 0})</span>
                {customComponent ?? null}
            </div>
            {/* <span className="text-xs text-muted-foreground">
                    ({unfilteredCount}/{item.count ?? 0}/{filteredCount ?? 0})
                </span> 
                */}
            <div className="flex flex-row gap-1 items-end justify-center">

                <CountPill count={counts?.unmatchedSegments ?? 0} type="fastfill" />
                <CountPill count={counts?.missingData ?? 0} type="missing" />
                <CountPill count={counts?.status ?? 0} type="status" />
                <CountPill count={counts?.excluded ?? 0} type="excluded" />
                <div className="w-10 flex items-end justify-end">
                    <FilterCountBadge
                        variant={isSelected ? 'selected' : counts?.total ?? 0 > 0 ? 'partial' : 'unselected'}
                        className="bg-zinc-300 py-0.5 font-normal text-foreground"

                    >
                        {counts?.filtered ?? 0}
                    </FilterCountBadge>
                </div>

            </div>
        </div>

    )
});

const FilterCheckbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
        isPartial?: boolean
    }
>(({ className, isPartial, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
            "peer h-4 w-4 shrink-0 rounded-none border border-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
            className
        )}
        {...props}
    >
        <CheckboxPrimitive.Indicator
            className={cn("flex items-center justify-center text-current")}
        >

            {isPartial ? (
                <div className="h-4 w-4 flex items-center justify-center">
                    <div className="h-2 w-2 bg-primary rounded-sm" />
                </div>
            ) : props.checked ? (
                <Check className="h-4 w-4" />
            ) : null}

        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
))
