import { TestFormValues } from './test-form';

const defaultValues: TestFormValues = {
  status: "draft",
  assetId: undefined,
  test_specification_id: undefined,
  building_id: undefined,
  level_id: undefined,
  area_id: undefined,
  assessmentId: undefined,
  element: undefined,
  element_name: undefined,
  element_id: undefined,
  observations: "",
  recommendations: "",
  images: [],
  result: undefined,
  custom: {}
};

export default defaultValues;