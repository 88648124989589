import { ArrowDownWideNarrow, ArrowLeftRight, ArrowUpWideNarrow, BetweenHorizonalStart, BuildingIcon, CircleHelpIcon, ClipboardCheckIcon, ClipboardPenIcon, FileTextIcon, Grid2x2PlusIcon, ListCheckIcon, ListCollapseIcon, Loader2Icon } from "lucide-react";

export const AssetsIcon = BuildingIcon;
export const TestSuitesIcon = ClipboardCheckIcon;
export const TestsIcon = ClipboardPenIcon;
export const InspectionsIcon = Grid2x2PlusIcon;
export const LoadingIcon = Loader2Icon;
export const ElementsIcon = ListCollapseIcon;
export const ReportBlocksIcon = BetweenHorizonalStart;
export const ReportIcon = FileTextIcon;
export const HelpIcon = CircleHelpIcon;

export const ExpandAllIcon = ArrowDownWideNarrow;
export const CollapseAllIcon = ArrowUpWideNarrow;
export const SwitchIcon = ArrowLeftRight;
export const TestSpecificationsIcon = ListCheckIcon;

export const ReportBlockIcon = ({ size = 16, color = "currentColor", strokeWidth = 2, ...props }) => {
    return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M22.43,10.14h.01v-.01l-.01.01ZM22.43,7.01l.01.01v-.01h-.01Z"/>
          <path d="M16.75,6.42c-1.94,0-3.58,.86-4.32,2.09h-.09V1.98l-1.79-1.79-1.79,1.79v5.13l1.64,1.64-1.64,1.64v11.03h3.43v-2.18h.09c.78,1.31,2.5,2.18,4.47,2.18,3.67,0,6.56-3.01,6.56-7.48s-2.89-7.51-6.56-7.51Zm-.81,11.81c-2.09,0-3.61-1.46-3.61-3.34v-1.94c0-1.88,1.52-3.34,3.61-3.34s3.73,1.61,3.73,4.32-1.58,4.29-3.73,4.29Z"/>
          <path d="M8.76,7.11v3.28c-.42-.06-.84-.09-1.22-.09-2.62,0-3.96,1.4-3.96,4.32v6.8l-1.79-1.79L0,21.43V7.14h3.22l.21,2.18h.09c.68-1.52,2.18-2.44,3.91-2.44,.51,0,.98,.09,1.34,.24Z"/>
        </svg>
      );
  };


export const FastFillIcon = ({ width = "24", height = "24", className, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M22.4 9.6l-0.78 0.78-0.78 0.78h-3.63v11.28h-3.61V11.16H6.47v11.28H2.86V11.16H0l0.78-0.78 0.78-0.78-1.56-1.56h4.03V6.84C4.03 2.16 5.95 0 9.32 0c0.75 0 1.47 0.09 2.01 0.27v3.04c-0.33-0.06-0.72-0.12-1.17-0.12-1.74 0-2.52 0.72-2.52 2.52v1.29h7.54V5.74c0-3.82 1.92-5.74 5.29-5.74 0.75 0 1.47 0.09 2.01 0.27v3.04c-0.33-0.06-0.72-0.12-1.17-0.12-1.74 0-2.52 0.72-2.52 2.52v1.29h3.63l1.56 1.56z"/>
    </svg>
  );
};
