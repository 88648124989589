import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import type { TestStatus } from "../types/test-form"
import React, { useCallback, useMemo } from "react"

interface StatusIndicatorProps {
  currentStatus: TestStatus
  onStatusChange: (status: TestStatus) => Promise<void> | void
  saveStatus?: string
  isFormValid: boolean
  areRequiredFieldsFilled: boolean
}

interface StatusConfig {
  label: string
  variant: string
  requiresValidation: boolean
}

const STATUSES = ["archived", "draft", "qa", "published"] as const;

const TEST_STATUS_CONFIG: Record<TestStatus, StatusConfig> = {
  archived: {
    label: "Archived",
    variant: "bg-white text-zinc-500 border-zinc-400",
    requiresValidation: false
  },
  draft: {
    label: "Draft",
    variant: "bg-white text-zinc-950 border border-zinc-950",
    requiresValidation: false
  },
  qa: {
    label: "QA",
    variant: "bg-zinc-500 text-white",
    requiresValidation: true
  },
  published: {
    label: "Final",
    variant: "bg-black text-white",
    requiresValidation: true
  },
} as const;

export const getStatusLabel = (status: TestStatus): string => {
  return TEST_STATUS_CONFIG[status]?.label ?? "Inactive";
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({
  currentStatus,
  onStatusChange,
  saveStatus,
  isFormValid,
  areRequiredFieldsFilled
}) => {

  const getVariant = useCallback((status: TestStatus): string => {
    return TEST_STATUS_CONFIG[status]?.variant ?? "border-zinc-300 bg-transparent text-transparent";
  }, []);

  const isStatusDisabled = useCallback((status: TestStatus): boolean => {
    const config = TEST_STATUS_CONFIG[status.toLowerCase()];
    return config.requiresValidation ? !isFormValid : !areRequiredFieldsFilled;
  }, [isFormValid, areRequiredFieldsFilled]);

  const buttonClassNames = useMemo(() => 
    STATUSES.reduce((acc, status) => ({
      ...acc,
      [status]: cn(
        "capitalize",
        getVariant(status),
        currentStatus === status ? "ring-none ring-offset-0" : "",
        TEST_STATUS_CONFIG[status].requiresValidation && !isFormValid 
          ? "opacity-50 cursor-not-allowed text-red-500 bg-zinc-200" 
          : "",
      )
    }), {} as Record<TestStatus, string>),
    [currentStatus, isFormValid, getVariant]
  );

  const handleStatusChange = useCallback(async (status: TestStatus) => {
    try {
      await onStatusChange(status);
    } catch (err) {
      console.error('Failed to change status:', err);
      // You might want to add proper error handling here
    }
  }, [onStatusChange]);

  return (
    <section aria-label="Status Selection" className="relative">
    <div className="flex items-center gap-2">
      {saveStatus && (
        <p className="text-sm text-green-500 mr-2" role="status">
          ({saveStatus})
        </p>
      )}
      {STATUSES.map((status) => (
        <Button
          key={status}
          variant="outline"
          className={buttonClassNames[status]}
          onClick={() => handleStatusChange(status)}
          disabled={isStatusDisabled(status)}
          aria-label={`Set status to ${getStatusLabel(status)}`}
          aria-current={currentStatus === status ? 'true' : 'false'}
        >
          {getStatusLabel(status)}
        </Button>
      ))}
    </div>
  </section>
  )
}

