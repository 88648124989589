import * as React from "react"
import { cn } from "@/lib/utils"
import { ChevronDown } from "lucide-react"

interface ToggleOption {
  description: string
  value: string
  icon?: React.ReactNode
  tooltip?: string
  disabled?: boolean
}

interface MultiOptionToggleProps {
  options: readonly ToggleOption[]
  value: string
  onChange: (value: string) => void
  icon?: React.ReactNode
  className?: string
  label?: string
}

export default function OptionToggle({
  options = [],
  value,
  onChange,
  icon = <ChevronDown className="h-4 w-4" />,
  className,
  label = "Options",
}: MultiOptionToggleProps) {
  if (!Array.isArray(options) || options.length === 0) {
    return null
  }

  return (
    <div
      role="group"
      aria-label={label}
      className={cn(
        "inline-flex items-center rounded-md bg-muted p-1 text-muted-foreground",
        className
      )}
    >
      {options.map((option) => (
        <button
          key={option.value}
          onClick={() => onChange(option.value)}
          disabled={option.disabled}
          aria-pressed={option.value === value}
          className={cn(
            "inline-flex items-center justify-center rounded-sm px-3 py-1.5 text-sm font-medium",
            "transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
            option.value === value
              ? "bg-white text-foreground shadow-sm"
              : "hover:bg-muted-foreground/10",
            option.disabled && "opacity-50 cursor-not-allowed",
             "transition-transform active:scale-95"
          )}
        >
          {option.value === value && (
            <span className="mr-2 md:hidden" aria-hidden="true">{icon}</span>
          )}
          <span className="hidden md:inline">{option.description}</span>
          <span className="md:hidden">
            {option.description && option.description.length > 0
              ? option.description.charAt(0)
              : ''}
          </span>
        </button>
      ))}
    </div>
  )
}