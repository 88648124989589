import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"
import { AspectRatio } from "@/components/ui/aspect-ratio"
import { ImageOffIcon } from "lucide-react"

const noImagesVariants = cva(
  "flex items-center justify-center bg-white text-muted-foreground border border-muted-background rounded-md",
  {
    variants: {
      size: {
        sm: "w-20 h-20 text-sm",
        md: "w-48 h-48 text-base",
        lg: "w-96 h-96 text-lg",
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
)

export interface NoImagesProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof noImagesVariants> {
  message?: string
  aspectRatio?: number
}

const NoImages = React.forwardRef<HTMLDivElement, NoImagesProps>(
  ({ className, size, message = "No images", aspectRatio = 1, ...props }, ref) => {
    return (
      <div className={cn(noImagesVariants({ size }), className)} ref={ref} {...props}>
        <AspectRatio ratio={aspectRatio} className="w-full h-full flex items-center justify-center">
          <p>{message}</p>
        </AspectRatio>
      </div>
    )
  }
)

NoImages.displayName = "NoImages"

export { NoImages, noImagesVariants }