import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AssetsIcon, ElementsIcon, HelpIcon, InspectionsIcon, ReportBlockIcon, ReportIcon, TestSuitesIcon } from '@/components/raytd/icons';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import classNames from 'classnames';
import { PanelsTopLeft } from 'lucide-react';

const isActiveRoute = (path: string) => {
  if (path === '/') {
    // For home route, it should only be active if it's exactly '/'
    return location.pathname === '/';
  }
  // For other routes, check if the pathname starts with the given path
  return location.pathname.startsWith(path);
};

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <div className={cn("flex items-center font-semibold gap-3 rounded-lg px-3 py-2 text-muted-foreground ml-7")}>
      {title}
    </div>
  );
};

const SidebarNavigation: React.FC = () => {
  const location = useLocation();

  return (
    <nav className="flex flex-col items-start px-2 text-sm font-medium lg:px-4">
      <SideBarItem icon={<PanelsTopLeft className="h-4 w-4" />} title="Dashboard" to="/" />
      <SideBarSeparator />
      <Header title="manage." />
      <SideBarItem icon={<AssetsIcon className="h-4 w-4" />} title="Assets" to="/assets" />
      <SideBarSeparator />
      <Header title="inspect." />
      <SideBarItem icon={<TestSuitesIcon className="h-4 w-4" />} title="Test Suites" to="/test-suites" />
      <SideBarItem icon={<ElementsIcon className="h-4 w-4" />} title="Element Groups" to="/elements" />
      <SideBarItem icon={<InspectionsIcon className="h-4 w-4" />} title="Inspections" to="/inspections" />

      <SideBarSeparator />
      <Header title="report." />
      <SideBarItem icon={<ReportBlockIcon className="h-4 w-4" />} title="Report Blocks" to="/report-blocks" />
      <SideBarItem icon={<ReportIcon className="h-4 w-4" />} title="Reports" to="/reports" />
      <SideBarSeparator spacing="lg" />
      <Header title="support." />
      <SideBarItem icon={<HelpIcon className="h-4 w-4" />} title="Support" to="/support" />
    </nav>
  );
};

export default SidebarNavigation;

type SidebarItemProps = {
  icon: React.ReactNode;
  title: string;
  to: string;
  badge?: React.ReactNode;
};

const SideBarItem: React.FC<SidebarItemProps> = ({ icon, title, to, badge }) => {

  const isActivePath = isActiveRoute(to);

  return (
    <Link
      to={to}
      className={classNames(
        "flex items-center gap-3 rounded-md px-3 max-w-40 py-1 text-muted-foreground transition-all hover:text-primary",
        "w-full min-w-0",
        {
          "text-primary bg-zinc-100": isActivePath,
        }
      )}
    >
      {icon}
      {title}
      {badge && (
        <Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
          {badge}
        </Badge>
      )}
    </Link>
  );
}

interface SideBarSeparatorProps {
  className?: string;
  spacing?: 'sm' | 'md' | 'lg';
}

const SideBarSeparator: React.FC<SideBarSeparatorProps> = ({ 
  className,
  spacing = 'md' 
}) => {
  const spacingClasses = {
    sm: 'my-2',
    md: 'my-4',
    lg: 'my-6'
  };

  return (
    <div className="flex w-full px-6">
      <hr 
        className={cn(
          "w-full border-none h-px bg-zinc-200/50 inset-1",
          spacingClasses[spacing],
          className
        )}
      />
    </div>
  );
}