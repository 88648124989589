import { ReportItem, ReportItemContent, ReportTestItem, TestSpecification } from "@/app/features/reports/utils/entities";
import { useGetReportItemsQuery, useGetReportQuery } from "@app.raytd.com/store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { findFirstContent } from './TestPage';
import convertSpacesToTree from '../features/reports/utils/convertSpacesToTree';
import { skipToken } from "@reduxjs/toolkit/query";
import { TestSuite } from "store/src/lib/tests/entity";

interface TextComparisonProps {
  userText: string;
  predefinedTexts: string[];
}


/**
 * Removes punctuation and normalizes text for comparison
 */
const cleanText = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
    .replace(/\s+/g, ' ')
    .trim();
};


/**
 * Custom hook for text comparison logic
 */
const useTextComparison = (userText: string, predefinedTexts: string[]) => {
  const findUnmatchedSegments = useCallback((): string[] => {
    const cleanedPredefinedTexts = predefinedTexts.map(cleanText);
    let remainingText = userText;
    const unmatchedSegments: string[] = [];

    // Split user text into segments
    const segments = userText.split(/([.!?]+\s+)/);

    segments.forEach(segment => {
      const cleanedSegment = cleanText(segment);
      if (!cleanedSegment) return; // Skip empty segments

      // Check if this segment matches any predefined text
      const hasMatch = cleanedPredefinedTexts.some(predefinedText =>
        cleanedSegment === predefinedText || predefinedText.includes(cleanedSegment)
      );

      if (!hasMatch && cleanedSegment.length > 0) {
        unmatchedSegments.push(segment);
      }
    });

    return unmatchedSegments;
  }, [userText, predefinedTexts]);

  return {
    findUnmatchedSegments
  };
};

interface DetailedComparisonProps {
  userText: string;
  predefinedTexts: string[];
  showStats?: boolean;
  highlightColor?: string;
}

const DetailedComparison: React.FC<DetailedComparisonProps> = ({
  userText,
  predefinedTexts,
  showStats = false,
  highlightColor = 'yellow'
}) => {
  const { findUnmatchedSegments } = useTextComparison(userText, predefinedTexts);
  const unmatchedSegments = findUnmatchedSegments();

  const calculateMatchPercentage = (): number => {
    if (!userText) return 0;
    const totalWords = cleanText(userText).split(' ').length;
    const unmatchedWords = unmatchedSegments
      .reduce((acc, segment) => acc + cleanText(segment).split(' ').length, 0);
    return Math.round(((totalWords - unmatchedWords) / totalWords) * 100);
  };

  return (
    <div className="space-y-4">
      <div className="border rounded-lg p-4 bg-white">
        <Highlighter
          highlightStyle={{ backgroundColor: highlightColor }}
          searchWords={unmatchedSegments}
          textToHighlight={userText}
          autoEscape={true}
        />
      </div>

      {showStats && userText && (
        <div className="space-y-2">
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div className="bg-gray-50 p-3 rounded-md">
              <span className="font-medium">Match Percentage:</span>
              <span className="ml-2">{calculateMatchPercentage()}%</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-md">
              <span className="font-medium">Unmatched Segments:</span>
              <span className="ml-2">{unmatchedSegments.length}</span>
            </div>
          </div>

          {unmatchedSegments.length > 0 && (
            <div className="bg-gray-50 p-3 rounded-md space-y-2">
              <p className="font-medium mb-2">Unmatched Text:</p>
              {unmatchedSegments.map((segment, index) => (
                <div
                  key={index}
                  className="text-red-600 text-sm mt-1 p-2 bg-white rounded shadow-sm border border-zinc-100"
                >
                  "{segment.trim()}"
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};


const TextComparison: React.FC<TextComparisonProps> = ({ userText, predefinedTexts }) => {
  const { findUnmatchedSegments } = useTextComparison(userText, predefinedTexts);

  return (
    <Highlighter
      highlightStyle={{ backgroundColor: 'yellow' }}
      searchWords={findUnmatchedSegments()}
      textToHighlight={userText}
      autoEscape={true}
    />

  );
};

const predefinedTexts = [
  'The quick brown fox jumps over the lazy dog. It was a sunny day!',
  'Hello world. This is a test sentence. How are you today?',
  'React makes UI development fun. TypeScript adds type safety.'
];

export const TextComparisonField: React.FC = () => {
  const [userInput, setUserInput] = useState('');
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const handleOnClick = (text: string) => {
    inputRef.current?.focus();
    setUserInput(prev => prev + text);
  }


  return (
    <div className="p-4 space-y-4">
      <div className="space-y-2">
        <h2>Predefined Texts</h2>
        {
          predefinedTexts.map((text, index) => (
            <div
              key={index}
              onClick={() => handleOnClick(text)}
              className="p-2 border rounded-md bg-white">
              {text}
            </div>
          ))
        }
      </div>
      <div className="space-y-2">
        <label htmlFor="userInput" className="block font-medium">
          Enter your text:
        </label>
        <textarea
          id="userInput"
          ref={inputRef}
          className="w-full p-2 border rounded-md"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          rows={4}
        />
      </div>

      <div className="border-t pt-4 space-y-6">
        <h3 className="font-medium mb-2">Comparison Result:</h3>
        <div className="space-y-4 bg-white border border-zinc-200 rounded-md p-4">

          <TextComparison
            userText={userInput}
            predefinedTexts={predefinedTexts}
          />
        </div>
        <DetailedComparison
          userText={userInput}
          predefinedTexts={predefinedTexts}
          showStats={true}
        />
      </div>
    </div>
  );
};

export type TextComparison = ReportTestItem & {
  observations: string;
  recommendations: string;
  observations_unmatched?: string[];
  recommendations_unmatched?: string[];
  hasUnmatchedSegments?: boolean;
  [key: string]: any;
}

interface ComparisonConfig {
  field: string;
  predefinedTexts: string[];
}

const comparisonConfigs: ComparisonConfig[] = [
  {
    field: 'observations',
    predefinedTexts: [
      'The quick brown fox jumps over the lazy dog. It was a sunny day!',
      'Hello world. This is a test sentence. How are you today?'
    ]
  },
  {
    field: 'recommendations',
    predefinedTexts: [
      'React makes UI development fun. TypeScript adds type safety. It was a sunny day!'
    ]
  }
]

export const usePredefinedTexts = (testSuites: TestSuite[]) => {
  // const { data: report, isLoading: reportIsLoading } = useGetReportQuery(reportId ? reportId : skipToken);

  const getPredefinedTexts = useCallback((field: string, testSpecification: TestSpecification): string[] => {
    if (!testSuites) return [];

    const testSuite = testSuites.find(ts => ts.id === testSpecification.testSuiteId);

    if (field === 'observations') {
      const texts = testSuite?.specifications?.find(spec => spec.id === testSpecification.id)?.observations;
      return texts ? texts.map(obj => obj.description) : [];
    }

    if (field === 'recommendations') {
      const texts = testSuite?.specifications?.find(spec => spec.id === testSpecification.id)?.recommendations;
      return texts ? texts.map(obj => obj.description) : [];
    }

    return [];
  }, [testSuites]);

  return { getPredefinedTexts };
};

export const useTextEnrichment = (
  comparisonConfigs: ComparisonConfig[],
  getPredefinedTexts: (field: string, testSpecification: TestSpecification) => string[]
) => {

  const findUnmatchedSegments = useCallback((text: string, predefinedTexts: string[]): string[] => {
    if (!text) return [];

    const cleanedPredefinedTexts = predefinedTexts.map(cleanText);
    const segments = text.split(/([.!?]+\s+)/);
    const unmatchedSegments: string[] = [];

    segments.forEach(segment => {
      const cleanedSegment = cleanText(segment);
      if (!cleanedSegment) return; // Skip empty segments

      // Check if this segment matches any predefined text
      const hasMatch = cleanedPredefinedTexts.some(predefinedText =>
        cleanedSegment === predefinedText || predefinedText.includes(cleanedSegment)
      );

      if (!hasMatch && cleanedSegment.length > 0) {
        unmatchedSegments.push(segment);
      }
    });

    return unmatchedSegments;
  }, []);

  const checkTestForUnmatchedSegments = useCallback((test: TextComparison) => {
    if (!test) return test;

    const enriched = { ...test };

    comparisonConfigs.forEach(({ field, predefinedTexts: old }) => {
      if (test[field]) {
        const predefinedTexts = getPredefinedTexts(field, test.testSpecification);
        const unmatched = findUnmatchedSegments(test[field], predefinedTexts);
        enriched[`${field}_unmatched`] = unmatched.length > 0 ? unmatched : [];
        enriched.hasUnmatchedSegments = enriched.hasUnmatchedSegments ? true : unmatched.length > 0;
      }

    });

    return enriched;
  }, [comparisonConfigs, findUnmatchedSegments, getPredefinedTexts]);

  const enrichTests = useCallback((tests: TextComparison[]) => {
    if (!tests) return [];
    return tests?.map(test => {
      // const enriched = { ...test };

      // comparisonConfigs.forEach(({ field, predefinedTexts: old }) => {
      //   if (test[field]) {
      //     const predefinedTexts = getPredefinedTexts(field, test.testSpecification);
      //     // console.debug('predefinedTexts', predefinedTexts);
      //     const unmatched = findUnmatchedSegments(test[field], predefinedTexts);
      //     enriched[`${field}_unmatched`] = unmatched.length > 0 ? unmatched : [];
      //     enriched.hasUnmatchedSegments = enriched.hasUnmatchedSegments ? true : unmatched.length > 0;
      //   }

      // });
      //      return enriched;

      return checkTestForUnmatchedSegments(test);

    });
  }, [comparisonConfigs, findUnmatchedSegments, checkTestForUnmatchedSegments, getPredefinedTexts]);

  return {
    enrichTests,
    checkTestForUnmatchedSegments
  }
};

export const findAllContent = (items: ReportItem[]): any[] => {
  const allContent: any[] = [];

  const traverse = (item: ReportItem): void => {
    // Collect content if it exists and has elements
    if (item.content?.length) {
      allContent.push(...item.content);
    }

    // Traverse children recursively
    if (item.children?.length) {
      for (const child of item.children) {
        traverse(child);
      }
    }
  };

  // Traverse each root item
  for (const item of items) {
    traverse(item);
  }

  return allContent;
};

export const TextEnrichments = () => {

  const { data: report, isLoading: reportIsLoading } = useGetReportQuery("2");
  const [selectedId, setSelectedId] = useState<number | null>(2);
  const { data: items, isLoading } = useGetReportItemsQuery("2", {
    selectFromResult: ({ data, isLoading }) => ({
      data: data?.filter(item => item.test === selectedId),
      isLoading
    }),
  });

  // console.debug('TestTile report, items', { report, items })

  const data = useMemo(() => {
    if (!items) return null;
    const flattened = convertSpacesToTree(items as unknown as ReportTestItem[]);
    const content = findAllContent(flattened);
    return content?.map(test => test.testCase);
  }, [items]);

  const { getPredefinedTexts } = usePredefinedTexts(report?.testSuites);
  const { enrichTests } = useTextEnrichment(comparisonConfigs, getPredefinedTexts);

  const enrichedTests = useMemo(() => {
    return enrichTests(data).sort((a, b) => {
      if (a.hasUnmatchedSegments === b.hasUnmatchedSegments) {
        return 0;
      }
      return a.hasUnmatchedSegments ? -1 : 1;
    });
  }, [data]);

  if (reportIsLoading) return <div>Loading report...</div>;
  if (!data && (reportIsLoading || isLoading)) return <div>Loading report items...</div>;

  return (
    (<div className="space-y-4">
      {enrichedTests.map((test, index) => {
        const predefinedTexts = getPredefinedTexts('observations', test.testSpecification);
        // console.debug('predefinedTexts 2', predefinedTexts);
        return (
          (<div key={index} className="border rounded-lg p-4 bg-white space-y-6">
            <h1>Test {test.assessmentTestId}</h1>
            {comparisonConfigs.map(({ field }) => (
              <div key={field} className="mb-4 space-y-4 ">
                <h3 className="font-medium capitalize mb-2">{field}:</h3>

                <div className="space-y-2 p-6 border rounded-md">
                  <Highlighter
                    highlightStyle={{ backgroundColor: 'yellow' }}
                    searchWords={test[`${field}_unmatched`] || []}
                    textToHighlight={test[field]}
                    autoEscape={true}
                  />
                  {test[`${field}_unmatched`]?.length > 0 && (
                    <div className="text-sm text-red-600">
                      Unmatched segments: {test[`${field}_unmatched`].length}
                    </div>
                  )}
                </div>

                <div className="space-y-2 p-2 border rounded-md">
                  <h2 className="font-medium">Fast Fill Texts</h2>
                  {
                    predefinedTexts.map((text, index) => (
                      <div
                        key={index}
                        className="p-2 border rounded-md bg-zinc-100">
                        {text}
                      </div>
                    ))
                  }
                </div>

              </div>
            ))}
          </div>)
        );
      })}
    </div>)
  );
}