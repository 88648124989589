import { FilterCounts } from '../types/filters';
import { ReportTestItem } from '../utils/entities';
import { elementKeyExtractors } from './convertTestsToElementFacets';
import { exclusionKeyExtractors } from './convertTestsToExclusionFacets';
import { TestCounts } from './entities';
import { FilterState } from './facets';

// export type CalculatedFilters = {
//   tests: TestCounts;
// } & {
//   [key in FilterKeys]: Record<string, number>;
// };

export type CalculatedFilters = FilterCounts;
export interface Count {
  total: number;
  filtered: number;
  unmatchedSegments: number;
  missingData: number;
  excluded: number;
  status: number;
}

type CountTypes = keyof Count;

export const defaultCounts: Record<keyof Count, number> = {
  total: 0,
  filtered: 0,
  unmatchedSegments: 0,
  missingData: 0,
  excluded: 0,
  status: 0,
};

const defaultTestCounts: TestCounts = {
  total: 0,
  published: 0,
  qa: 0,
  draft: 0,
  archived: 0,
  missingData: 0,
  fastFill: 0,
  unmatchedSegments: 0,
  manuallyExcluded: 0,
  included: 0,
  status: 0,
};

const defaultValues = {
  tests: { ...defaultTestCounts },
  filtered: { ...defaultTestCounts },
  status: {},
  rating: {},
  testSuite: {},
  space: {},
  elementName: {},
  elementId: {},
  element: {},
  excluded: {},
};

const incrementCounts = (
  counts: Record<string, Count>,
  key: keyof Count | string,
  item: ReportTestItem,
  isFiltered: boolean
) => {
  if (!counts[key]) {
    counts[key] = { ...defaultCounts };
  }

  if (isFiltered) {
    counts[key].filtered++;
    // if (item.hasFastFillQA) counts[key].unmatchedSegments++;
    // if (item.hasMissingData) counts[key].missingData++;
    // if (item.status !== 'published') counts[key].status++;
    // if (item.switches?.include !== true) counts[key].excluded++;

    if (item.exclusionReason === 'missing') {
      counts[key].missingData++;
    }

    if (item.exclusionReason === 'fastfill') {
      counts[key].unmatchedSegments++;
    }

    if (item.exclusionReason === 'excluded') {
      counts[key].excluded++;
    }

    if (item.exclusionReason === 'status') {
      counts[key].status++;
    }

  } else {
    counts[key].total++;
  }
};

export const generateNestedCounts = (
  filteredItems: ReportTestItem[],
  allItems: ReportTestItem[],
  keyExtractors: KeyExtractor[]
): Record<string, Count> => {
  const counts: Record<string, Count> = {};

  const processItems = (items: ReportTestItem[], isFiltered: boolean) => {
    items.forEach((item) => {
      let currentKey = '';

      // Build and process hierarchical keys
      for (const extractor of keyExtractors) {
        const value = extractor(item);
        if (value === null) break;

        currentKey = currentKey? `${currentKey}.${value}` : value;
        incrementCounts(counts, currentKey as CountTypes, item, isFiltered);
      }
    });
  };

  processItems(allItems, false);
  processItems(filteredItems, true);

  return counts;
};

export const countSimpleFilter = (
  filteredItems: ReportTestItem[],
  allItems: ReportTestItem[],
  key: keyof ReportTestItem
): Record<string, Count> => {
  const counts: Record<string, Count> = {};

  const processItems = (items: ReportTestItem[], isFiltered: boolean) => {
    items.forEach((item) => {
      const value = item[key] ? item[key]?.toString().toLowerCase() : 'null';
      if (value) {
        incrementCounts(counts, value as CountTypes, item, isFiltered);
      }
    });
  };

  processItems(allItems, false);
  processItems(filteredItems, true);

  return counts;
};

const calculateTestCounts = (items: ReportTestItem[]): TestCounts => {
  return items.reduce(
    (counts: TestCounts, item) => {
      // Increment total
      counts.total++;

      // Increment status-based counts
      const status = item.status.toLowerCase();
      switch (status) {
        case 'published':
          counts.published++;
          break;
        case 'qa':
          counts.qa++;
          break;
        case 'draft':
          counts.draft++;
          break;
        case 'archived':
          counts.archived++;
          break;
      }

      // Increment special flags
      // if (item.hasFastFillQA) {
      //   counts.fastFill++;
      // }
      // if (item.hasMissingData) {
      //   counts.missingData++;
      // }

      if (item.exclusionReason === 'missing')  {
        counts.missingData++;
      }

      if (item.exclusionReason === 'fastfill') {
        counts.fastFill++;
      }

      if (item.exclusionReason === 'excluded') {
        counts.manuallyExcluded++;
      }

      if (item.exclusionReason === 'included') {
        counts.included++;
      }

      if (item.exclusionReason === 'status') {
        counts.status++;
      }

      if (item.hasUnmatchedSegments && item.switches?.fast_fill_accepted !== true) {
        counts.unmatchedSegments++;
      }

      return counts;
    },
    {
      ...defaultTestCounts
    }
  );
};

export const calculateExclusionCounts = (
  filteredItems: ReportTestItem[],
  allItems: ReportTestItem[]
): Record<string, Count> => {
  const counts: Record<string, Count> = {};

  const processItems = (items: ReportTestItem[], isFiltered: boolean) => {
    items.forEach((item) => {
      const status = item.status.toLowerCase();

      if (item.hasMissingData) {
        incrementCounts(counts, 'missingData', item, isFiltered);
      }

      if (item.hasFastFillQA) {
        incrementCounts(counts, 'unmatchedSegments', item, isFiltered);
      }

      if (item.switches?.include !== true) {
        incrementCounts(counts, 'excluded', item, isFiltered);
      }

      if (item.status !== 'published') {
        incrementCounts(counts, 'status', item, isFiltered);
      }

      if (item.exclusionReason === 'included') {
        incrementCounts(counts, 'included', item, isFiltered);
      }

      // if (item.switches?.include !== true) {
      //   incrementCounts(counts, 'manuallyExcluded', item, isFiltered);
      // }

      if (status === 'draft') {
        incrementCounts(counts, 'status.draft', item, isFiltered);
      }
      if (status === 'qa') {
        incrementCounts(counts, 'status.qa', item, isFiltered);
      }
      if (status === 'archived') {
        incrementCounts(counts, 'status.archived', item, isFiltered);
      }

      // if (item.hasFastFillQA) {
      //   incrementCounts(counts, 'unmatchedSegments', item, isFiltered);
      // }
      // if (item.hasMissingData) {
      //   incrementCounts(counts, 'missingData', item, isFiltered);
      // }
    });
  };

  processItems(allItems, false);
  processItems(filteredItems, true);

  return counts;
};

export type KeyExtractor = (item: ReportTestItem) => string | null;

// Example key extractors for space hierarchy
const spaceKeyExtractors: KeyExtractor[] = [
  (item) => item.assetId?.toString() ?? 'null',
  (item) => (item.buildingId ? `${item.buildingId}` : 'null'),
  (item) => (item.levelId ? `${item.levelId}` : 'null'),
  (item) => (item.areaId ? `${item.areaId}` : 'null'),
];

// Example key extractors for rating hierarchy
const ratingKeyExtractors: KeyExtractor[] = [
  (item) => item.testSpecification.testSuiteId.toString(),
  (item) => (item.result ? `${item.result}` : 'null'),
];

const testSuiteExtractors: KeyExtractor[] = [
  (item) => item.testSpecification.testSuiteId.toString(),
  (item) => item.testSpecification.category,
  (item) => item.testSpecification.subcategory,
  (item) => item.testSpecification.id.toString(),
];

const calculateFilterCounts = (
  filteredItems: ReportTestItem[],
  allItems: ReportTestItem[]
): FilterState['counts'] => {
  if (!allItems?.length) {
    return {
      ...defaultValues,
    };
  }

  const spaceCounts = generateNestedCounts(
    filteredItems,
    allItems,
    spaceKeyExtractors
  );

  // Rating hierarchy counts
  const ratingCounts = generateNestedCounts(
    filteredItems,
    allItems,
    ratingKeyExtractors
  );

  const testSuiteCounts = generateNestedCounts(
    filteredItems,
    allItems,
    testSuiteExtractors
  );

  const elementCounts = generateNestedCounts(
    filteredItems,
    allItems,
    elementKeyExtractors
  );

  const exclusionCounts = generateNestedCounts(
    filteredItems,
    allItems,
    exclusionKeyExtractors
  );

  return {
    tests: calculateTestCounts(allItems),
    filtered: calculateTestCounts(filteredItems),
    space: spaceCounts,
    rating: ratingCounts,
    testSuite: testSuiteCounts,
    element: elementCounts,
    status: countSimpleFilter(filteredItems, allItems, 'status'),
    elementName: countSimpleFilter(filteredItems, allItems, 'element_name'),
    elementId: countSimpleFilter(filteredItems, allItems, 'element_id'),
    excluded: calculateExclusionCounts(filteredItems, allItems),
  };
};

export default calculateFilterCounts;
