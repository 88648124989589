import { TestSuite } from 'store/src/lib/tests/entity';
import { FilterItem, ReportTestItem } from '../utils/entities'
import sortByName from './sortByName';

const extractIdsAndTitles = (item: ReportTestItem) => {
  const { testSuiteId, category, subcategory, id: testSpecificationId, test_suite_title, label } = item.testSpecification;

  const ids = {
    suite: testSuiteId,
    category: `${testSuiteId}.${category}`,
    subcategory: `${testSuiteId}.${category}.${subcategory}`,
    testSpecification: `${testSuiteId}.${category}.${subcategory}.${testSpecificationId.toString()}`,
    testSpecificationId: testSpecificationId
  };

  const titles = {
    suite: test_suite_title,
    category,
    subcategory,
    testSpecification: label,
    ratingType: item.ratingType
  };

  return { ids, titles };
}

const convertTestsToTestSuiteFilters = (testItems: ReportTestItem[]): FilterItem[] => {
  const filters = new Map<string, FilterItem>();

  // Single pass through test items
  for (const item of testItems) {
    // Create ids and titles in a more concise way
    const { ids, titles } = extractIdsAndTitles(item);

    // Get or create asset
    let suite = filters.get(ids.suite.toString());
    if (!suite) {
      suite = {
        key: 'testSuite',
        id: ids.suite.toString(),
        name: titles.suite,
        children: [],
        count: 0,
        extraProps: {
          ratingType: item.ratingType
        }
      };
      filters.set(ids.suite.toString(), suite);
    }
    suite.count++;

    // Get or create building
    let category = suite.children?.find(b => b.id === ids.category);
    if (!category) {
      category = {
        key: 'testSuite',
        id: ids.category,
        name: titles.category,
        children: [],
        count: 0
      };
      suite.children?.push(category);
    }
    category.count++;

    // Get or create level
    let subcategory = category.children?.find(l => l.id === ids.subcategory);
    if (!subcategory) {
      subcategory = {
        key: 'testSuite',
        id: ids.subcategory,
        name: titles.subcategory,
        children: [],
        count: 0
      };
      category.children?.push(subcategory);
    }
    subcategory.count++;

    // Get or create area
    let spec = subcategory.children?.find(a => a.id === ids.testSpecification);
    if (!spec) {
      spec = {
        key: 'testSuite',
        id: ids.testSpecification,
        name: titles.testSpecification,
        count: 0
      };
      subcategory.children?.push(spec);
    }
    spec.count++;
  }
  
  return Array.from(filters.values())
    .sort(sortByName)
    .map(root => {
      root.children?.sort(sortByName);
      root.children?.forEach(lvl1 => {
        lvl1.children?.sort(sortByName);
        lvl1.children?.forEach(lvl2 => {
          lvl2.children?.sort(sortByName);
        });
      });
      return root;
    });
};

export default convertTestsToTestSuiteFilters;
