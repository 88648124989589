import { useRef, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { useReportDisplayContext } from './report-viewer-context';

type StickyHeaderProps = {
    children: React.ReactNode;
}

const StickyHeader: React.FC<StickyHeaderProps> = ({ children }) => {

    const headerRef = useRef<HTMLElement>(null);
    const sentinelRef = useRef<HTMLDivElement>(null);

    const { isHeaderFixed, setIsHeaderFixed } = useReportDisplayContext();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // When intersection ratio is less than 1, element is partially hidden
                // console.debug('toolbar entry', entry.intersectionRatio, entry.isIntersecting);
                setIsHeaderFixed(!entry.isIntersecting);
            },
            {
                threshold: [.3], // Observe when element is fully visible
                rootMargin: "242px 0px 0px 0px" // Trigger slightly before element starts moving
            }
        );

        if (sentinelRef.current) {
            observer.observe(sentinelRef.current);
        }

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const targetDiv = document.querySelector('[data-viewport-type="window"]');
        if (targetDiv) {
            targetDiv.className = isHeaderFixed ? 'header-fixed' : 'header-default';
        }
    }, [isHeaderFixed]);


    return (
        <>
            {/* Sentinel element to detect scroll position */}
            <div ref={sentinelRef} className="absolute h-[1px] top-0" />

            <header
                ref={headerRef}
                className={cn(
                    // "w-full",
                    // "max-w-screen-2xl mx-auto",
                    "max-w-screen-2xl mx-auto ",
                    "transition-transform duration-500 ease-in-out transform-gpu translate-y-0",
                    isHeaderFixed
                        ? "translate-y-0 bg-muted/50 fixed top-0 left-[220px] right-0 z-50 bg-white border-b-2 border-zinc-200 px-6 py-2 max-w-[1300px]"
                        : "relative bg-white w-full"
                )}
            >
                {/* <div className={cn(isHeaderFixed  ? "flex w-[220px] lg:w-[200px]" : "hidden")}>&nbsp;</div> */}
                {children}
            </header>
        </>
    );
};

export default StickyHeader;