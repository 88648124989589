import { ReportItemContent, ReportTestItem } from './entities';

export default function convertTestToReportItem(
  testItem: ReportTestItem
): ReportItemContent {
  return {
    id: testItem.assessmentTestId.toString(),
    title:
      testItem.testSpecification.label ||
      testItem.element_name ||
      testItem.element_id ||
      '',
    //      testSuiteName: testItem.testSpecification.test_suite_title,
    //    result: testItem.result,
    score: testItem.score,
    //  resultDescription: testItem.resultDescription,
    testCase: testItem,
    ratingType: testItem.ratingType,
    //images: testItem.images ?? []
  };
}

export const convertTestsToReportItems = (
  testItems: ReportTestItem[]
): ReportItemContent[] => {
  return testItems.map(convertTestToReportItem);
}