import { CustomField, TestSuite } from 'store/src/lib/tests/entity'
import { type TestFormValues } from '../types/test-form'

const getDefaultValueForFieldType = (field: CustomField): any => {
  switch (field.type) {
    case 'number':
    case 'scrollable_number':
      return null;

    case 'text':
    case 'textarea':
    case 'raytd':
      return '';

    case 'date':
      return null;

    case 'select':
    case 'radio':
      if (field.selection_type === 'single') {
        return '';
      } else {
        return [];
      }

    case 'checkbox':
    case 'switch':
      return false;

    default:
      return '';
  }
};


const filterActiveCustomFields = (
  initialData: Partial<TestFormValues>,
  testSuite: TestSuite
): Partial<TestFormValues> => {
  if (!testSuite.custom_fields) {
    return initialData;
  }

  // Create maps for quick lookups
  const activeFieldMap = new Map(
    testSuite.custom_fields
      .map(field => [field.field_id, field.active])
  );

  // First, remove any values from initialData that are inactive
  const cleanedCustom = Object.entries(initialData.custom || {})
    .reduce((acc, [key, value]) => {
      if (activeFieldMap.get(key)) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);

  // Then add all active fields, using existing values or undefined
  const finalCustom = testSuite.custom_fields
    .filter(field => field.active)
    .reduce((acc, field) => {
      acc[field.field_id] = cleanedCustom[field.field_id] ?? getDefaultValueForFieldType(field);
      return acc;
    }, {} as Record<string, any>);

  return {
    ...initialData,
    custom: finalCustom
  };
};

export default filterActiveCustomFields;