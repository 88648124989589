import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"
import { AspectRatio } from "@/components/ui/aspect-ratio"
import { Skeleton } from "@/components/ui/skeleton"

const imageContainerVariants = cva("overflow-hidden", {
    variants: {
        size: {
            sm: "w-20 h-20",
            md: "w-48 h-48",
            lg: "w-[350px] h-[350px]",
        },
    },
    defaultVariants: {
        size: "md",
    },
})

const imageVariants = cva("object-cover transition-opacity duration-300 h-full w-full")

export interface ImageWithPlaceholderProps
    extends React.ImgHTMLAttributes<HTMLImageElement>,
    VariantProps<typeof imageContainerVariants> {
    src: string
    alt: string
    aspectRatio?: number
    fallback?: React.ReactNode
}

const ImageWithPlaceholder = React.forwardRef<HTMLImageElement, ImageWithPlaceholderProps>(
    ({ className, size, src, alt, aspectRatio = 1, fallback, ...props }, ref) => {
        const [isLoading, setIsLoading] = React.useState(true)
        const [hasError, setHasError] = React.useState(false)

        const handleLoad = () => setIsLoading(false)
        const handleError = () => {
            setIsLoading(false)
            setHasError(true)
        }

        return (
            <div className={cn(imageContainerVariants({ size }), className)}>
                <AspectRatio ratio={aspectRatio}>
                    {isLoading && (
                        <Skeleton className="w-full h-full" />
                    )}
                    {hasError && fallback ? (
                        fallback
                    ) : (
                        <img
                            ref={ref}
                            src={src}
                            alt={alt}
                            className={cn(
                                imageVariants(),
                                isLoading ? "opacity-0" : "opacity-100"
                            )}
                            onLoad={handleLoad}
                            onError={handleError}
                            {...props}
                        />
                    )}
                </AspectRatio>
            </div>
        )
    }
)

ImageWithPlaceholder.displayName = "ImageWithPlaceholder"

export { ImageWithPlaceholder, imageContainerVariants }