import { TestSuiteStatus } from 'store/src/lib/tests/entity';
import TestStatusBadge from './test-status-badge';
import { TestCountVariant } from '@/components/raytd/test-count-pill';

const StatusBadges = ({ status }: { status: TestCountVariant }) => {
    return (
        <>
            {['archived', 'draft', 'qa', /*'final',*/ 'published'].map(s =>
                <TestStatusBadge
                    key={s}
                    status={s as TestSuiteStatus}
                    active={status.toLowerCase() === s} />
            )}
        </>
    )
}

export default StatusBadges;