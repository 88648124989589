import { ReportItem, ReportItemContent, ReportTestItem } from './entities';
import convertTestToReportItem from './convertTestItem';
import { customCompare } from './sorter';

export default function convertElementToTree(
  testItems: ReportTestItem[]
): ReportItem[] {
  const elementMap: { [key: string]: ReportItem } = {};

  testItems.forEach(item => {
    const path = item.element?.path;
    
    if (!path || path.length === 0) {
      if (!elementMap["(No Element)"]) {
        elementMap["(No Element)"] = {
          id: "(No Element)",
          title: "(No Element)",
          content: []
        };
      }
      elementMap["(No Element)"].content?.push(convertTestToReportItem(item));
      return;
    }

    let currentNode: ReportItem | undefined;
    let parentNode: ReportItem | undefined;

    path.forEach((pathItem, index) => {
      const nodeId = pathItem.id.toString();
      const nodeName = pathItem.name;
      const isLastLevel = index === path.length - 1;

      if (index === 0) {
        // Root level - store in elementMap
        if (!elementMap[nodeId]) {
          elementMap[nodeId] = {
            id: nodeId,
            title: nodeName,
            children: isLastLevel ? undefined : []
          };
        }
        currentNode = elementMap[nodeId];
      } else {
        // Child level - find or create in parent's children
        if (!parentNode?.children) {
          parentNode!.children = [];
        }
        
        currentNode = parentNode?.children.find(child => child.id === nodeId);
        
        if (!currentNode) {
          currentNode = {
            id: nodeId,
            title: nodeName,
            children: isLastLevel ? undefined : []
          };
          parentNode?.children.push(currentNode);
        }
      }

      if (isLastLevel) {
        if (!currentNode.content) {
          currentNode.content = [];
        }
        currentNode.content.push(convertTestToReportItem(item));
      }

      parentNode = currentNode;
    });
  });
  
  // Helper function to sort nodes recursively
  function sortNode(node: ReportItem) {
    if (node.children) {
      node.children.sort((a, b) => customCompare(a.title, b.title));
      node.children.forEach(sortNode);
    }
    if (node.content) {
      node.content.sort((a, b) => customCompare(a.title, b.title));
    }
  }

  // Count items recursively
  function countItems(item: ReportItem): number {
    if (item.content) {
      return item.content.length;
    }
    if (item.children) {
      item.count = item.children.reduce((sum, child) => sum + countItems(child), 0);
      return item.count;
    }
    return 0;
  }

  // Sort and count all nodes
  const sortedTree = Object.values(elementMap)
    .sort((a, b) => customCompare(a.title, b.title));

  sortedTree.forEach(node => {
    sortNode(node);
    countItems(node);
  });

  return sortedTree;
}
