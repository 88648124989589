import { testBadgeVariants } from "@/components/raytd/test-count-pill"
import { Badge } from "@/components/ui/badge"
import { cn } from "@/lib/utils"
import { sentenceCase } from "change-case"
import * as React from "react"
import { useTestTileContext } from "./context"
import { useMemo } from "react"

const testBadgeMissingDataVariants = {
    archived: "border-tests-error text-tests-error opacity-50",
    draft: "border-tests-error text-tests-error",
    qa: "bg-red-500/50 text-white",
    published: "bg-red-500 text-white",
    final: "bg-red-500 text-white",
    inactive: 'border-zinc-300 bg-transparent text-transparent'
};

const fastFillErrorVariants = {
    archived: "border-tests-missing text-tests-missing opacity-50",
    draft: "border-tests-missing text-tests-missing",
    published: "bg-tests-missing text-white",
    final: "bg-tests-missing text-white",
    qa: " bg-tests-missing/50 text-white",
    inactive: 'border-tests-missing bg-transparent text-transparent'
};

const TestStatusBadge = React.memo(({ status, active = false }: { status: string, active: boolean }) => {

    const { hasMissingData, hasFastFillErrors } = useTestTileContext();

    const getVariant = useMemo(() => {

        const statusVariant = status.toLowerCase();

        if (active) {
            if (hasMissingData) {
                return testBadgeMissingDataVariants[statusVariant];
            }
            if (hasFastFillErrors) {
                return fastFillErrorVariants[statusVariant];
            }
            return testBadgeVariants[statusVariant];
        }

        return testBadgeVariants['inactive'];

    }, [active, hasMissingData, hasFastFillErrors, status]);

    return (
        <Badge
            variant="outline"
            className={cn(
                "px-2.5 py-0.5 rounded-md font-medium",
                getVariant,
            )}
        >
            {(status.toLowerCase() === 'qa' ? 'QA' : sentenceCase(status === 'published' ? 'final' : status))}
        </Badge>
    )
});

export default TestStatusBadge;