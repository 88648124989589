import { Checkbox } from "@/components/ui/checkbox";
import { FormControl, FormDescription, FormField, FormItem } from "@/components/ui/form";
import { CustomFieldProps } from '../custom-fields-container';
import { FormLabelWithIndicator } from "../form-label-with-indicator";

type CustomCheckboxFieldProps = CustomFieldProps & {
}

export function CustomCheckboxField({
    control,
    name,
    label,
    description,
    showDescription,
    info,
    required,
    isValid,
}: CustomCheckboxFieldProps) {
    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className="">
                    <FormLabelWithIndicator label={label} isValid={isValid} required={required} info={info} />
                    <FormControl>
                        <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                        />
                    </FormControl>
                    {showDescription && (<FormDescription>{description}</FormDescription>)}
                    {/* <FormMessage /> */}
                </FormItem>
            )}
        />
    )
}

