import { isEqual } from 'lodash';

interface RegistrationIssue {
  default: any;
  current: any;
}

interface RegistrationIssues {
  [key: string]: RegistrationIssue | string;
}

const findRegistrationIssues = (
  defaultValues: Record<string, any>, 
  currentValues: Record<string, any>
): RegistrationIssues => {
  const issues: RegistrationIssues = {};
  
  Object.keys(defaultValues).forEach((key) => {
    if (!(key in currentValues)) {
      issues[key] = "Field not registered";
    } else if (!isEqual(defaultValues[key], currentValues[key])) {
      issues[key] = {
        default: defaultValues[key],
        current: currentValues[key]
      };
    }
  });
  
  return issues;
};

export default findRegistrationIssues;