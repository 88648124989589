import { Loader2Icon } from "lucide-react";
import Spinner from "../../../components/raytd/spinner";
import { test } from '@playwright/test';

type Props = {
    title: string;
    subtitle?: string;
    badge?: React.ReactNode;
    showSpinner?: boolean;
    showLoading?: boolean;
    children: React.ReactNode;
    rightComponents?: React.ReactNode;
    className?: string;
    contentClassName?: string;
}

const LoadingIndicator = () => {
    return (
        <div className="flex items-center justify-center h-48">
            <Loader2Icon className="h-8 w-8 animate-spin" />
        </div>
    );
}

const SubPageLayout: React.FC<Props> = ({
    title,
    subtitle,
    badge,
    showSpinner = false,
    showLoading = false,
    children,
    rightComponents,
     className = "",
    contentClassName = ""
}) => {

    return (
        <div className={`flex flex-col h-full ${className}`} test-id="sub-page-layout">
            <header className="mb-6 space-y-1 shrink-0">
                <div className="flex flex-wrap items-center justify-between gap-4">
                    <div className="flex items-center gap-3">
                        <h1 className="text-2xl font-semibold tracking-tight">
                            {title}
                            {badge && <span className="ml-2">{badge}</span>}
                        </h1>
                        {showSpinner && <Spinner />}
                    </div>
                    
                    {rightComponents && (
                        <div className="flex items-center gap-3">
                            {rightComponents}
                        </div>
                    )}
                </div>
                
                {subtitle && (
                    <p className="text-sm text-muted-foreground">
                        {subtitle}
                    </p>
                )}
            </header>

            <section 
                className={`flex-1 min-h-0 ${contentClassName}`}
                aria-busy={showLoading}
            >
                {showLoading ? <LoadingIndicator /> : <div className="h-full w-full">{children}</div>}
            </section>
        </div>
    );
}

export default SubPageLayout;