import { convertAverage } from '@/app/features/reports/ratingHelpers';
import { BuildingIcon } from '@/components/icons/building-icon';
import { LevelIcon } from '@/components/icons/level-icon';
import { SpaceIcon } from '@/components/icons/space-icon';
import { TestsIcon } from '@/components/raytd/icons';
import { Pill } from '@/components/raytd/pill';
import { RatingMixedScore, RatingTypes } from '@/components/raytd/rating-tool';
import { ValuePill } from '@/components/raytd/value-pill';
import { cn } from '@/lib/utils';
import React, { memo, useCallback, useMemo } from 'react';
import { FlattenedItem } from '../utils/entities';
import { ChevronDownIcon, ChevronRight } from 'lucide-react';
import { useReportContext } from '@/app/features/reports/viewContext';
import CountPill from './excluded-count-pill';
import { c } from 'node_modules/@udecode/plate-list/dist/BaseListPlugin-B0eGlA5x';

interface RowHeaderProps {
    item: FlattenedItem;
    indent: number;
    toggleExpand: (id: string) => void;
    active: boolean;
    parents?: string[];
    className?: string;
}

const ScoreDisplay = memo(({ score }: { score: string }) => (
    <div className="w-28 flex flex-row justify-center items-center">
        <Pill
            variant="enabled"
            size="md"
            className=""
        >
            {score === undefined ? '-' : `${score} / 5.00`}
        </Pill>
    </div>
));

const ParentPath = memo(({ parents, hasContents = false }: { parents: string[], hasContents?: boolean }) => (
    <>
        {parents.map((parent, index) => (
            <React.Fragment key={index}>
                {index < parents.length - 1 ? (
                    <>
                        <span className="font-normal text-zinc-800 tracking-tight">{parent}</span>
                        <ChevronRight size="12" className="text-zinc-500" />
                    </>
                ) : (
                    hasContents ? (
                        <span className="font-semibold tracking-wider">{parent}</span>
                    ) : (
                        <span className="font-normal text-zinc-600">{parent}</span>
                    )
                    // <span className="font-medium">{parent}</span>
                )}
            </React.Fragment>
        ))}
    </>
));

const ExpandableContent = memo(({
    item,
    handleExpand,
    IconType
}: {
    item: FlattenedItem;
    handleExpand: (e: React.MouseEvent) => void;
    IconType: typeof ChevronDownIcon | typeof ChevronRight;
}) => (
    <button
        onClick={handleExpand}
        className="flex flex-1 items-center hover:bg-accent/10 rounded-sm group"
    >
        <div className="flex items-center">
            <IconType
                className="h-6 w-6 text-zinc-500 ml-1 group-hover:text-zinc-700"
            // expanded={item.isExpanded} 
            />
        </div>
        <h3 className="flex-1 font-medium flex flex-row items-center gap-2 px-2">
            <ParentPath parents={item.parents || []} hasContents={item.hasContent} />
        </h3>
    </button>
));

const MetricsSection = memo(({
    item
}: {
    item: FlattenedItem
}) => {
    const convertedScore = useMemo(() =>
        convertAverage(parseFloat(item.score)),
        [item.score]
    );

    const { includedCount, score, ratingTypes } = item;

    return (
        <div className="flex gap-2 items-center">

            <CountPill count={item.fastFillCount} type="fastfill" />
            <CountPill count={item.missingCount} type="missing" />
            <CountPill count={item.nonFinalCount} type="status" />
            <CountPill count={item.excludedCount} type="excluded" />

            <div className="w-10 flex items-start">
                <ValuePill
                    iconOnly
                    size="small"
                    label="Tests"
                    icon={<TestsIcon className="h-4 w-4 text-zinc-500" />}
                    value={includedCount} 
                    className="font-semibold"
                    />
            </div>
            <ScoreDisplay score={score} />
            <RatingMixedScore
                width={140}
                height={36}
                type={ratingTypes as any}
                score={convertedScore} />
        </div>
    );
});

const RowHeader: React.FC<RowHeaderProps> = ({
    item,
    toggleExpand,
    active,
    className,
}) => {

    // const {showFastQA} = useReportContext();
    const showFastQA = false;

    const handleExpand = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        toggleExpand(item.id);
    }, [item.id, toggleExpand]);

    const IconType = useMemo(() =>
        // item.level === 0 ? BuildingIcon : 
        // item.level === 1 ? LevelIcon : 
        // SpaceIcon
        item.isExpanded ? ChevronDownIcon : ChevronRight
        , [item.isExpanded]);

    return (
        <div
            className={cn(
                "w-full border-b border-b-zinc-300",
                active && "bg-yellow-50 border-t border-zinc-700",
                !active && "bg-white/5",

                className
            )}
        >
            <div className={cn(
                "flex items-center px-0 h-[42px]",
                item.hasContent && 'bg-zinc-200/75 border-t border-zinc-700',
            )}>
                {(item.hasChildren || item.content) ? (
                    <ExpandableContent
                        item={item}
                        handleExpand={handleExpand}
                        IconType={IconType}
                    />
                ) : (
                    <>
                        <span className="w-[28px]" />
                        <h3 className="flex-1 font-medium flex flex-row items-center gap-2">
                            <ParentPath parents={item.parents || []} hasContents={false} />
                        </h3>
                    </>
                )}

                {showFastQA && item.fastFillCount > 0 && (<div>{item.fastFillCount}</div>)}

                <MetricsSection
                    item={item}
                />
            </div>
        </div>
    );
};

export default memo(RowHeader);
