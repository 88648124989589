import { ConvertResultToIndex, ratingMix } from "@/app/features/reports/ratingHelpers";
import React, { useMemo } from "react";

export type RatingTypes =
    | 'compliance'
    | 'compliance_b'
    | 'compliance_a'
    | 'condition'
    | 'mixed'
    | 'frankenstein'
    | 'no_rating'
    | 'generic'
    | 'unknown';

type RatingWidgetProps = {
    width: number;
    height: number;
    selected?: number;
}


type RatingProps = RatingWidgetProps & {
    type: RatingTypes;
    showSelector?: boolean;
    isMissing?: boolean;
}

const getRatingTypeFile = (type: RatingTypes): string => {
    const ratingMap: Record<RatingTypes, string> = {
        compliance: 'Compliance-A',
        compliance_a: 'Compliance-A',
        compliance_b: 'Compliance-B',
        condition: 'Condition',
        mixed: 'Mixed1',
        frankenstein: 'Mixed2',
        no_rating: 'No_Rating',
        generic: 'No_Rating',
        unknown: 'Mixed_'
    };

    return ratingMap[type] || 'Mixed_';
};

const validateSelected = (selected: number | undefined): number | false => {
    if (selected === undefined || isNaN(selected)) return false;
    if (selected < 0) return 0;
    if (selected > 5) return 5;
    return selected;
};

const RatingImage = React.memo(({ src, alt, width, height, title }: {
    src: string;
    alt: string;
    width: number;
    height: number;
    title: string;
}) => (
    <img
        className="object-contain max-w-full"
        src={src}
        alt={alt}
        width={width}
        height={height}
        title={title}
        style={{ width: `${width}px`, height: `${height}px` }}
    />
));

RatingImage.displayName = 'RatingImage';

type RatingScoreProps = Omit<RatingProps, 'selected'> & {
    score?: number; // 0-5
};

export const RatingScore: React.FC<RatingScoreProps> = React.memo(({ type, score, width, height, showSelector=false, isMissing=false }) => {
    const index = ConvertResultToIndex(score);
    return <Rating type={type} selected={index} width={width} height={height} showSelector={showSelector} isMissing={isMissing} />;
});

export type RatingMixedScoreProps = Omit<RatingScoreProps, 'type'> & {
    type: RatingTypes[];
};

export const RatingMixedScore: React.FC<RatingMixedScoreProps> = React.memo(({ type, score, width, height }) => {
    const mixed = ratingMix(type);
    return <RatingScore type={mixed} score={score} width={width} height={height} />;
});

export const Rating = React.memo(({ type, width, height, selected, showSelector=false, isMissing=false }: RatingProps) => {
    const validatedSelected = useMemo(() => validateSelected(selected), [selected]);

    const ratingTypeFile = useMemo(() => getRatingTypeFile(type), [type]);

    if (validatedSelected === false && showSelector && type !== 'generic') {

        if (isMissing) {
            // console.debug('Rating: Missing rating');
            return (
                <RatingImage
                    src={`reports/ratings/PNG__${ratingTypeFile}_MISSINGDATA.png`}
                    alt={`Rating: Missing`}
                    width={width}
                    height={height}
                    title={`${type} / Missing`}
                />
            );
        }

        return (
            <RatingImage
                src={`reports/ratings/PNG__${ratingTypeFile}_SELECTOR.png`}
                alt={`Rating: None`}
                width={width}
                height={height}
                title={`${type} / No selection`}
            />
        );
    }

    if (validatedSelected === false || type === 'generic') {
      //  console.debug('Rating: No rating selected');
        return (
            <RatingImage
                src="reports/ratings/PNG__No_Rating.png"
                alt="No Rating"
                width={width}
                height={height}
                title="Not rated"
            />
        );
    }

    const selectedIndex = validatedSelected + 1;

    if (!Number.isInteger(selectedIndex) || selectedIndex < 0 || selectedIndex > 5) {
        throw new Error(`Rating: Selected must be an integer between 0 and 4 or undefined: ${selectedIndex}`);
    }

    return (
        <RatingImage
            src={`reports/ratings/PNG__${ratingTypeFile}_${selectedIndex}.png`}
            alt={`Rating: ${selectedIndex}`}
            width={width}
            height={height}
            title={`${type} / Result ${selectedIndex}`}
        />
    );
});

Rating.displayName = 'Rating';

export default Rating;