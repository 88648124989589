import { ReportItem, ReportItemContent, ReportTestItem, TestSpecification } from "@/app/features/reports/utils/entities";
import { useGetReportItemsQuery, useGetReportQuery, useGetReportTestSuitesQuery } from "@app.raytd.com/store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { findFirstContent } from './TestPage';
import convertSpacesToTree from '../features/reports/utils/convertSpacesToTree';
import { skipToken } from "@reduxjs/toolkit/query";
import { TestCase, TestItem, TestSuite } from "store/src/lib/tests/entity";
import { useDynamicResolver } from "@/app/features/reports/utils/testFormValidator";
import convertInspectionToFormValues from "@/app/features/reports/utils/convertInspectionToFormValues";
import { createSelector } from "@reduxjs/toolkit";
import { TypedUseQueryStateResult } from "@reduxjs/toolkit/query/react";
import filterActiveCustomFields from '../features/reports/utils/filterActiveCustomFields';
import defaultValues from "@/app/features/reports/types/form-default-values";
import { Button } from "@/components/ui/button";
import { useEnchancedDynamicResolver } from '../features/reports/utils/testFormValidator';
import { set } from "date-fns";
import TestTile from "@/app/features/reports/components/test-tile";
import { Report } from "@/app/features/reports/types/report";
import { ReportEntity } from "store/src/lib/services/types";
import { ReportViewerContext } from '../features/reports/components/report-viewer-context';
import { cn } from "@/lib/utils";
import { ValidationErrorMessage, ValidationError, ValidationErrors } from '../features/reports/types/missing-data';
import useValidationResults, { ValidationResults } from '../features/reports/utils/useValidationResults';
import getTestSuiteForSpecification from '../features/reports/utils/getTestSpecification';

interface TestSuiteResult {
    suite: TestSuite;
    specification: TestCase;
}




export type TextComparison = ReportTestItem & {
    observations: string;
    recommendations: string;
    observations_unmatched?: string[];
    recommendations_unmatched?: string[];
    hasUnmatchedSegments?: boolean;
    [key: string]: any;
}

export const findAllContent = (items: ReportItem[]): any[] => {
    const allContent: any[] = [];

    const traverse = (item: ReportItem): void => {
        // Collect content if it exists and has elements
        if (item.content?.length) {
            allContent.push(...item.content);
        }

        // Traverse children recursively
        if (item.children?.length) {
            for (const child of item.children) {
                traverse(child);
            }
        }
    };

    // Traverse each root item
    for (const item of items) {
        traverse(item);
    }

    return allContent;
};

/**

 *    const custom = filterActiveCustomFields(initialData, testSuite.suite);

      console.debug('Resetting form with custom fields', { custom });

      methods.reset({
        ...defaultValues,
        ...custom,

      });


 */

const makeSelectFilteredItems = (selectedId: number | null) =>
    createSelector(
        [(state: { data?: TestItem[] }) => state.data],
        (data) => data?.filter(item => item.test === selectedId) ?? []
    )

const report_id = "13";





export const MissingData = () => {

    const [selectedTestSpecificationId, setSelectedId] = useState<number | null>(2);
    const { data: report, isLoading: reportIsLoading } = useGetReportQuery(report_id);

    const selectFilteredItems = useMemo(
        () => makeSelectFilteredItems(selectedTestSpecificationId),
        [selectedTestSpecificationId] // Recreate selector only when ID changes
    );

    // const { data: items, isLoading } = useGetReportItemsQuery(report_id, {
    //     selectFromResult: (result) => ({
    //         data: selectFilteredItems(result),
    //         isLoading: result.isLoading,
    //       }),
    // });

    const { data: items, isLoading } = useGetReportItemsQuery(report_id);

    // const items = useMemo(
    //     () => allItems?.filter(item => item.test === selectedId),
    //     [allItems, selectedId] // Only changes when underlying data changes
    //   );

    // console.debug('TestTile report, items', { report, items })

    const data = useMemo(() => {
        if (!items) return null;
        const flattened = convertSpacesToTree(items as unknown as ReportTestItem[]);
        const content = findAllContent(flattened);
        return content?.map(test => test.testCase);
    }, [items]);

    const { data: testSuites, isLoading: isLoadingSpecifications } = useGetReportTestSuitesQuery(report?.id ? Number(report?.id) : skipToken);

    // const resolver = useDynamicResolver(testSuites as any);
    // const resolver = useEnchancedDynamicResolver(testSuites as any);

    // const [missingData, setMissingData] = useState<Map<number, ValidationResults>>(new Map());

    // const [isValidationLoading, setIsValidationLoading] = useState(false);

    // const fetchValidationResults = useCallback(async (data) => {
    //     setIsValidationLoading(true);
    //     try {
    //         if (!data?.length || !testSuites) return;

    //         const results = await Promise.all(
    //             data.map(async (testCase: ReportTestItem) => {
    //                 const initialData = convertInspectionToFormValues(testCase);
    //                 const testSuite = getTestSuiteForSpecification(testSuites, testCase.test);

    //                 const values = filterActiveCustomFields(initialData, testSuite.suite);

    //                 const resolverResult = await resolver({
    //                     ...defaultValues,
    //                     ...values,
    //                 });

    //                 // console.debug('Resolver result', { values, errors: resolverResult?.errors });

    //                 return [testCase.assessmentTestId, {
    //                     ...resolverResult,
    //                     testCase
    //                 }] as const;
    //             })
    //         );

    //         const validationResults = new Map<number, ValidationResults>(results);

    //         setMissingData(prevTests => {
    //             // Compare stringified maps for equality check
    //             const prevMap = prevTests instanceof Map ? prevTests : new Map();
    //             if (mapEquals(prevMap, validationResults)) {
    //                 return prevTests;
    //             }
    //             return validationResults;
    //         });

    //     } catch (error) {
    //         console.error('Error enriching tests:', error);
    //     } finally {
    //         setIsValidationLoading(false);
    //     }

    // }, [resolver, testSuites]);

    // useEffect(() => {
    //     if (!data?.length || !testSuites) return;
    //     fetchValidationResults(data);
    // }, [data, testSuites]);

    // const handleRevalidate = useCallback(() => {
    //     setMissingData(new Map());
    //     fetchValidationResults(data);
    // }, [data]);

    // console.debug('enrichedTests', missingData, testSuites);

    const { validationResults: missingData, isValidationLoading, revalidateData: handleRevalidate } = useValidationResults(data, testSuites);

    if (reportIsLoading || isLoadingSpecifications) return <div>Loading report...</div>;
    if ((reportIsLoading || isLoading)) return <div>Loading report items...</div>;

    return (
        (<div className="space-y-4">

            <h1> Missing Data</h1>
            <Button onClick={handleRevalidate} disabled={isValidationLoading}>Click to revalidate</Button>
            <div className="bg-white  p-4 rounded-md space-y-6">
                <ValidationSummaryComponent data={missingData} />
                <ValidationDebugUI data={missingData} report={report} />
            </div>
        </div>)
    );
}

const ValidationDebugUI: React.FC<{ data: Map<number, ValidationResults>, report: ReportEntity }> = ({ data, report }) => {
    const errorTypes = useMemo(() => {
        const types = new Set<string>();
        data.forEach(item => {
            Object.keys(item.errors).forEach(key => types.add(key));
        });
        return Array.from(types);
    }, [data]);

    const missingData = useMemo(() => {
        const result = new Map<number, ValidationErrors>();

        data.forEach((validation, id) => {
            const errors = Object.entries(validation.errors).reduce((acc, [type, error]) => {
                if (Array.isArray(error) ? error.length > 0 : !!error) {
                    acc[type] = error;
                }
                return acc;
            }, {} as ValidationErrors);
            if (Object.keys(errors).length > 0) {
                result.set(id, errors);
            }
        });

        return result;

    }, [data]);

    const [filterType, setFilterType] = useState<string>('all');

    const getErrorCounts = () => {
        const counts: Record<string, number> = { total: data.size };
        errorTypes.forEach(type => {
            counts[type] = Array.from(data.values())
                .filter(item => {
                    const error = item.errors[type];
                    return Array.isArray(error) ? error.length > 0 : !!error;
                })
                .length;
        });
        return counts;
    };

    const formatError = (error: ValidationErrorMessage[] | ValidationErrorMessage) => {
        if (Array.isArray(error)) {
            return error.map((err, index) => (
                <div key={index} className="ml-4 text-sm">
                    • {err?.message}
                </div>
            ));
        } else {
            return <span className="ml-4 text-sm">{error?.message}</span>;
        }

    };

    const errorCounts = getErrorCounts();

    return (
        <ReportViewerContext.Provider
            value={{
                // data: [],
                // report,
                activeItems: [], setActiveItems: () => { },
                // expandedItems: new Set(),
                // setExpandedItems: () => { },
                // expandAll: () => { },
                // collapseAll: () => { },
                // viewMode: 'details',
                showTestEditor: undefined,
                isScrolling: false,
                //clearFilterInput: () => { },
                //filterInput: '',
                showFastFillQA: false,
                // filteredCounts: undefined,
                // unfilteredCounts: undefined,
                // groupMode: 'element',
                // missingData: missingData,
            }}>
            <div className="p-4 space-y-4">
                <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
                    <h2 className="text-lg font-bold">Validation Debug</h2>
                    <div className="flex flex-wrap gap-2 text-sm">
                        {Object.entries(errorCounts).map(([type, count]) => (
                            <span key={type} className="px-2 py-1 bg-white rounded">
                                {type}: {count}
                            </span>
                        ))}
                    </div>
                </div>

                <div className="flex gap-2 mb-4 flex-wrap">
                    <button
                        onClick={() => setFilterType('all')}
                        className={`px-3 py-1 rounded ${filterType === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                        All
                    </button>
                    {errorTypes.map(type => (
                        <button
                            key={type}
                            onClick={() => setFilterType(type)}
                            className={`px-3 py-1 rounded ${filterType === type ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                        >
                            {type}
                        </button>
                    ))}
                </div>

                <div className="grid gap-4">
                    {Array.from(data.entries()).map(([id, validation]) => {
                        if (filterType !== 'all' && !validation.errors[filterType]) return null;

                        const assessment = report.assessments.find(assessment => assessment.id === validation.testCase?.assessmentId);
                        const testSuite = getTestSuiteForSpecification(report.testSuites, validation.testCase?.test);

                        return (
                            <div key={id} className={cn("border rounded-lg p-4 hover:shadow-md transition-shadow",
                                Object.keys(validation.errors).length > 0 ? 'border-red-500' : 'border-gray-200'
                            )}>
                                <div className="flex justify-start items-center mb-3">
                                    <span className="font-medium">ID: {id}</span>
                                    <div className="ml-4 bg-zinc-200 rounded-lg text-sm p-2">{Object.keys(validation.errors).length ?? 0}</div>
                                    {/* <div className="flex gap-2 flex-wrap">
                                        {Object.keys(validation.errors).map(errorType => {
                                            const error = validation.errors[errorType];
                                            const count = Array.isArray(error) ? error.length : 1;
                                            return (
                                                <span
                                                    key={errorType}
                                                    className="text-xs px-2 py-1 rounded bg-gray-100"
                                                >
                                                    {errorType} ({count})
                                                </span>
                                            );
                                        })}
                                    </div> */}
                                </div>

                                <div className="space-y-3">
                                    {Object.entries(validation.errors).map(([errorType, messages]) => (
                                        <div key={errorType} className="bg-gray-50 p-1 rounded">
                                            <div className="font-normal text-xs mb-1">{errorType}: {formatError(messages)}</div>
                                        </div>
                                    ))}

                                    <div className="p-6 border border-zinc-100">

                                        <TestTile
                                            item={validation.testCase}
                                            assessment={assessment}
                                            testSuite={testSuite?.suite}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </ReportViewerContext.Provider>
    );
};


interface ValidationSummary {
    total: number;
    passing: number;
    failing: number;
    errorTypes: Record<string, number>;
    statusBreakdown: Record<string, number>;
}

const ValidationSummaryComponent: React.FC<{ data: Map<number, ValidationError> }> = ({ data }) => {
    const summary = useMemo(() => {
        const result: ValidationSummary = {
            total: data.size,
            passing: 0,
            failing: 0,
            errorTypes: {},
            statusBreakdown: {}
        };

        data.forEach(validation => {
            // Count passing vs failing items
            const hasErrors = Object.keys(validation.errors).length > 0;
            if (hasErrors) {
                result.failing++;
            } else {
                result.passing++;
            }

            // Count error types
            Object.entries(validation.errors).forEach(([type, errors]) => {
                result.errorTypes[type] = (result.errorTypes[type] || 0) + 1;

                // Special handling for status errors to breakdown invalid values
                if (type === 'status' && Array.isArray(errors)) {
                    errors.forEach(error => {
                        const match = error?.message?.match(/received '(\w+)'/);
                        if (match) {
                            const status = match[1];
                            result.statusBreakdown[status] = (result.statusBreakdown[status] || 0) + 1;
                        }
                    });
                }
            });
        });

        return result;
    }, [data]);

    return (
        <div className="bg-white rounded-lg shadow p-4 mb-4">
            <h2 className="text-lg font-bold mb-3">Validation Summary</h2>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
                <div className="bg-gray-50 p-3 rounded">
                    <div className="text-2xl font-bold">{summary.total}</div>
                    <div className="text-sm text-gray-600">Total Items</div>
                </div>
                <div className="bg-green-50 p-3 rounded">
                    <div className="text-2xl font-bold text-green-700">{summary.passing}</div>
                    <div className="text-sm text-green-600">Passing</div>
                </div>
                <div className="bg-red-50 p-3 rounded">
                    <div className="text-2xl font-bold text-red-700">{summary.failing}</div>
                    <div className="text-sm text-red-600">Failing</div>
                </div>
                <div className="bg-yellow-50 p-3 rounded">
                    <div className="text-2xl font-bold text-yellow-700">
                        {((summary.failing / summary.total) * 100).toFixed(1)}%
                    </div>
                    <div className="text-sm text-yellow-600">Failure Rate</div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="border rounded p-3">
                    <h3 className="font-semibold mb-2">Error Types</h3>
                    <div className="space-y-1">
                        {Object.entries(summary.errorTypes).map(([type, count]) => (
                            <div key={type} className="flex justify-between text-sm">
                                <span>{type}</span>
                                <span className="font-medium">{count}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="border rounded p-3">
                    <h3 className="font-semibold mb-2">Invalid Status Values</h3>
                    <div className="space-y-1">
                        {Object.entries(summary.statusBreakdown).map(([status, count]) => (
                            <div key={status} className="flex justify-between text-sm">
                                <span>{status}</span>
                                <span className="font-medium">{count}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
