import { cva, type VariantProps } from "class-variance-authority"

const testTypePillVariants = cva(
  "px-3 py-1.5 rounded-md text-xs font-medium border",
  {
    variants: {
      type: {
        compliance: "bg-green-500",
        generic: "",
        condition: "",
        numerical: "bg-pink-400",
      },
      inverse: {
        true: "bg-transparent",
      }
    },
    compoundVariants: [
      {
        type: "compliance",
        inverse: true,
        className: "text-green-500 border-green-500 bg-white",
      },
      {
        type: "generic",
        inverse: false,
        className: "text-white bg-zinc-500 border-zinc-500"
      },
      {
        type: "generic",
        inverse: true,
        className: "text-zinc-500 bg-white border-zinc-500"
      },
      {
        type: "condition",
        inverse: true,
        className: "bg-white text-sky-500 border-sky-500",
      },
      {
        type: "condition",
        inverse: false,
        className: "bg-sky-500 text-white border-transparent",
      },
      {
        type: "numerical",
        inverse: true,
        className: "bg-white text-pink-400 border-pink-400",
      },
    ],
    defaultVariants: {
      type: "generic",
      inverse: false,
    },
  }
)

export type TestType = "compliance" | "generic" | "condition" | "numerical"

export const getTypeName = (type: TestType): string => {
  const names: Record<TestType, string> = {
    compliance: "Compliance",
    generic: "General",
    condition: "Condition",
    numerical: "Numerical",
  }
  return names[type] || "Unknown"
}

interface TestTypePillProps extends VariantProps<typeof testTypePillVariants> {
  type: TestType
  inverse?: boolean
}

export default function TestTypePill({ type, inverse = false }: TestTypePillProps) {
  return (
    <div className={testTypePillVariants({ type, inverse })}>
      {getTypeName(type)}
    </div>
  )
}

import { Badge } from "@/components/ui/badge"
import { cn } from "@/lib/utils"
import { sentenceCase } from 'change-case';

type TestSuiteTypeVariant = "compliance" | "condition" | "generic" | "numerical"

interface TestSuiteTypeBadgeProps {
  type: TestSuiteTypeVariant
  size?: "sm" | "md" | "lg"
}
export const getBaseTestSuiteType = (testSuiteType: string) => {
  return testSuiteType.split('_')[0];
}

export function TestSuiteTypeBadge({ type, size="md" }: TestSuiteTypeBadgeProps) {
  const convertedTestTypeType = getBaseTestSuiteType(type) as TestSuiteTypeVariant
  const variantStyles = {
    compliance: "bg-green-500 text-white hover:bg-emerald-600",
    condition: "bg-sky-500 text-white hover:bg-blue-600",
    generic: "bg-gray-500 text-white hover:bg-gray-600",
    numeric: "bg-purple-500 text-white hover:bg-purple-600"
  }

  return (
    <Badge
      variant="default"
      className={cn(
        "px-3 py-0.5 rounded-md text-xs font-normal shadow-none",
        size === "sm" && "px-1",
        variantStyles[convertedTestTypeType]
      )}
    >
      {sentenceCase(convertedTestTypeType === 'generic' ? 'general' : convertedTestTypeType)}
    </Badge>
  )

  
}

