import { ReportItem, ReportItemContent, ReportTestItem } from './entities';
import convertTestToReportItem from './convertTestItem';
import { customCompare } from './sorter';
import { compare } from 'natural-orderby';
import { convertRatingToScore, convertScoreToRating, getOverallRatingLabel, getRatingGroupName } from '../ratingHelpers';
import { TestSuite } from 'store/src/lib/tests/entity';


export function scoreCompare(a: string, b: string): number {
    const noPattern = /No .*/;

    // If a or b is a "No" item, move it to the bottom
    const isANoItem = noPattern.test(a);
    const isBNoItem = noPattern.test(b);
  
    if (isANoItem && !isBNoItem) return 0;  // a goes after b
    if (!isANoItem && isBNoItem) return -1; // a goes before b
  
    // Otherwise, apply natural sort
    return compare({
        order: 'desc'
    })(a, b);
  }

  export default function convertScoreToTree(
    testItems: ReportTestItem[],
    testSuites: TestSuite[]
  ): ReportItem[] {
    // First level map for test suites
    const suiteMap: { [key: string]: ReportItem } = {};
  
    // Helper to get normalized suite title
    const getSuiteTitle = (item: ReportTestItem): string => {
      return item.testSpecification?.test_suite_title?.trim() || '(No Suite)';
    };

    const getRatingLabel = (item: ReportTestItem): string => {
      const testSuite = testSuites.find(ts => ts.id === item.testSpecification?.testSuiteId);
      const result = item.result;
      const score = convertRatingToScore(result);
      const resultLabel = result
      ? `${
        testSuite?.ratingScale?.[`${result}_label`] ?? result
      } (${score?.toFixed(2)}/5.00)`
      : '(No Rating)';

      return resultLabel;
    };
  
    // Group items by suite and score
    testItems.forEach((item) => {
      
      const scoreLabel = getRatingLabel(item);
      const suiteTitle = getSuiteTitle(item);
  
      // Initialize suite node if it doesn't exist
      if (!suiteMap[suiteTitle]) {
        suiteMap[suiteTitle] = {
          id: suiteTitle,
          title: suiteTitle,
          children: []
        };
      }
  
      const suiteNode = suiteMap[suiteTitle];
  
      // Find or create score node
      let scoreNode = suiteNode.children?.find(child => child.title === scoreLabel);
      
      if (!scoreNode) {
        scoreNode = {
          id: `${suiteTitle}-${scoreLabel}`,
          title: scoreLabel,
          content: []
        };
        suiteNode.children?.push(scoreNode);
      }
  
      // Add content to score node
      const contentItem: ReportItemContent = convertTestToReportItem(item);
      scoreNode.content?.push(contentItem);
    });
  
    // Sort and count
    const result = Object.values(suiteMap)
      .sort((a, b) => customCompare(a.title, b.title))
      .map(suite => {
        // Sort scores within suite
        if (suite.children) {
          suite.children.sort((a, b) => scoreCompare(a.title, b.title));
        }
  
        // Count total items in suite
        suite.count = suite.children?.reduce((sum, scoreNode) => 
          sum + (scoreNode.content?.length || 0), 0
        ) || 0;
  
        return suite;
      });
  
    return result;
  }
  