import { useAuth } from "../_components";
import { useEffect } from "react";

const ProfileLoader = ({ children }) => {
    const {refreshUser} = useAuth();

    useEffect(() => {
        refreshUser();
    }, [refreshUser]);
  
    return children;
  };

  export default ProfileLoader;