import { TestSuite } from 'store/src/lib/tests/entity';
import { FilterItem, ReportTestItem } from '../utils/entities';
import sortByName from './sortByName';
import { convertRatingToScore } from '@/app/features/reports/ratingHelpers';
import { createFacet, FacetChild, FacetConfig, FacetCounts } from './facets';
import { FilterKeys } from '../types/filters';

const sortByScore = (a: FilterItem, b: FilterItem): number => {
  const aScore = convertRatingToScore(a.extraProps?.result);
  const bScore = convertRatingToScore(b.extraProps?.result);

  // Handle cases where either score is undefined
  if (aScore === undefined && bScore === undefined) return 0;
  if (aScore === undefined) return 1; // Move undefined to end
  if (bScore === undefined) return -1; // Move undefined to end

  return bScore - aScore;
};

const createRatingFacetConfig = (
  testSuites: TestSuite[]
): FacetConfig<ReportTestItem, FacetChild> => ({
  key: 'rating', // Changed to match original
  getId: (item) => item.testSpecification.testSuiteId.toString(),
  getName: (item) => item.testSpecification.test_suite_title,
  getChildren: (item) => {
    const testSuite = testSuites.find(
      (ts) => ts.id === item.testSpecification.testSuiteId
    );
    
    const result = item.result;
    const score = convertRatingToScore(result);
    const resultLabel = result
    ? `${
      testSuite?.ratingScale?.[`${result}_label`] ?? result
    } (${score?.toFixed(2)}/5.00)`
    : '(No Rating)';
    
    return [
      {
        id: `${item.testSpecification.testSuiteId}.${result}`, // Matches original composite key
        key: 'rating',
        name: resultLabel,
        count: 1,
        extraProps: {
          result,
          key: 'rating', // Matches original child key
        },
      },
    ];
  },
  extraProps: (item) => ({
    testSuiteId: item.testSpecification.testSuiteId,
  }),
});

const convertTestsToRatingFilters = (
  filteredItems: ReportTestItem[],
  allItems: ReportTestItem[],
  testSuites: TestSuite[],
  counts?: FacetCounts
): FilterItem[] => {
  if (!allItems?.length) return [];

  const config = createRatingFacetConfig(testSuites);

  const facets = createFacet(filteredItems, allItems, config, counts);

  return facets.sort(sortByName).map((facet) => ({
    ...facet,
    children: facet.children
      ?.map((child) => ({
        ...child,
        key: 'rating' as FilterKeys, // Ensure child key matches original
        count: counts?.filtered?.[child.id] ?? child.count,
        totalCount: counts?.total?.[child.id] ?? child.count,
        children: [],
      }))
      .sort(sortByScore),
  }));
};

export default convertTestsToRatingFilters;
