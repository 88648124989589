import * as React from "react"
import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Textarea } from "@/components/ui/textarea"
import * as Sentry from "@sentry/react";
import { useAuth } from "@/app/_components";

export function FeedbackDialog() {
    const [open, setOpen] = React.useState(false)
    const [feedback, setFeedback] = React.useState("")
    const [isSubmitted, setIsSubmitted] = React.useState(false)

    const { user } = useAuth();

    const handleSubmit = async () => {
        // Send feedback to Sentry
        Sentry.captureFeedback({
            message: feedback,
            email: user.email,
            name: user.name,
        });

        setFeedback("")
        setIsSubmitted(true)
    }


    const handleClose = () => {
        setOpen(false)
        // Reset the submitted state after dialog is closed
        setTimeout(() => {
            setIsSubmitted(false)
        }, 300)
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button size="sm" className="w-full bg-zinc-200 text-zinc-950 border shadow-none border-zinc-300">
                    Send Feedback
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[475px] md:max-w-[600px]">
                {!isSubmitted ? (
                    <>
                        <DialogHeader>
                            <DialogTitle>Your feedback helps make Raytd better</DialogTitle>
                            <DialogDescription>What can we do to make Raytd a 14/10 experience for you?</DialogDescription>
                        </DialogHeader>
                        <div className="mt-4">
                            <Textarea
                                placeholder="Start typing..."
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                className="min-h-[150px]"
                            />
                        </div>
                        <DialogFooter className="mt-6">
                            <Button variant="outline" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit}>Send</Button>
                        </DialogFooter>
                    </>
                ) : (
                    <>
                        <DialogHeader>
                            <DialogTitle>Thank you for your feedback</DialogTitle>
                        </DialogHeader>
                        <div className="space-y-4 py-4">
                            <p>Your experience means the world to us.</p>
                            <p>
                                Your feedback will be reviewed by one of our team and we will reach out to let you know what we can do
                                to make Raytd a 14/10 experience for you.
                            </p>
                            <p>
                                If you require more urgent support, you can also use our live chat function during standard Australian
                                business hours.
                            </p>
                            <p className="text-sm text-muted-foreground">Inspect. Report. Analyse. Better with Raytd.</p>
                        </div>
                        <DialogFooter>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogFooter>
                    </>
                )}
            </DialogContent>
        </Dialog>
    )
}


export default FeedbackDialog;  