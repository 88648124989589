
import { ReportItem, ReportItemContent, ReportTestItem } from './entities';
import convertTestToReportItem from './convertTestItem';
import { customCompare } from './sorter';

export default function convertTestSpecificationToTree(testItems: ReportTestItem[]
): ReportItem[] {
  const testSuiteMap: { [key: string]: ReportItem } = {};

  testItems.forEach((item) => {
    const testSpecification = item.testSpecification;

    // Determine the grouping based on the type
    const isConditionOrGeneric =
      testSpecification.type === 'condition' ||
      testSpecification.type === 'generic';

    const testSuiteTitle = testSpecification.test_suite_title;
    const category = testSpecification.category || '(No Category)';
    const subcategory = testSpecification.subcategory || '(No Subcategory)';
    const label = testSpecification.label;

    // Ensure test_suite_title exists in the map
    if (!testSuiteMap[testSuiteTitle]) {
      testSuiteMap[testSuiteTitle] = {
        id: testSuiteTitle,
        title: testSuiteTitle,
        children: [],
        content: [],
      };
    }

    const testSuiteNode = testSuiteMap[testSuiteTitle];

    if (isConditionOrGeneric) {
      // Directly under test_suite_title for compliance or generic types
    //   let labelNode = testSuiteNode.children?.find(
    //     (c) => c.id === `${testSuiteTitle}.${label}`
    //   );
    //   if (!labelNode) {
    //     labelNode = {
    //       id: `${testSuiteTitle}.${label}`,
    //       title: label,
    //       content: [],
    //     };
    //     testSuiteNode.children?.push(labelNode);
    //   }

      // Add the content item to the label node
      const contentItem: ReportItemContent = convertTestToReportItem(item);

      testSuiteNode.content?.push(contentItem);
    } else {
      // Group under test_suite_title > category > subcategory > label
      let categoryNode = testSuiteNode.children?.find(
        (c) => c.id === `${testSuiteTitle}.${category}`
      );
      if (!categoryNode) {
        categoryNode = {
          id: `${testSuiteTitle}.${category}`,
          title: category,
          children: [],
        };
        testSuiteNode.children?.push(categoryNode);
      }

      let subcategoryNode = categoryNode.children?.find(
        (sc) => sc.id === `${testSuiteTitle}.${category}.${subcategory}`
      );
      if (!subcategoryNode) {
        subcategoryNode = {
          id: `${testSuiteTitle}.${category}.${subcategory}`,
          title: subcategory,
          children: [],
        };
        categoryNode.children?.push(subcategoryNode);
      }

      let labelNode = subcategoryNode.children?.find(
        (l) => l.id === `${testSuiteTitle}.${category}.${subcategory}.${label}`
      );
      if (!labelNode) {
        labelNode = {
          id: `${testSuiteTitle}.${category}.${subcategory}.${label}`,
          title: label,
          content: [],
        };
        subcategoryNode.children?.push(labelNode);
      }

      // Add the content item to the label node
      const contentItem: ReportItemContent =convertTestToReportItem(item);

      labelNode.content?.push(contentItem);
    }
  });

  // Sort test_suite_title, categories, subcategories, and labels using customCompare
  const sortedTestSuites = Object.values(testSuiteMap).sort((a, b) =>
    customCompare(a.title, b.title)
  );

  sortedTestSuites.forEach((testSuite) => {
    // Sort categories inside each test_suite
    testSuite.children?.sort((a, b) => customCompare(a.title, b.title));

    testSuite.children?.forEach((category) => {
      // Sort subcategories inside each category
      category.children?.sort((a, b) => customCompare(a.title, b.title));

      category.children?.forEach((subcategory) => {
        // Sort labels inside each subcategory
        subcategory.children?.sort((a, b) => customCompare(a.title, b.title));
      });
    });
  });

  // Now count the items
  function countItems(item: ReportItem): number {
    if (item.content) {
      return item.content.length;
    }
    if (item.children) {
      item.count = item.children.reduce(
        (sum, child) => sum + countItems(child),
        0
      );
      return item.count;
    }
    return 0;
  }

  sortedTestSuites.forEach((testSuite) => {
    countItems(testSuite);
  });

  // Return the sorted tree structure
  return sortedTestSuites;
}
