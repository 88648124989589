import convertElementToTree from '@/app/features/reports/utils/convertElementsToTree';
import convertScoreToTree from '@/app/features/reports/utils/convertScoreToTree';
import useValidationResults from '@/app/features/reports/utils/useValidationResults';
import {
  TextComparison,
  usePredefinedTexts,
  useTextEnrichment,
} from '@/app/utils/TextComparison';
import {
  useGetReportItemsQuery,
  useGetReportQuery,
} from '@app.raytd.com/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import convertSpacesToTree from '../utils/convertSpacesToTree';
import convertTestSpecificationToTree from '../utils/convertTestsToTree';
import {
  ExclusionReasons,
  ReportItem,
  ReportTestItem,
} from '../utils/entities';
import useReportItemFilters from '../utils/useReportItemsFilter';
import { BookMark, ReportGroupModes } from '../types';

interface ComparisonConfig {
  field: string;
  predefinedTexts: string[];
}

const comparisonConfigs: ComparisonConfig[] = [
  {
    field: 'observations',
    predefinedTexts: [],
  },
  {
    field: 'recommendations',
    predefinedTexts: [],
  },
];

export type ReportFilters = Pick<
  ReturnType<typeof useReportItemFilters>,
  | 'selectedFilters'
  | 'facets'
  | 'filteredCounts'
  | 'unfilteredCounts'
  | 'filtersActive'
  | 'setSelectedFilters'
  | 'updateFilters'
  | 'removeFilter'
  | 'filterInput'
  | 'setFilterInput'
>;

function useReportData(reportId: string, editMode = true) {
  const [dataState, setDataState] = useState<{
    data: ReportTestItem[];
    bookmarks: BookMark[];
    missingSegments: number;
    missingDataCount: number;
  }>({
    data: [],
    bookmarks: [],
    missingSegments: 0,
    missingDataCount: 0,
  });

  const {
    data: allTestItems,
    dataUpdatedAt,
    error,
    isLoading,
  } = useGetReportItemsQuery(reportId, {
    selectFromResult: (result) => ({
      data: result.data,
      error: result.error,
      isLoading: result.isLoading,
      dataUpdatedAt: result.fulfilledTimeStamp,
    }),
  });

  const {
    data: report,
    error: reportError,
    isLoading: reportLoading,
  } = useGetReportQuery(reportId);

  const { getPredefinedTexts } = usePredefinedTexts(report?.testSuites);
  const { checkTestForUnmatchedSegments } = useTextEnrichment(
    comparisonConfigs,
    getPredefinedTexts
  );
  const { missingData, isValidationLoading } = useValidationResults(
    allTestItems as unknown as ReportTestItem[],
    report?.testSuites
  );

  const enrichedTestItems = useMemo(() => {
    if (!allTestItems) return [];
    const items = allTestItems.map((testItem) => {
      const testItemWithUnmatchedSegments = checkTestForUnmatchedSegments(
        testItem as unknown as TextComparison
      );
      const hasFastFillErrors =
        testItemWithUnmatchedSegments.hasUnmatchedSegments &&
        !testItem.switches?.fast_fill_accepted;
      const hasMissingData = missingData.has(testItem.assessmentTestId);

      let reason: ExclusionReasons = 'included';
      if (hasMissingData) reason = 'missing';
      else if (hasFastFillErrors) reason = 'fastfill';
      else if (testItem.status.toLowerCase() !== 'published') reason = 'status';
      else if (testItem.switches.include === false) reason = 'excluded';

      return {
        ...testItemWithUnmatchedSegments,
        hasUnmatchedSegments:
          testItemWithUnmatchedSegments.hasUnmatchedSegments,
        hasFastFillQA: hasFastFillErrors,
        hasMissingData,
        exclusionReason: reason,
      };
    });

    return editMode
      ? items
      : items.filter((item) => item.exclusionReason === 'included');
  }, [
    allTestItems,
    missingData,
    checkTestForUnmatchedSegments,
    dataUpdatedAt,
    editMode,
  ]);

  const {
    selectedFilters,
    facets,
    filteredData,
    filteredCounts,
    unfilteredCounts,
    filtersActive,
    setSelectedFilters,
    updateFilters,
    removeFilter,
    filterInput,
    setFilterInput
  } = useReportItemFilters(enrichedTestItems, report);

  const updateGroupedData = useCallback(
    (groupMode: ReportGroupModes, data: ReportTestItem[]) => {
      switch (groupMode) {
        case 'element':
          return convertElementToTree(data);
        case 'location':
          return convertSpacesToTree(data);
        case 'test':
          return convertTestSpecificationToTree(data);
        case 'rating':
          return convertScoreToTree(data, report?.testSuites);
        default:
          console.error('Unknown group mode', groupMode);
          return data;
      }
    },
    [report?.testSuites]
  );

  useEffect(() => {
    if (!filteredData) {
      setDataState({
        data: [],
        bookmarks: [],
        missingSegments: 0,
        missingDataCount: 0,
      });
      return;
    }

    const missingSegments = filteredData.filter(
      (item: ReportTestItem) => item.hasFastFillQA
    ).length;
    const missingDataCount = filteredData.reduce(
      (acc, item) => (missingData.has(item.assessmentTestId) ? acc + 1 : acc),
      0
    );

    const total = filteredData.length;
    const bookmarks = filteredData.reduce((acc, item, index) => {
      const relativePosition = (index / total) * 100;
      if (item.hasUnmatchedSegments) {
        acc.push({
          index,
          title: item.assessmentTestId,
          position: relativePosition,
          type: 'fastfill',
        });
      }
      if (item.hasMissingData) {
        acc.push({
          index,
          title: item.assessmentTestId,
          position: relativePosition,
          type: 'missing',
        });
      }
      return acc;
    }, [] as BookMark[]);

    setDataState({
      data: filteredData,
      bookmarks,
      missingSegments,
      missingDataCount,
    });
  }, [filteredData, missingData]);

  return {
    ...dataState,
    report,
    enrichedTestItems,
    missingData,
    filteredData,
    totalDataCount: allTestItems?.length ?? 0,
    isLoading: isLoading || reportLoading || isValidationLoading,
    error: error || reportError,
    filters: {
      selectedFilters,
      facets,
      filteredCounts,
      unfilteredCounts,
      filtersActive,
      setSelectedFilters,
      updateFilters,
      removeFilter,
      filterInput,
      setFilterInput
    },
    updateGroupedData,
  };
}

export default useReportData;
