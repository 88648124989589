import React, { useEffect } from 'react';
import MobileNavigation from './MobileNavigation';
import SearchBar from './SearchBar';
import UserMenu from './UserMenu';
import BreadcrumbNavigation from './BreadcrumbNavigation';
import HeaderLogo from './HeaderLogo';
import { cn } from '@/lib/utils';

const Header: React.FC<{compact?:boolean}> = ({compact}) => {

  useEffect(() => {
    console.debug('Header mounted', {compact});
    return () => {
      console.debug('Header unmounted');
    }
  }, [compact]);

  return (
    <header 
      className={cn("flex items-center gap-4 border-none", 
        "justify-between px-1 overflow-hidden",
        "transition-all duration-400 ease-in-out",
        compact ? "bg-white shadow-md h-[0px]" : "h-[60px]"
      )}>
      <MobileNavigation />
      {/* <SearchBar /> */}
      <BreadcrumbNavigation />
      <HeaderLogo/> 
    </header>
  );
};

export default Header;
