import { ImageRow } from '@/app/features/reports/v2/ImageGrid';
import TextWithBreaks from '@/components/raytd/convert-line-break';
import AuthorBadge from '@/components/raytd/user-avatar';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { cn } from '@/lib/utils';
import { Assessment, useToggleTestSwitchMutation } from '@app.raytd.com/store';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TestSuite } from 'store/src/lib/tests/entity';
import { ReportTestItem } from '../utils/entities';
import useReportViewerContext, { useReportGroupingContext, useReportViewContext } from './report-viewer-context';
import { TestItemHeader } from './test-tile-header';
import { TestTileContext, useTestTileContext } from './test-tile/context';
import CustomFieldContainer from './test-tile/custom-fields';
import TileExpander from './test-tile/expander';
import FastFillText from './test-tile/fast-fill-text';
import TextTile from './test-tile/text-with-label';
import StatusBadges from './test-tile/save-status-badges';
import { TestCountVariant } from '@/components/raytd/test-count-pill';
import { getRatingLabel } from '../ratingHelpers';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/motion/accordion';
import { Tooltip, TooltipTrigger } from '@/components/ui/tooltip';
import { TooltipContent } from '@radix-ui/react-tooltip';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { FastFillIcon } from '@/components/raytd/icons';
import HighlightText from '@/app/features/reports/components/test-tile/highlight-text';

const toggleVariants = {
    included: 'bg-zinc-600 text-white data-[state=checked]:bg-zinc-600',
    excluded: 'bg-white border-zinc-500 text-white data-[state=checked]:bg-zinc-600 data-[state=unchecked]:bg-white',
    fastfill: 'text-white data-[state=unchecked]:bg-transparent data-[state=checked]:bg-tests-missing data-[state=unchecked]:border-tests-missing',
    missing: 'text-white data-[state=unchecked]:bg-transparent data-[state=unchecked]:border-tests-error',
    status: 'text-white data-[state=unchecked]:bg-transparent data-[state=unchecked]:border-tests-status',
    accepted: 'text-white data-[state=unchecked]:bg-transparent data-[state=unchecked]:border-tests-missing',
}

const thumbVariants = {
    included: 'bg-white',
    excluded: 'bg-tests-excluded',
    fastfill: 'bg-zinc-200 data-[state=unchecked]:bg-tests-missing',
    missing: 'bg-tests-error',
    status: 'bg-tests-status'
}

type ExclusionToggleProps = {
    // onToggle: (excluded: boolean) => void;
}

const ExclusionToggle: React.FC<ExclusionToggleProps> = ({ }) => {
    const switchName = 'include';
    const { item, exclusionReason, exclusionToggleOperable } = useTestTileContext();
    const [toggleSwitch, { isLoading }] = useToggleTestSwitchMutation();
    const { report } = useReportViewContext();
    const [localChecked, setLocalChecked] = useState(item.switches[switchName]);

    useEffect(() => {
        setLocalChecked(exclusionReason === 'included');
    }, [exclusionReason]);

    const handleToggle = useCallback(async () => {
        setLocalChecked(!localChecked);
        try {
            await toggleSwitch({
                reportId: report.id,
                revisionId: item.revision.report_revision_id.toString(),
                testItemId: item.assessmentTestId.toString(),
                switchName: 'include'
            }).unwrap();
        } catch (error) {
            setLocalChecked(localChecked); // Revert on error
            console.error('Failed to toggle switch:', error);
        }
    }, [localChecked, toggleSwitch, report?.id, item?.assessmentTestId, item?.revision?.report_revision_id]);

    const isTestIncluded = exclusionReason === 'included';
    const key = `${item.assessmentTestId}-${exclusionReason}-${localChecked ? 'included' : 'excluded'}`;
    const switchId = `switch-${switchName}-${item.assessmentTestId}`;

    return (
        <React.Fragment key={key}>
            <Switch
                id={switchId}
                disabled={!exclusionToggleOperable || isLoading}
                checked={isTestIncluded}
                onCheckedChange={handleToggle}
                className={cn('', toggleVariants[exclusionReason])}
                thumbClassName={cn(thumbVariants[exclusionReason])}
            />
            <Label htmlFor={switchId}
                className="text-sm font-medium">
                {isTestIncluded ? 'Included' : 'Excluded'}
            </Label>
        </React.Fragment>
    )
}

const TestItemToggle: React.FC<{
    label: string;
    offLabel?: string;
    switchType: 'include' | 'fast_fill_accepted';
    variant?: string;
}> = ({ label: onLabel, offLabel, switchType: switchName, variant }) => {

    const [toggleSwitch, { isLoading }] = useToggleTestSwitchMutation();
    const { report } = useReportViewContext();
    const { item } = useTestTileContext();
    const [localChecked, setLocalChecked] = useState(item.switches[switchName]);

    useEffect(() => {
        setLocalChecked(item.switches[switchName]);
    }, [item.switches[switchName]]);

    // item.assessmentTestId === 2322 && console.info('test item toggle', { item, switchName, value: item.switches });

    const handleToggle = async () => {
        setLocalChecked(!localChecked);
        try {
            await toggleSwitch({
                reportId: report.id,
                revisionId: item.revision.report_revision_id.toString(),
                testItemId: item.assessmentTestId.toString(),
                switchName
            }).unwrap();
        } catch (error) {
            setLocalChecked(localChecked); // Revert on error
            console.error('Failed to toggle switch:', error);
        }
    };

    const switchId = `switch-${switchName}-${item.assessmentTestId}`;

    //alternative to force the switch to re-render
    // const key = `${switchName}-${item.assessmentTestId}-${item[switchName]}`;

    return (
        <>
            <Switch
                id={switchId}
                checked={localChecked}
                onCheckedChange={handleToggle}
                disabled={isLoading}
                aria-labelledby={`${switchId}-label`}
                className={cn('', toggleVariants[variant])}
                thumbClassName={cn(thumbVariants[variant])}
            />
            <Label id={`${switchId}-label`} htmlFor={switchId} className="text-sm font-medium">
                {localChecked ? onLabel : (offLabel ?? onLabel)}
            </Label>
        </>
    );
};

const FastFillAcceptedToggle: React.FC<{ visible?: boolean }> = React.memo(({ visible }) => {

    if (visible !== true) {
        return null;
    }

    return (
        <div className='flex flex-row gap-2 items-center bg-zinc-100 rounded-md py-1 px-2 shadow-[inset_0_2px_8px_rgba(0,0,0,0.1)]'>
            <FastFillIcon className='w-4 h-4' />
            <TestItemToggle
                label="Accepted"
                switchType="fast_fill_accepted"
                offLabel='Not Accepted'
                variant="fastfill"
            />
        </div>
    )
});


type ValidationErrors = Record<string, string>;

// Break out the content section into its own component
const TestItemContent: React.FC<{
    item: ReportTestItem;
    testSuite: TestSuite;
    isScrolling: boolean;
    isTileExpanded: boolean;
    handleExpandTile: () => void;
}> = React.memo(({ item, testSuite, isScrolling, isTileExpanded, handleExpandTile }) => (
    <div className="flex-1">
        <div className="flex flex-row gap-4 px-4 mb-4">
            <div className="flex-1 grid grid-cols-2 gap-4">
                <FastFillText field_id='observations' label="Observations" item={item} />
                <FastFillText field_id='recommendations' label="Recommendations" item={item} />
                {!isScrolling && (
                    <Accordion
                        className='flex w-full flex-col divide-y divide-zinc-200 dark:divide-zinc-700 col-span-2'
                        expandedValue={isTileExpanded ? `test-item-${item.assessmentTestId}` : undefined}
                    >
                        <AccordionItem value={`test-item-${item.assessmentTestId}`} className='w-full'>

                            <AccordionContent>

                                {item.testSpecification.type === 'compliance' && (
                                    <div className=' grid grid-cols-2 gap-4 mb-4'>
                                        <TextTile title="Test Specification" newLines>
                                            {item.testSpecification.description}
                                        </TextTile>
                                        <TextTile title="Test Result">
                                            <span className="font-medium"><HighlightText>{getRatingLabel(testSuite, item.result)}</HighlightText></span>
                                            <TextWithBreaks className="font-light">{item.resultDescription}</TextWithBreaks>
                                        </TextTile>
                                    </div>
                                )}
                                <div className='col-span-2'>
                                    <CustomFieldContainer item={item} testSuite={testSuite} />
                                </div>
                            </AccordionContent>
                            <AccordionTrigger className='w-full py-0.5 text-left text-zinc-950 dark:text-zinc-50'>
                                <div className="col-span-2">
                                    <TileExpander handleExpandTile={handleExpandTile} isTileExpanded={isTileExpanded} />
                                </div>
                            </AccordionTrigger>
                        </AccordionItem>
                    </Accordion>
                )}
            </div>
        </div>
        {!isScrolling && (
            <div className="min-h-20 flex mb-2 px-4">
                <ImageRow
                    className="flex-1"
                    media={item?.images ?? []}
                    imageSize="sm"
                    maxPhotos={50}
                />
            </div>
        )}
    </div>
));


type TestItemProps = {
    item: ReportTestItem;
    testSuite: TestSuite;
    assessment: Assessment;
    onEdit?: (id: number) => void;
}

const TestItem: React.FC<TestItemProps> = ({ item, testSuite, assessment, onEdit }) => {

    const { missingData } = useReportViewContext();
    const { isScrolling, showMissingData, showFastFillQA } = useReportViewerContext();
    const { expandedTiles, setExpandedTiles } = useReportGroupingContext();

    //console.log('TestItem missingData', missingData);

    const handleExpandTile = useCallback(() => {
        setExpandedTiles(prev => {
            const next = new Set(prev);
            if (next.has(item.assessmentTestId)) {
                next.delete(item.assessmentTestId);
            } else {
                next.add(item.assessmentTestId);
            }
            return next;
        });
    }, [item?.assessmentTestId, setExpandedTiles]);

    const isTileExpanded = expandedTiles.has(item.assessmentTestId);

    useEffect(() => {
        if (!isTileExpanded) return;

        const element = document.getElementById(`test-item-${item.assessmentTestId}`);
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    }, [isTileExpanded, item?.assessmentTestId]);

    const tileValidationErrors = (missingData.get(item.assessmentTestId) || {});
    const hasMissingData = Object.keys(tileValidationErrors).length > 0;
    const exclusionToggleOperable = item.exclusionReason === 'included' || item.exclusionReason === 'excluded';

    //console.debug('missing data', item.assessmentTestId, showMissingData, tileValidationErrors, hasMissingData, missingData);

    const contextValues = useMemo(() => ({
        item,
        testSuite,
        missingData: tileValidationErrors, //showMissingData,// && tileValidationErrors,
        hasMissingData: hasMissingData,
        showMissingData: showMissingData,
        exclusionToggleOperable,
        hasFastFillErrors: item.hasFastFillQA, // has errors whether they are approved or not.
        showFastFillQA: showFastFillQA,
        hasUnmatchedSegments: item.hasUnmatchedSegments,
        status: item.status,
        exclusionReason: item.exclusionReason
    }), [
        item,
        testSuite,
        showMissingData,
        showFastFillQA,
        tileValidationErrors,
        hasMissingData,
        exclusionToggleOperable
    ]);

    if (!item) {
        return null;
    }

    return (
        <TestTileContext.Provider value={contextValues}>
            <div className={cn(
                "flex-1 flex flex-col border-zinc-200 border-l-2 pl-2",
                {
                    'border-l-compliance-500': item.ratingType.includes('compliance'),
                    'border-l-condition-500': item.ratingType.includes('condition'),
                    'border-l-zinc-500': item.ratingType.includes('generic'),
                }

            )}>
                <TestItemHeader item={item} />

                <TestItemContent
                    item={item}
                    testSuite={testSuite}
                    isScrolling={isScrolling}
                    isTileExpanded={isTileExpanded}
                    handleExpandTile={handleExpandTile}
                />

                <TestTileEditTools onEdit={onEdit} assessment={assessment} />

            </div>
        </TestTileContext.Provider>
    );
}

const DebugInfo: React.FC<{ exclusionReason: string; status: string; id: number }> = React.memo(
    ({ exclusionReason, status, id }) => {
        const { item, hasFastFillErrors, hasUnmatchedSegments, missingData } = useTestTileContext();

        return (
            <Popover>
                <PopoverTrigger>
                    <div className="text-white bg-amber-500 px-2 py-1 text-xs rounded-lg cursor-pointer truncate max-w-28">
                        {id}/{exclusionReason}
                    </div>
                </PopoverTrigger>
                <PopoverContent className="w-80 p-4">
                    <div className="space-y-2">
                        <h4 className="font-medium">Debug Information</h4>
                        <div className="grid grid-cols-2 gap-1 text-sm">
                            <span className="text-zinc-500">ID:</span>
                            <span>{id}</span>
                            <span className="text-zinc-500">Exclusion Reason:</span>
                            <span>{exclusionReason}</span>
                            <span className="text-zinc-500">Status:</span>
                            <span>{status}</span>
                            <span className="text-zinc-500">Fast Fill Errors:</span>
                            <span>{hasFastFillErrors ? 'Yes' : 'No'}</span>
                            <span className="text-zinc-500">Unmatched Segments:</span>
                            <span>{hasUnmatchedSegments ? 'Yes' : 'No'}</span>
                        </div>
                        {missingData && Object.keys(missingData).length > 0 && (
                            <>
                                <h4 className="font-medium pt-2">Missing Data</h4>
                                <div className="grid grid-cols-2 gap-1 text-sm">
                                    {Object.entries(missingData).map(([field, message]) => (
                                        <React.Fragment key={field}>
                                            <span className="text-zinc-500">{field}:</span>
                                            {/* @ts-ignore */}
                                            <span className="text-red-500">{message.message}</span>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </PopoverContent>
            </Popover>
        );
    }
);


const AssessmentBadge: React.FC<{ assessment: Assessment }> = ({ assessment }) => {
    return (
        <Tooltip>
            <TooltipTrigger>
                <div className="text-sm bg-zinc-200 text-zinc-500 px-2 py-1 max-w-48 rounded-sm truncate">
                    {assessment?.title}
                </div>
            </TooltipTrigger>
            <TooltipContent className="bg-black text-white p-2 rounded-md text-sm">
                {assessment?.title}
            </TooltipContent>
        </Tooltip>
    );
};


type EditToolsProps = {
    onEdit?: (id: number) => void;
    assessment: Assessment;
}

const TestTileEditTools: React.FC<EditToolsProps> = ({ onEdit, assessment }) => {

    const { item, hasFastFillErrors, hasUnmatchedSegments } = useTestTileContext();
    const { isEditMode } = useReportViewContext();

    if (!isEditMode) {
        return null;
    }

    return (
        <div className="bg-zinc-50 border-t px-4 py-2">
            <div className="flex flex-row justify-between items-center gap-2">
                <div className="flex items-center space-x-2">
                    <Button
                        variant="outlinelight"
                        size='sm'
                        className="text-zinc-500"
                        aria-label="Edit Test Item"
                        onClick={() => onEdit?.(item.assessmentTestId)}
                    >Edit</Button>
                    <ExclusionToggle />
                    <FastFillAcceptedToggle visible={hasUnmatchedSegments} />
                    {/* {process.env.NODE_ENV === 'development' && ( */}
                        <DebugInfo
                            exclusionReason={item.exclusionReason}
                            status={item.status}
                            id={item.assessmentTestId}
                        />
                    {/* )} */}
                </div>
                <div className="flex flex-row items-center gap-2 justify-end">
                    <AssessmentBadge assessment={assessment} />
                    <AuthorBadge item={item.author} />
                    <StatusBadges status={item.status as TestCountVariant} />
                </div>
            </div>
        </div>
    )
}

export default TestItem;
