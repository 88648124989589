import { Skeleton } from "@/components/ui/skeleton";

export const InspectionFormSkeleton = () => {
  return (
    <div className="w-full max-w-3xl mx-auto p-4 space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <Skeleton className="h-8 w-32" /> {/* Title */}
        <div className="flex gap-2">
          <Skeleton className="h-9 w-24" /> {/* Archive button */}
          <Skeleton className="h-9 w-24" /> {/* Draft button */}
          <Skeleton className="h-9 w-24" /> {/* QA button */}
          <Skeleton className="h-9 w-24" /> {/* Final button */}
        </div>
      </div>

      {/* Created by section */}
      <div className="flex items-center gap-2 mb-6">
        <Skeleton className="h-10 w-10 rounded-full" /> {/* Avatar */}
        <div className="space-y-2">
          <Skeleton className="h-4 w-20" /> {/* Created by text */}
          <Skeleton className="h-4 w-32" /> {/* Date */}
        </div>
      </div>

      {/* Form Fields */}
      <div className="space-y-8">
        {/* Inspection */}
        <div className="space-y-2">
          <Skeleton className="h-5 w-24" /> {/* Label */}
          <Skeleton className="h-10 w-full" /> {/* Input */}
        </div>

        {/* Asset */}
        <div className="space-y-2">
          <Skeleton className="h-5 w-16" /> {/* Label */}
          <Skeleton className="h-10 w-full" /> {/* Input */}
        </div>

        {/* Test */}
        <div className="space-y-2">
          <Skeleton className="h-5 w-14" /> {/* Label */}
          <Skeleton className="h-32 w-full" /> {/* Dropdown area */}
        </div>

        {/* Location */}
        <div className="space-y-2">
          <Skeleton className="h-5 w-20" /> {/* Label */}
          <Skeleton className="h-32 w-full" /> {/* Location fields */}
        </div>

        {/* Element */}
        <div className="space-y-2">
          <Skeleton className="h-5 w-20" /> {/* Label */}
          <Skeleton className="h-10 w-full" /> {/* Input */}
        </div>

        {/* Element Name */}
        <div className="space-y-2">
          <Skeleton className="h-5 w-28" /> {/* Label */}
          <Skeleton className="h-10 w-full" /> {/* Input */}
        </div>

        {/* Element ID */}
        <div className="space-y-2">
          <Skeleton className="h-5 w-24" /> {/* Label */}
          <Skeleton className="h-10 w-full" /> {/* Input */}
        </div>

        {/* Result */}
        <div className="space-y-2">
          <Skeleton className="h-5 w-16" /> {/* Label */}
          <Skeleton className="h-20 w-full rounded-lg" /> {/* Result gauge */}
        </div>
      </div>
    </div>
  );
};

export default InspectionFormSkeleton;