import { usePhotoViewer } from "@/app/contexts/PhotoViewerContext"
import { MediaInfo } from "@/components/image-upload/types"
import { cn } from "@/lib/utils"
import { ImageType } from "@/types/Images"
import * as React from "react"
import { useTestTileContext } from "../components/test-tile/context"
import { ImageWithPlaceholder, ImageWithPlaceholderProps } from "./ImagePlaceholder"
import { NoImages } from "./NoImages"
import useReportViewerContext from "@/app/features/reports/components/report-viewer-context"

interface PhotoGridProps {
  media: Partial<MediaInfo>[]
  imageSize: ImageWithPlaceholderProps["size"]
  columns: number
  maxPhotos: number
  className?: string
}

const MorePhotos: React.FC<{
  count: number; size: ImageWithPlaceholderProps["size"]; onClick?: () => void
}
> = ({ count, size, onClick }) => (
  <div className={cn(
    "relative bg-muted text-zinc-600 flex items-center justify-center text-sm",
    {
      "w-20 h-20": size === "sm",
      "w-48 h-48": size === "md",
      "w-[350px] h-[350px]": size === "lg",
      "cursor-pointer": !!onClick
    }
  )}
    onClick={() => onClick?.()}
  >
    <div className="absolute inset-0 flex items-center justify-center rounded-md">
      <span className="text-center">+{count}</span>
    </div>
  </div>
)

export function PhotoGrid({ media, imageSize, columns, maxPhotos, className }: PhotoGridProps) {
  const displayMedia = media?.slice(0, maxPhotos) || []
  const morePhotosCount = Math.max(0, (media?.length || 0) - maxPhotos)

  if (!media || media.length === 0) {
    return <NoImages size={imageSize} message="No photos" className="rounded-md" />
  }

  return (
    <div
      className={cn(
        "grid gap-4",
        {
          "grid-cols-1": columns === 1,
          "grid-cols-2": columns === 2,
          "grid-cols-3": columns === 3,
          "grid-cols-4": columns === 4,
          "grid-cols-5": columns === 5,
          "grid-cols-6": columns === 6,
        },
        className
      )}
    >
      {displayMedia.map((item, index) => (
        <ImageWithPlaceholder
          key={item?.id || index}
          src={item?.thumbnail_url}
          alt={`Photo ${index + 1}`}
          size={imageSize}
          className="w-full h-full"
        />
      ))}
      {morePhotosCount > 0 && (
        <MorePhotos count={morePhotosCount} size={imageSize} />
      )}
    </div>
  )
}

export const ImageRow: React.FC<Omit<PhotoGridProps, 'columns'>> = React.memo(
  ({
    media = [],
    imageSize,
    maxPhotos,
    className
  }) => {

    const displayMedia = React.useMemo(() =>
      media.slice(0, maxPhotos),
      [media, maxPhotos]
    );

    const morePhotosCount = React.useMemo(() =>
      Math.max(0, (media?.length || 0) - maxPhotos),
      [media, maxPhotos]
    );

    const { openPhotoViewer } = usePhotoViewer();
    const { missingData } = useTestTileContext();
    const { showMissingData } = useReportViewerContext();

    const handleImageClick = React.useCallback((index: number) => {
      if (media) {
        openPhotoViewer(media as unknown as ImageType[], index)
      }
    }, [media, openPhotoViewer]);

    const handleMorePhotosClick = React.useCallback(() => {
      handleImageClick(maxPhotos - 1);
    }, [handleImageClick, maxPhotos]);

    if (media?.length === 0) {
      return (
        <NoImages
          size={imageSize}
          message="No photos"
          className={cn("rounded-md",
            missingData?.['images'] && showMissingData && "bg-red-400 text-white"
          )}
          aria-label="No photos available"
        />
      );
    }

    return (
      <div
        className={cn("flex flex-wrap gap-2", className)}
        role="grid"
        aria-label="Photo gallery"
      >
        {displayMedia.map((item, index) => (
          <div className="aspect-square"
            key={item?.id || index}
            role="gridcell"
            aria-label={`Photo ${index + 1}`}
          >
            <ImageWithPlaceholder
              src={item?.thumbnail_url}
              alt={`Photo ${index + 1}`}
              size={imageSize}
              className="cursor-pointer hover:opacity-90 transition-opacity"
              onClick={() => handleImageClick(index)}
              aria-label={`View Photo ${index + 1}`}
            />
          </div>
        ))}
        {morePhotosCount > 0 && (
          <MorePhotos
            count={morePhotosCount}
            size={imageSize}
            onClick={handleMorePhotosClick}
            aria-label={`View ${morePhotosCount} more photos`}
          />
        )}
      </div>
    )
  })

ImageRow.displayName = 'ImageRow'