import { TestSuites } from '@/app/features/reports/types/test-specification';
import { getDefaultFieldSettings } from '@/utils/getDefaultFieldSettings';
import throttle from 'lodash/throttle';
import { useCallback, useMemo } from 'react';
import {
  CustomField,
  TestSuite,
  ValidationTypes,
} from 'store/src/lib/tests/entity';
import { z } from 'zod';
import { ImageSchema, type TestFormValues } from '../types/test-form';

const createBasicFieldSchema = (
  field: CustomField,
  type: ValidationTypes
): z.ZodTypeAny => {
  field.type === 'radio' && console.debug('createBasicFieldSchema', field, type);


  /**
   *     case 'radio':
      // If the field has options, validate against those specific values
      if (field.options && field.options.length > 0) {
        const validValues = field.options.map(opt => opt.value);
        return field.required
          ? z.enum(validValues as [string, ...string[]], {
              errorMap: () => ({ message: `${field.label} is required` })
            })
          : z.enum(validValues as [string, ...string[]]).nullable().optional();
      }
      // Fall back to string validation if no options are defined
      return field.required
        ? z.string().min(1, `${field.label} is required`)
        : z.string().nullable().optional();
   * 
   */
  switch (type) {
    case 'boolean':
      return field.required ? z.boolean() : z.boolean().nullable().optional();
    case 'numeric':
      return field.required
        ? z.coerce
            .string()
            .transform((val) => {
              const cleaned = val?.replace(/,/g, '');
              const num = Number(cleaned);
              if (isNaN(num)) {
                throw new Error(`${field.label} must be a valid number`);
              }
              return num;
            })
            .pipe(z.number().min(1, `${field.label} is required`))
        : z.coerce
            .string()
            .transform((val) => {
              if (!val) return null;
              const cleaned = val.replace(/,/g, '');
              const num = Number(cleaned);
              return isNaN(num) ? null : num;
            })
            .pipe(z.number().nullable().optional());
    default:
      return field.required
        ? z.coerce.string().min(1, `${field.label} is required`)
        : z.coerce.string().nullable().optional();
  }
};

const createArrayFieldSchema = (field: CustomField) => {
  console.debug('createArrayFieldSchema', field);
  return field.required
    ? z
        .array(z.string())
        .min(1, `${field.label} must have at least one item`)
        .transform((val) => val || []) // Handle null/undefined
    : z
        .array(z.string())
        .nullable()
        .optional()
        .transform((val) => val || []); // Handle null/undefined
};

const createCustomFieldSchema = (field: CustomField): z.ZodTypeAny => {
  if (!field.active) return z.never();

  return field.selection_type === 'multiple'
    ? createArrayFieldSchema(field)
    : createBasicFieldSchema(
        field,
        (field.validations || 'string') as ValidationTypes
      );
};

const buildCustomFieldValidation = (customFields: CustomField[]) => {
  const schemas = customFields.reduce<Record<string, z.ZodTypeAny>>(
    (acc, field) => {
      if (field.active !== false) {
        const baseSchema = createCustomFieldSchema(field);

        // Add metadata to the schema without always triggering an error
        acc[field.field_id] = baseSchema.superRefine((val, ctx) => {
          // Only add issue if the value is invalid according to base validation
          if (!baseSchema.safeParse(val).success) {
            ctx.addIssue({
              code: 'custom',
              message: `Invalid value for ${field.label}`,
              path: ['custom', field.field_id],
              params: {
                fieldId: field.field_id,
                fieldLabel: field.label,
                fieldType: field.selection_type,
                validationType: field.validations,
                required: field.required,
              },
            });
          }
        });
      } else {
        acc[field.field_id] = z.never();
      }

      return acc;
    },
    {}
  );

  return z.object(schemas);
};

const formatCustomFieldErrors = (error: z.ZodError) => {
  return error.issues.reduce((acc, issue) => {
    if (issue.path[0] === 'custom') {
      const fieldId = issue.path[1];
      acc[`custom.${fieldId}`] = {
        message: issue.message,
        path: issue.path,
        code: issue.code,
        //@ts-expect-error
        params: issue.params || {},
        //@ts-expect-error
        unionErrors: issue.unionErrors || [],
      };
    }
    return acc;
  }, {} as Record<string, any>);
};

// Base validation schema (always required fields)
const baseSchema = z.object({
  assessmentId: z.number().min(1, 'Inspection is required'),
  assetId: z.number().min(1, 'Asset is required'),
  test_specification_id: z.number().min(1, 'Test is required'),
  status: z.enum(['archived', 'draft', 'qa', 'published']),
  images: z.array(ImageSchema).optional(),
});

const fields = [
  'element',
  'element_name',
  'element_id',
  'observations',
  'recommendations',
  'photographs',
  'rating',
  'location',
] as const;

const buildFieldSchmea = (currentTestSuite) => {
  const dynamicValidation: Record<string, any> = {};
  fields?.forEach((fieldName) => {
    const defaultSettings = getDefaultFieldSettings(
      fieldName,
      currentTestSuite?.type
    );
    const settingOverride = currentTestSuite?.settings.find(
      (s) => s.field_name === fieldName
    );

    const isActive = settingOverride?.active ?? defaultSettings.active;
    const isRequired = settingOverride?.required ?? defaultSettings.required;

    if (isActive) {
      // Field is active
      let field = fieldName;
      const fieldSchema = (() => {
        switch (fieldName) {
          case 'element':
            return isRequired
              ? z.number().min(1, `${fieldName} is required`)
              : z.number().nullable().optional();
          case 'element_name':
          case 'element_id':
          case 'observations':
          case 'recommendations':
            return isRequired
              ? z.string().min(1, `${fieldName} is required`)
              : z.string().nullable().optional();
          case 'photographs':
            //@ts-expect-error
            fieldName = 'images';
            console.debug('images', { fieldName, isActive, isRequired });
            return isRequired
              ? z.array(ImageSchema).min(1, 'At least one image is required')
              : z.array(ImageSchema).nullable().optional();
          case 'rating':
            //@ts-expect-error
            fieldName = 'result';
            return isRequired
              ? z.enum(['very_poor', 'poor', 'fair', 'good', 'very_good'])
              : z
                  .enum(['very_poor', 'poor', 'fair', 'good', 'very_good'])
                  .nullable()
                  .optional();

          case 'location':
            // Add all location-related fields
            if (isRequired) {
              dynamicValidation['building_id'] = z
                .number()
                .min(1, 'Building is required');
              dynamicValidation['level_id'] = z.number().nullable().optional();
              dynamicValidation['area_id'] = z.number().nullable().optional();
            }

            return; // Skip adding location itself

          // Add more field types as needed
          default:
            return z.string().nullable().optional();
        }
      })();
      if (fieldSchema) {
        dynamicValidation[fieldName] = fieldSchema;
      }
    }
  });

  console.debug('dynamicValidation', dynamicValidation);

  return dynamicValidation;
};

const schemaCache = new Map<number, z.ZodTypeAny>();

const buildValidationSchema = (
  testSuite: TestSuite | undefined
): z.ZodTypeAny => {
  if (!testSuite) {
    return baseSchema;
  }

  const fieldValidation = buildFieldSchmea(testSuite);
  fieldValidation['custom'] = buildCustomFieldValidation(
    testSuite.custom_fields
  );

  return baseSchema.extend(fieldValidation);
};

export const useDynamicResolver = (testSuites: TestSuites[]) => {
  const validateData = useCallback(
    async (data: TestFormValues) => {
      const specId = data.test_specification_id;

      // Try to get cached schema
      let schema = schemaCache.get(specId);

      // If no cached schema, build and cache it
      if (!schema) {
        const testSuite = testSuites.find((suite) =>
          suite.specifications.some((spec) => spec.id === specId)
        );

        schema = buildValidationSchema(testSuite as unknown as TestSuite);
        schemaCache.set(specId, schema);
      }

      try {
        const validatedData = await schema.parseAsync(data);
        return {
          values: validatedData,
          errors: {},
        };
      } catch (error) {
        if (error instanceof z.ZodError) {
          return {
            values: {},
            errors: error.formErrors.fieldErrors,
          };
        }
        return {
          values: {},
          errors: { _form: ['Validation failed'] },
        };
      }
    },
    [testSuites]
  ); // Empty dependency array since we use closure to access testSuites

  // Return memoized validator
  return useMemo(() => validateData, [validateData]);
};

export const useEnchancedDynamicResolver = (testSuites: TestSuites[]) => {
  const validateData = useCallback(
    async (data: TestFormValues) => {
      const specId = data.test_specification_id;
      let schema = schemaCache.get(specId);

      if (!schema) {
        const testSuite = testSuites.find((suite) =>
          suite.specifications.some((spec) => spec.id === specId)
        );
        schema = buildValidationSchema(testSuite as unknown as TestSuite);
        schemaCache.set(specId, schema);
      }

      try {
        const validatedData = await schema.parseAsync(data);
        return {
          values: validatedData,
          errors: {},
        };
      } catch (error) {
        if (error instanceof z.ZodError) {
          const customErrors = formatCustomFieldErrors(error);
          const formattedErrors = error.format();

          const detailedErrors = {
            ...Object.entries(formattedErrors).reduce(
              (acc, [key, value]) => {
                if (key === '_errors' || key === 'custom') return acc;

                acc[key] = {
                  // @ts-expect-error
                  message: value._errors[0],
                  path:
                    error.issues.find((issue) => issue.path.join('.') === key)
                      ?.path || [],
                  code:
                    error.issues.find((issue) => issue.path.join('.') === key)
                      ?.code || 'custom',
                };
                return acc;
              },
              {} as Record<
                string,
                {
                  message: string;
                  path: (string | number)[];
                  code: string;
                }
              >
            ),
            ...customErrors,
          };

          return {
            values: {},
            errors: detailedErrors,
          };
        }

        // Handle non-Zod errors with more context
        return {
          values: {},
          errors: {
            _form: [
              {
                message: 'Validation failed',
                code: 'custom',
                path: [],
                details:
                  error instanceof Error ? error.message : 'Unknown error',
              },
            ],
          },
        };
      }
    },
    [testSuites]
  );

  return useMemo(() => validateData, [validateData]);
};
