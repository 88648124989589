import { Badge } from "@/components/ui/badge"
import { X } from 'lucide-react'
import React from 'react'
import { FilterCounts, filterNames, Filters, FilterTypes, SelectedFilters } from '../types/filters'
import { getSelectedSpaces, groupSelectedSpaces } from './filter-drawer'
import extractFilterCount from '../utils/extractFilterCount';
import { useReportViewContext } from "@/app/features/reports/components/report-viewer-context"

type FilterFacetsProps = {
}

export const FilterFacets: React.FC<FilterFacetsProps> = () => {

    const { filters: { filteredCounts, removeFilter, selectedFilters, facets: filters } } = useReportViewContext();

    return (
        <div className="flex flex-wrap gap-2">

            {Object.entries(selectedFilters).map(([category, categoryFilters]) =>
                categoryFilters.map((filter) => {
                    if (filterNames.includes(category as FilterTypes)) {

                        return (
                            <SpacesFilterPills
                                key={category}
                                category={category}
                                selected={filter}
                                filters={filters}
                                onRemove={removeFilter}
                                filterCounts={filteredCounts}

                            />
                        )

                    }

                    let displayText = `${category}: ${filter}`;

                    return (
                        <FilterPill
                            key={`${category}:${filter}`}
                            text={displayText}
                            filter={filter}
                            count={filteredCounts[category][filter]}
                            onClick={() => removeFilter(category as FilterTypes, filter)}
                        />
                    );
                })
            )}
        </div>

    )
}

export default FilterFacets;

type SpaceFilterPillProps = {
    category: string;
    selected: string;
    filters: Filters
    // onClick?: (id: string) => void
    onRemove: (category: FilterTypes, filter: string) => void
    filterCounts: FilterCounts;
}

const SpacesFilterPills: React.FC<SpaceFilterPillProps> = ({ selected, category, filters, onRemove, filterCounts }) => {

    const matchedTests = getSelectedSpaces(filters[category], selected.split(','));
    const grouped = groupSelectedSpaces(matchedTests);
    console.log('SpacesFilterPills', { selected, filters, filterCounts, matchedTests, grouped });

    return (
        (<div className="flex space-x-2">
            {grouped.length > 0 ? (
                grouped.map((group, groupIndex) => {
                    if (group.children.length === 1) {
                        const item = group.children[0];
                        // const key = item.key.replace(/Id$/, '');
                        // const id = item.id.split('.').splice(-1)[0];
                        const count = extractFilterCount(filterCounts, item, 'filtered');

                        return (
                            <FilterPill
                                key={groupIndex}
                                text={`${group.parent} > ${item.name}`}
                                filter={group.children[0].id}
                                count={count}
                                onClick={() => onRemove(category as FilterTypes, group.children[0].id)}
                            //onClick={() => onClick(group.children[0].id)}
                            />
                        )
                    }

                    return (
                        (<div key={groupIndex} className="flex flex-wrap items-center gap-2 p-1 border border-zinc-200 rounded-lg">
                            <span className="text-sm font-medium">{group.parent ?? 'Asset'}</span>
                            {group.children.map((child, childIndex) => {
                                const count = extractFilterCount(filterCounts, child, 'filtered');

                                return (
                                    (<FilterPill
                                        key={childIndex}
                                        text={child.name}
                                        filter={`${group.parent} > ${child}`}
                                        onClick={() => onRemove(category as FilterTypes, child.id)}
                                        //onClick={() => onClick(child.id)}
                                        count={count}
                                    />
                                    )
                                );
                            })}
                        </div>)
                    );
                })
            ) : (
                <p className="text-sm text-muted-foreground">No items selected</p>
            )}
        </div>)
    );
}

const FilterPill = ({ text, filter, count, onClick }) => {
    return (
        <Badge
            variant="secondary"
            className="flex items-center gap-1 text-sm px-3 py-1"
        >
            <span>{text}</span>
            <span className="text-muted-foreground ml-1">({count || 0})</span>
            <X
                className="h-3 w-3 cursor-pointer ml-1"
                onClick={() => onClick(filter)}
            />
        </Badge>
    )
}