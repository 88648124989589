import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"

const pillVariants = cva(
  "inline-flex items-center rounded-md px-4 py-1 text-sm transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        enabled: "bg-zinc-100 text-zinc-900 hover:bg-zinc-200",
        disabled: "bg-white border-zinc-300 border text-zinc-600 cursor-not-allowed",
        inverted: "bg-zinc-900 text-zinc-100 hover:bg-zinc-700",
        highlighted: "bg-zinc-600 text-zinc-100 hover:bg-zinc-900",
        outline: "bg-transparent border-zinc-300 border text-zinc-900 hover:bg-zinc-200",
      },
      bold: {
        true: "font-semibold",
        false: "font-normal",
      },
      size: {
        sm: "text-xs px-2 py-0.5",
        md: "text-sm px-2 py-1",
        lg: "text-base px-4 py-2",
      }
    },
    defaultVariants: {
      variant: "enabled",
      bold: false,
      size: "md",
    }

  }
)

export interface PillProps
  extends React.HTMLAttributes<HTMLSpanElement>,
  VariantProps<typeof pillVariants> {
  onClick?: () => void;
  showSelector?: boolean;
}

const Pill = React.forwardRef<HTMLSpanElement, PillProps>(
  ({ className, variant, bold, size, children, ...props }, ref) => {

    if (!children) {
      return null
    }

    return (
      <span
        className={cn(pillVariants({ variant, bold, size }), className)}
        ref={ref}
        onClick={props.onClick?.bind(null)}
        {...props}
      >{children}
      </span>
    )
  }
)
Pill.displayName = "Pill"

export { Pill, pillVariants }
