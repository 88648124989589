import React from 'react';
import { CircleUser } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Link } from 'react-router-dom';
import { selectLoggedInUser, selectUserIsLoading, useAppSelector } from '@app.raytd.com/store';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';

const UserMenu: React.FC = () => {

  const loggedInUser = useAppSelector(selectLoggedInUser);
  const userIsLoading = useAppSelector(selectUserIsLoading);

  console.debug('UserMenu', loggedInUser);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex items-center gap-2 px-0 cursor-pointer">
          {userIsLoading ? <CircleUser className="w-6 h-6 animate-spin" /> :
          (
          <Avatar>
            <AvatarImage
              src={loggedInUser?.avatar}
              alt={loggedInUser?.name}
              className='object-cover'
            />
            <AvatarFallback>{loggedInUser?.name[0]}</AvatarFallback>
          </Avatar>
          )}
          <span className="sr-only">Open user profile menu</span>
          <span className="text-sm font-semibold text-zinc-600">{loggedInUser?.name}</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem><Link to="/profile">Profile</Link></DropdownMenuItem>
        <DropdownMenuItem><Link to="/organisation">Organisation</Link></DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem><Link to="/logout">Logout</Link></DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserMenu;
