import { FormControl, FormDescription, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { Control } from "react-hook-form"
import { FormLabelWithIndicator } from "../form-label-with-indicator"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Checkbox } from "@/components/ui/checkbox"
import { MultiSelect } from "@/components/ui/multi-select"
import { SelectField } from "@/app/features/reports/components/select-field"
import { useMemo } from "react"
import { CustomFieldProps } from '../custom-fields-container';

type CustomSelectFieldProps = CustomFieldProps & {
  options: { id: string; displayName: string }[]
  selectionType: 'single' | 'multiple';
  allowAdd?: boolean;
}

export function CustomSelectField({
  control,
  name,
  label,
  description,
  showDescription,
  info,
  required,
  placeholder,
  options,
  selectionType,
  isValid,
  allowAdd = false,
  showFormMessage = false,
}: CustomSelectFieldProps) {

  const customFieldOptions = useMemo(() => {
    return options.map((option) => ({ value: option.id, label: option.displayName }))
  }, [options])

  if (selectionType === 'multiple') {
    return (
      <SelectField
        control={control}
        //@ts-expect-error
        name={name}
        label={label}
        info={info}
        isValid={isValid}
        options={customFieldOptions}
        required={required}
        placeholder={placeholder}
        disabled={false}
        description={description}
        className="w-full"
        multiple={true}
        allowAdd={allowAdd}
      />
    )
  }

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabelWithIndicator label={label} isValid={isValid} required={required} info={info} />
          <Select
            onValueChange={field.onChange}
            value={field.value}
            disabled={field.disabled}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {customFieldOptions.length === 0 ? (
                <SelectItem value="empty" disabled>
                  No options available
                </SelectItem>
              ) : (
                customFieldOptions.map((option) => (
                  <SelectItem
                    key={option.value}
                    value={option.value}
                    className="cursor-pointer"
                  >
                    {option.label}
                  </SelectItem>
                ))
              )}
            </SelectContent>
          </Select>
          {showDescription && (<FormDescription>{description}</FormDescription>)}
          {showFormMessage && <FormMessage />}
        </FormItem>
      )}
    />
  )
}

