import React from 'react';
import UserMenu from './UserMenu';
import SidebarNavigation from './SidebarNavigation';
import UpgradeCard from './UpgradeCard';


const Sidebar: React.FC = () => {
    return (
        <div className="hidden border-none md:block">
            <div className="flex h-full max-h-screen w-[220px] lg:w-[220px] flex-col gap-2 fixed">
                <div className="flex h-14 items-center border-none px-4 lg:h-[60px] lg:px-6">
                    <UserMenu />
                </div>
                <div className="flex-1">
                    <SidebarNavigation />
                </div>
                <div className="mt-auto p-4">
                    <UpgradeCard />
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
