import { compare } from 'natural-orderby';

export function customCompare(a: string, b: string): number {
    const noPattern = /\(No .*\)/;
    
    // If a or b is a "No" item, move it to the bottom
    const isANoItem = noPattern.test(a);
    const isBNoItem = noPattern.test(b);
  
    if (isANoItem && !isBNoItem) return 1;  // a goes after b
    if (!isANoItem && isBNoItem) return -1; // a goes before b
  
    // Otherwise, apply natural sort
    return compare()(a, b);
  }