import { useAlert } from "@/app/contexts/AlertContext"
import { TestSuites } from "@/app/features/reports/types/test-specification"
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "@/components/ui/dialog"
import { Assessment, AssetEntity } from "@app.raytd.com/store"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { TestItem } from 'store/src/lib/tests/entity'
import { TestEditForm } from "./test-edit-form"
import { Loader2 } from "lucide-react"
import { VisuallyHidden } from "@ariakit/react"

type TestEditDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    testItem: TestItem | null;
    isLoading: boolean;
    onSubmit?: (data: any) => void;
    inspections: Assessment[];
    assets: AssetEntity[];
    testSuites: TestSuites;
}

const TestEditDialog = forwardRef(({
    isOpen,
    onClose,
    onSubmit,
    testItem,
    isLoading,
    inspections,
    assets,
    testSuites
}: TestEditDialogProps, ref) => {

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const { showAlertDialog } = useAlert();
    const formRef = useRef<any>(null);

    const handleDirtyChange = useCallback((isDirty: boolean) => {
        setHasUnsavedChanges(isDirty);
    }, []);

    const handleOpenChange = useCallback((newOpen: boolean) => {
        if (!newOpen) {
            if (hasUnsavedChanges) {
                showAlertDialog({
                    title: 'Unsaved Changes',
                    description: 'You have unsaved changes. Are you sure you want to leave?',
                    confirmText: 'Leave',
                    cancelText: 'Stay',
                }).then((confirmed) => {
                    if (confirmed) {
                        setHasUnsavedChanges(false);
                        onClose();
                    }
                });
            } else {
                onClose();
            }
        }
    }, [onClose, showAlertDialog, hasUnsavedChanges]);

    const handleSubmit = useCallback((data: any) => {
        onSubmit?.(data);
        onClose();
    }, [onSubmit, onClose]);

    return (
        <Dialog open={isOpen} onOpenChange={handleOpenChange}>
            <VisuallyHidden>
                <DialogTitle>Test Edit</DialogTitle>
                <DialogDescription>Edit the test for the asset.</DialogDescription>
            </VisuallyHidden>
            <DialogContent
                className="max-w-2xl z-50 h-[90vh] flex flex-col py-6 px-2 bg-white"
                onOpenAutoFocus={(event) => event.preventDefault()}
                onEscapeKeyDown={(event) => event.preventDefault()}
                onPointerDownOutside={(event) => event.preventDefault()}
            >           {isLoading ? (
                <div className="flex items-center justify-center h-full">
                    <Loader2 className="w-4 h-4 animate-spin" />
                </div>
            ) : (
                <TestEditForm
                    ref={formRef}
                    key={`test-form-${testItem?.assessmentTestId}`} // Add key to force re-render
                    testItem={testItem}
                    inspections={inspections}
                    assets={assets}
                    testSuites={testSuites as any}
                    onDirtyChange={handleDirtyChange}
                    onSubmit={handleSubmit}
                />
            )}
            </DialogContent>
        </Dialog>
    )
})

export default TestEditDialog;