import { XCircle } from "lucide-react"

type ErrorStateProps = {
    title?: string;
    message?: string;
    onRetry?: () => void;
}

const ErrorState: React.FC<ErrorStateProps> = ({ 
    title = "Something went wrong",
    message = "An error occurred while processing your request",
    onRetry 
}) => {
    return (
        <div className="w-full min-h-[500px] flex flex-col items-center justify-center p-6">
            <div className="max-w-md text-center space-y-4">
                <XCircle className="h-16 w-16 text-red-500 mx-auto" />
                <h3 className="text-lg font-semibold text-gray-900">
                    {title}
                </h3>
                <p className="text-sm text-gray-500">
                    {message}
                </p>
                {onRetry && (
                    <button 
                        onClick={onRetry}
                        className="mt-4 px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        Try Again
                    </button>
                )}
            </div>
        </div>
    )
}

export default ErrorState;