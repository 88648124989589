import { ReportItemContent } from './entities';

const compareStrings = (a: string | undefined, b: string | undefined): number => {
    return (a || '').localeCompare(b || '');
};

const compareElementPaths = (a: ReportItemContent, b: ReportItemContent): number => {
    const pathA = a.testCase?.element?.path || [];
    const pathB = b.testCase?.element?.path || [];
    
    // Put empty paths last
    if (pathA.length === 0 && pathB.length === 0) return 0;
    if (pathA.length === 0) return 1;
    if (pathB.length === 0) return -1;
    
    const minLength = Math.min(pathA.length, pathB.length);
  
    // Compare common segments
    for (let i = 0; i < minLength; i++) {
      const comparison = pathA[i].name.localeCompare(pathB[i].name);
      if (comparison !== 0) {
        return comparison;
      }
    }
  
    return pathA.length - pathB.length;
  };

const compareLocations = (a: ReportItemContent, b: ReportItemContent): number => {
    // Compare asset_name
    const assetComparison = compareStrings(
        a.testCase?.asset_name,
        b.testCase?.asset_name
    );
    if (assetComparison !== 0) return assetComparison;

    // Compare build_description
    const buildComparison = compareStrings(
        a.testCase?.building_description,
        b.testCase?.building_description
    );
    if (buildComparison !== 0) return buildComparison;

    // Compare level_description
    const levelComparison = compareStrings(
        a.testCase?.level_description,
        b.testCase?.level_description
    );
    if (levelComparison !== 0) return levelComparison;

    // Compare area_description
    return compareStrings(
        a.testCase?.area_description,
        b.testCase?.area_description
    );
};

const compareTests = (a: ReportItemContent, b: ReportItemContent): number => {
    // Compare test_suite_title
    const titleComparison = compareStrings(
        a.testCase?.testSpecification?.test_suite_title,
        b.testCase?.testSpecification?.test_suite_title
    );
    if (titleComparison !== 0) return titleComparison;

    // Compare category
    const categoryComparison = compareStrings(
        a.testCase?.testSpecification?.category,
        b.testCase?.testSpecification?.category
    );
    if (categoryComparison !== 0) return categoryComparison;

    // Compare subcategory
    const subcategoryComparison = compareStrings(
        a.testCase?.testSpecification?.subcategory,
        b.testCase?.testSpecification?.subcategory
    );
    if (subcategoryComparison !== 0) return subcategoryComparison;

    // Compare label
    return compareStrings(
        a.testCase?.testSpecification?.label,
        b.testCase?.testSpecification?.label
    );
};

export const sortContentBy = (content: ReportItemContent[], sortOrder: string): ReportItemContent[] => {
    if (!content || !sortOrder) {
        return content;
    }

    console.debug('sorting content', sortOrder);

    return [...content].sort((a, b) => {
        switch (sortOrder) {
            case 'element':
                return compareElementPaths(a, b);
            case 'location':
                return compareLocations(a, b);
            case 'test':
                return compareTests(a, b);
            default:
                return 0;
        }
    });
}

export const determineSortOrder = (groupMode: string, headerMode: string): string => {
    const sortOrder1 = groupMode === 'location' ? 'element' : 'location';
    const sortOrder2 = (groupMode === 'location' || groupMode === 'element') ? 'test' : 'element';
    return headerMode === 'switch' ? sortOrder2 : sortOrder1;
};

export default sortContentBy;   