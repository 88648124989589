import { FilterItem } from "@/app/features/reports/utils/entities";
import { FilterCounts } from "../types/filters";
import { Count, defaultCounts } from './calculateFilterCounts';

type CountTypes = keyof Count;

const extractFilterCount = (
    filterCounts: FilterCounts,
    item: { key: string; id: string },
    type: CountTypes = 'total'
  ): number => {
    // Strip 'Id' suffix from key if present
  //  const baseKey = item.key.replace(/Id$/, '')
  const baseKey = item.key;
    
    // Get counts for this filter type
    const counts = filterCounts[baseKey]

    if (!counts) {
      // if (process.env.NODE_ENV === 'development') {
      //   console.debug('No counts found for key:', baseKey, filterCounts)
      // }
      return 0
    }
  
    // For hierarchical data, use the full ID
    const count = counts[item.id]
    if (count !== undefined) {
      return count[type] || 0;
    }
  
    // if (process.env.NODE_ENV === 'development') {
    //   console.debug('No count found for id:', item.id, 'in', baseKey, counts)
    // }
    return 0
  }

export default extractFilterCount;

export const extractCountForKey = (filterCounts: FilterCounts, item: FilterItem) => {

    const counts = filterCounts?.[item.key]?.[item.id];

    if (!counts) {
        if (process.env.NODE_ENV === 'development') {
            console.debug('No counts found for key:', item.key, filterCounts)
        }
        return undefined;
    }

    return counts || defaultCounts;

}