import React, { createContext, useContext } from 'react';
import Spinner from './spinner';
import { Loader2Icon } from 'lucide-react';

interface SubPageHeaderProps {
    children?: React.ReactNode;
}
interface SubPageContextType {
    title: string;
    subtitle?: string;
    badge?: React.ReactNode;
    showSpinner?: boolean;
    showLoading?: boolean;
}

interface SubPageLayoutProps {
    title: string;
    subtitle?: string;
    badge?: React.ReactNode;
    showSpinner?: boolean;
    showLoading?: boolean;
    children: React.ReactNode;
}


const SubPageContext = createContext<SubPageContextType | undefined>(undefined);

export const useSubPage = () => {
    const context = useContext(SubPageContext);
    if (context === undefined) {
        throw new Error('useSubPage must be used within a SubPageProvider');
    }
    return context;
};

export const SubPageProvider: React.FC<SubPageContextType & { children: React.ReactNode }> = ({
    children,
    ...value
}) => {
    return (
        <SubPageContext.Provider value={value}>
            {children}
        </SubPageContext.Provider>
    );
};

export const SubPageHeader: React.FC<SubPageHeaderProps> = ({ children }) => {
    const { title, subtitle, badge, showSpinner } = useSubPage();

    return (
        <div className='mb-6'>
            <div className="flex flex-row justify-between space-x-2">
                <div className="flex flex-row items-center space-x-2">
                    <h1 className="text-2xl font-semibold">{title}</h1>
                    {badge}
                    {showSpinner && <Spinner />}
                </div>
                <div className="flex items-center space-x-4 mt-2">
                    {children}
                </div>
            </div>
            {subtitle && <p className="text-sm text-muted-foreground">{subtitle}</p>}

        </div>
    );
};


const Loading = () => {
    return (
        <div className="flex items-center justify-center h-48">
            <Loader2Icon className="h-8 w-8 animate-spin" />
        </div>
    );
}

export const SubPageLayout: React.FC<SubPageLayoutProps> & {
    Header: typeof SubPageHeader;
} = ({ title, subtitle, badge, showSpinner = false, showLoading = false, children }) => {
    return (
        <SubPageProvider title={title} subtitle={subtitle} badge={badge} showSpinner={showSpinner} showLoading={showLoading}>
            {showLoading ? (
                <div className="">
                    <SubPageHeader />
                    <Loading />
                </div>
            ) : (
                <div>{children}</div>
            )}
        </SubPageProvider>
    );
};

SubPageLayout.Header = SubPageHeader;

export default SubPageLayout;