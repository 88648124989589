const formatDateAndTime = (date: string | Date, locale = 'en-AU') => {

  if (!date) return null;

  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }).format(new Date(date));
};


export default formatDateAndTime;