'use client'

import { useState, useMemo, useEffect, useCallback } from 'react'
import { useForm, Controller, useFormContext } from 'react-hook-form'
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { ChevronDown, ChevronRight, X } from 'lucide-react'
import { ClassificationTree } from '@app.raytd.com/store'

interface ClassificationItem {
  id: number;
  name: string;
  parent_id: number | null;
  level: number;
  children: ClassificationItem[];
}

interface TreeNode {
  id: number;
  parent_id: number;
  original: ClassificationItem;
  name: string;
  isOpen: boolean;
  key: 'category' | 'subcategory' | 'type' | 'subtype';
  childCount: number;
  children?: TreeNode[];
}

const LEVEL_KEYS = {
  0: 'category',
  1: 'subcategory',
  2: 'type',
  3: 'subtype'
} as const;

const MAX_DEPTH = 4;

interface ElementSelectorProps {
  elements: ClassificationTree[];
  onSelect?: (id: number | null) => void;
  name: string;
  isLoading?: boolean;
}

export function ElementSelector({ elements: elementGroups, onSelect, name, isLoading = false }: ElementSelectorProps) {
  const formContext = useFormContext();
  const { control, setValue, watch } = formContext || useForm();
  const [selectedTree, setSelectedTree] = useState<ClassificationTree | null>(null);
  const [path, setPath] = useState<TreeNode[]>([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const elements = useMemo(() => {
    if (!selectedTree) return [];
    console.debug('elements', { selectedTree });
    return selectedTree.elements;
  }, [selectedTree]);


  const filteredElements = useMemo(() => {
    if (!search) return [];
    const searchLower = search.toLowerCase();

    const searchNodes = (nodes: ClassificationItem[]): ClassificationItem[] => {
      return nodes.flatMap(node => {
        const matchesSearch = node.name.toLowerCase().includes(searchLower);
        const childMatches = node.children ? searchNodes(node.children) : [];

        if (matchesSearch || childMatches.length > 0) {
          return [{
            ...node,
            children: childMatches
          }];
        }
        return [];
      });
    };

    return searchNodes(elements);
  }, [elements, search]);

  const selectedValue = watch(name);

  useEffect(() => {
    if (selectedValue) {
      console.debug('selectedValue element', selectedValue);
      const findNodePath = (groups: ClassificationTree[], targetId: number): TreeNode[] => {
        for (const group of groups) {
          if (group.id === targetId) {
            return [{ id: group.id, name: group.name } as TreeNode];
          }
          
          const findInElements = (elements: ClassificationItem[]): TreeNode[] | null => {
            for (const element of elements) {
              if (element.id === targetId) {
                return [{ id: element.id, name: element.name } as TreeNode];
              }
              
              if (element.children) {
                const childPath = findInElements(element.children);
                if (childPath) {
                  return [{ 
                    id: element.id, 
                    name: element.name 
                  } as TreeNode, ...childPath];
                }
              }
            }
            return null;
          };

          const elementPath = findInElements(group.elements);
          console.debug('elementPath', {group, elementPath});
          if (elementPath) {
            setSelectedTree(group);
            setPath(elementPath);
            console.debug('elementPath', {group, elementPath});
            return elementPath;
          }
        }
        return [];
      };

      findNodePath(elementGroups, Number(selectedValue));
    }
  }, [selectedValue, elementGroups]);

  const resetSelection = () => {
    setSelectedTree(null);
    setPath([]);
    setValue(name, null);
    onSelect && onSelect(null);
    setOpen(false);
  };

  const renderTreeSelection = () => (
    <CommandGroup heading="Select Classification Group">
      {elementGroups?.map((tree) => (
        <CommandItem
          key={tree.id}
          onSelect={() => {
            setSelectedTree(tree);
            setPath([]);
          }}
        >
          {tree.name}
          {tree.status === 'archived' && (
            <span className="ml-2 text-xs text-muted-foreground">(Archived)</span>
          )}
        </CommandItem>
      ))}
    </CommandGroup>
  );


  const goToLevel = (index: number) => {
    setPath(prev => prev.slice(0, index + 1));
  };

  const renderSearchResults = () => (
    <CommandGroup heading="Search Results">
      {filteredElements.map((element) => (
        <CommandItem
          key={element.id}
          onSelect={() => {
            setValue(name, element.id);
            setOpen(false);
            onSelect && onSelect(element.id);
          }}
        >
          {element.name}
        </CommandItem>
      ))}
    </CommandGroup>
  );

  const renderContent = () => {
    if (search) return renderSearchResults();
    if (!selectedTree) return renderTreeSelection();

    const currentLevel = path.length > 0 ? path[path.length - 1] : { children: elements } as unknown as TreeNode;
    const nextLevelKey = LEVEL_KEYS[path.length as keyof typeof LEVEL_KEYS] || 'category';

    return (
      <>
        {path.length > 0 && (
          <CommandItem
            onSelect={() => {
              setValue(name, currentLevel.id);
              setOpen(false);
              onSelect && onSelect(currentLevel.id);
            }}
            className="font-medium border-b"
          >
            {currentLevel.name}
          </CommandItem>
        )}
        <CommandGroup heading={`Select ${nextLevelKey}`}>
          {(currentLevel.children || []).map((node) => (
            <CommandItem
              key={node.id}
              onSelect={() => {
                console.debug('selected element node', node);
                if (node.children && node.children.length > 0 && path.length < MAX_DEPTH) {
                  setPath(prev => [...prev, node]);
                } else {
                  setPath(prev => [...prev, node]);
                  setValue(name, node.id);
                  setOpen(false);
                  onSelect && onSelect(node.id);
                }
              }}
            >
              {node.name}
              {node.childCount > 0 && path.length < MAX_DEPTH && (
                <span className="ml-auto text-xs text-muted-foreground">
                  {node.childCount} item{node.childCount !== 1 ? 's' : ''}
                </span>
              )}
            </CommandItem>
          ))}
        </CommandGroup>
      </>
    );
  };

  // const getElementById = useCallback((id: number): ClassificationItem | null => {
  //   for (const node of elementGroups) {
  //     if (node.id === id) return node;
  //     if (node.children) {
  //       const childNode = getElementById(id, node.children);
  //       if (childNode) return childNode;
  //     }
  //   }
  //   return null;
  // }, [elementGroups]);

  const renderDisplayValue = useCallback((nodeId) => {

    console.debug('element renderDisplayValue', { nodeId, path, search });

    if (search) return `Search results for "${search}"`;
    if (path.length === 0) return 'Select Classification';
    return (
      <div className="flex flex-col items-start gap-1 font-normal">
        <span className="font-medium">{selectedTree?.name}</span>
        <div className="flex flex-row">
        {path.slice(0,-1).map(node => (
          <span key={node.id} className="flex items-center">
            <span>{node.name}</span>
            <ChevronRight className="h-4 w-4 mx-1" />
          </span>
        ))}
        </div>
        <span className="font-medium">{path[path.length - 1].name}</span>
      </div>)
  }, [path, search]);

  const renderFixedHeader = () => {
    if (search) return null;
    if (path.length === 0) return null;

    return (
      <div className="sticky top-0 bg-white dark:bg-gray-800 p-2 border-b flex items-center justify-between text-sm text-muted-foreground">
        <div className="flex items-center space-x-1">
          {path.map((node, index) => (
            <span key={node.id} className="flex items-center">
              {index > 0 && <ChevronRight className="h-4 w-4 mx-1" />}
              <button
                onClick={() => goToLevel(index)}
                className="hover:underline focus:outline-none"
              >
                {node.name}
              </button>
            </span>
          ))}
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={resetSelection}
          className="h-6 w-6"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        // const displayValue = selectedTree 
        //   ? `${selectedTree.name} > ${path.map(node => <div>{node.name}</div>)}`
        //   : "Select Classification";

        return (
          <Popover open={open} onOpenChange={setOpen} modal={true}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="w-full h-full justify-between"
              >
                <span className="truncate">{renderDisplayValue(field.value)}</span>
                <ChevronDown className="h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-min-[400px] w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                {selectedTree && (
                  <CommandInput
                    placeholder="Search elements..."
                    value={search}
                    onValueChange={setSearch}
                  />
                )}
                {renderFixedHeader()}
                <CommandList>
                  <CommandEmpty>No results found.</CommandEmpty>
                  {renderContent()}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        );
      }}
    />
  );
}

