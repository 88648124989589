import { memo, useCallback, useEffect, useState, RefObject } from 'react';
import { UseFormReturn, Path, useFieldArray, ArrayPath } from 'react-hook-form';

interface UseFormErrorNavigationProps<T> {
    methods: UseFormReturn<T>;
    enabled?: boolean;
    enableKeyboardShortcuts?: boolean;
    formRef: RefObject<HTMLFormElement>;
    isFormReady?: boolean;
}

interface UseFormErrorNavigationReturn<T> {
    currentErrorIndex: number;
    errorCount: number;
    handleNextError: () => void;
    handlePrevError: () => void;
    handleFieldClick: (fieldName: Path<T>) => void;
    ErrorNavigation: React.MemoExoticComponent<() => JSX.Element | null>;
}

const fieldsToRemove = ['assessmentTestId', 'level_id', 'area_id', 'formId', 'status', 'custom'];

export function useFormErrorNavigation<T>({
    methods,
    enabled = true,
    enableKeyboardShortcuts = true,
    formRef,
    isFormReady = false,
}: UseFormErrorNavigationProps<T>): UseFormErrorNavigationReturn<T> {
    const [currentErrorIndex, setCurrentErrorIndex] = useState<number>(0);
    const [errorFields, setErrorFields] = useState<string[]>([]);
    const [allFields, setAllFields] = useState<string[]>([]);  // New

    const { fields } = useFieldArray({ control: methods.control, name: "custom" as ArrayPath<T> });

    useEffect(() => {
        // console.debug('useFormErrorNavigation useEffect', { enabled, formRef, isFormReady });
        if (!isFormReady && enabled) {
            setAllFields([]);
        }
    }, [enabled, formRef, isFormReady]);

    useEffect(() => {
        if (!enabled || !formRef.current || !isFormReady) return;

        // Wait for the next frame to ensure DOM is updated
        const timeoutId = setTimeout(() => {
            const registerFields = () => {
                if (!formRef.current) return;

                const errors = methods.formState.errors;
                const errorFieldNames = Object.keys(errors);
                const formValues = methods.watch();
                let registeredFields: string[] = [];

                registeredFields.push(...Object.keys(formValues));

                if (formValues['custom']) {
                    const customFields = Object.keys(formValues['custom'])
                        .map(key => `custom.${key}`);
                    registeredFields.push(...customFields);
                }

                registeredFields = registeredFields
                    .filter(field => !fieldsToRemove.includes(field));

                if (registeredFields.length > 0) {
                    registeredFields.sort((a, b) => {
                        const elementA = formRef.current?.querySelector(`[name="${a}"]`);
                        const elementB = formRef.current?.querySelector(`[name="${b}"]`);
                        if (!elementA || !elementB) return 0;
                        return elementA.compareDocumentPosition(elementB) &
                            Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1;
                    });
                }

                setAllFields(registeredFields);
                setErrorFields(errorFieldNames);
                setCurrentErrorIndex(errorFieldNames.length > 0 ? 0 : -1);
            };

            registerFields();
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [methods.formState.errors, enabled, methods, formRef, fields, isFormReady]);

    const scrollToError = useCallback((fieldName: Path<T>) => {
        // First set focus using react-hook-form
        try {
            methods.setFocus(fieldName, { shouldSelect: true });
        } catch (error) {
            console.warn('scrollToError: Failed to set focus:', error);
            // Continue execution even if focus fails
        }
    }, [methods]);

    const handleNextError = useCallback(() => {
        if (errorFields.length === 0) return;
        const nextIndex = (currentErrorIndex + 1) % errorFields.length;
        setCurrentErrorIndex(nextIndex);
        scrollToError(errorFields[nextIndex] as Path<T>);
    }, [currentErrorIndex, errorFields, scrollToError]);

    const handlePrevError = useCallback(() => {
        if (errorFields.length === 0) return;
        const prevIndex = currentErrorIndex === 0 ? errorFields.length - 1 : currentErrorIndex - 1;
        setCurrentErrorIndex(prevIndex);
        scrollToError(errorFields[prevIndex] as Path<T>);
    }, [currentErrorIndex, errorFields, scrollToError]);

    const handleErrorClick = useCallback((fieldName: string) => {
        scrollToError(fieldName as Path<T>);
        const errorIndex = errorFields.indexOf(fieldName);
        if (errorIndex !== -1) {
            setCurrentErrorIndex(errorIndex);
        }
    }, [errorFields, scrollToError]);

    useEffect(() => {
        if (!enabled || !enableKeyboardShortcuts) return;

        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.altKey) {
                if (e.key === 'ArrowRight') {
                    e.preventDefault();
                    handleNextError();
                } else if (e.key === 'ArrowLeft') {
                    e.preventDefault();
                    handlePrevError();
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [handleNextError, handlePrevError, enabled, enableKeyboardShortcuts]);

    // Error navigation component
    const ErrorNavigation = memo(() => {
        if (!enabled || allFields.length === 0 || !isFormReady) return null;

        return (
            <div className="flex flex-col items-end gap-2 my-2">
                <div className="flex gap-1">
                    {allFields.map((fieldName) => {

                        const hasError = errorFields.includes(fieldName);
                        if (!hasError) return null;
                        // console.debug('fieldName', fieldName, hasError, errorFields);
                        return (
                            <button
                                key={fieldName}
                                onClick={() => handleErrorClick(fieldName)}
                                className={`w-2 h-2 rounded-full ${hasError ? 'bg-red-500' : 'bg-gray-300'
                                    } hover:opacity-75 transition-opacity`}
                                title={`${fieldName}${hasError ? ' (has error)' : ''}`}
                                aria-label={`Go to ${fieldName} field${hasError ? ' with error' : ''}`}
                            />
                        );
                    })}
                </div>
            </div>
        );
    });

    ErrorNavigation.displayName = 'ErrorNavigation';

    return {
        currentErrorIndex,
        errorCount: errorFields.length,
        handleNextError,
        handlePrevError,
        handleFieldClick: handleErrorClick,
        ErrorNavigation
    };
}