import SubPageLayout from '@/app/features/organisation/SubPageLayout';
import BulkEditDropdown from '@/app/features/reports/components/bulk-actions-button';
import TestEditDialog from '@/app/features/reports/components/test-edit-dialog';
import { TestTileSkeleton } from '@/app/features/reports/components/test-tile-skeleton';
import { TestStatus } from '@/app/features/reports/types/test-form';
import { useAppLayoutContext } from '@/app/layout2/AppLayout';
import EditModeToggle from '@/components/raytd/edit-mode-toggle';
import ToggleWithBadge from '@/components/raytd/toggle-with-badge';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { useGetReportTestSuitesQuery, useGetTestItemQuery } from '@app.raytd.com/store';
import { skipToken } from '@reduxjs/toolkit/query';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GroupedVirtuoso, ListProps } from 'react-virtuoso';
import { toast } from 'sonner';
import BookmarkScrollbar from './components/bookmarks';
import { ExcludedTestsBadge } from './components/excluded-tests-badge';
import FilterFacets from './components/filter-facets';
import ReportToolbar from './components/report-editing-toolbar';
import RowHeader from './components/report-group-header';
import RowContent from './components/report-row-content';
import useReportViewerContext, { ReportDisplayProvider, ReportProviders, ReportSelectionProvider, ReportViewerContext, useReportDisplayContext, useReportGroupingContext, useReportViewContext } from './components/report-viewer-context';
import StickyHeader from './components/sticky-header';
import { ExpandedItemsState, ReportGroupModes, ReportViewModes, ScrollPosition } from './types';
import { FlattenedItem, ReportItem, ReportItemContent } from './utils/entities';
import sortContentBy, { determineSortOrder } from './utils/sorters';
import { useTestEditor } from '@/app/features/reports/hooks/useTestEditor';
import { debounce } from 'lodash';


const createFlattenedItem = (
    item: ReportItem | ReportItemContent,
    scores: ScoreResult,
    isExpanded: boolean,
    level: number = 0,
    isContent: boolean,
    currentParents: string[]

): FlattenedItem => ({
    id: item.id,
    title: item.title,
    score: scores.totalScore > 0 ? (scores.totalScore / scores.averageCount).toFixed(2) : undefined,
    level,
    hasChildren: !isContent,
    hasContent: 'content' in item && Boolean(item.content?.length),
    length: ('children' in item && item.children?.length) || ('content' in item && item.content?.length),
    content: isContent,
    isExpanded,
    count: scores.count,
    ratingTypes: Array.from(scores.ratingTypes),
    testCase: isContent ? (item as ReportItemContent).testCase : undefined,
    parents: currentParents,
    missingCount: scores.missingCount,
    fastFillCount: scores.fastFillCount,
    nonFinalCount: scores.nonFinalCount,
    excludedCount: scores.excludedCount,
    includedCount: scores.averageCount,
});


const createEmptyNode = (parentId: string, level: number): FlattenedItem => ({
    id: `${parentId}-empty`,
    title: 'No tests',
    score: '',
    level,
    hasChildren: false,
    hasContent: false,
    content: false,
    isEmptyNode: true,
    ratingTypes: [],
    length: 0,
});

const isReportItem = (item: any): item is ReportItem => {
    return 'id' in item && 'title' in item;
};

const hasContent = (item: ReportItem): boolean => {
    return 'content' in item && Array.isArray(item.content) && item.content.length > 0;
};

const hasChildren = (item: ReportItem): boolean => {
    return 'children' in item && Array.isArray(item.children) && item.children.length > 0;
};

interface ScoreResult {
    totalScore: number; // Sum of valid scores
    count: number; // Number of items
    averageCount: number; // Number of items with valid scores
    ratingTypes: Set<string>;
    missingCount: number; // Number of items with missing data
    fastFillCount: number; // Number of items with fast fill errors
    excludedCount: number; // Number of items manually excluded
    nonFinalCount: number; // Number of items not final
}

const createInitialScoreResult = (): ScoreResult => ({
    totalScore: 0,
    count: 0,
    averageCount: 0,
    missingCount: 0,
    fastFillCount: 0,
    nonFinalCount: 0,
    excludedCount: 0,
    ratingTypes: new Set<string>()
});

const accumulateScores = (scores, contentScores) => {
    scores.totalScore += contentScores.totalScore;
    scores.count += contentScores.count;
    scores.averageCount += contentScores.averageCount;
    scores.missingCount += contentScores.missingCount;
    scores.fastFillCount += contentScores.fastFillCount;
    scores.nonFinalCount += contentScores.nonFinalCount;
    scores.excludedCount += contentScores.excludedCount;
    contentScores.ratingTypes.forEach(type => scores.ratingTypes.add(type));
};

const getContentScores = (content: ReportItemContent[]): ScoreResult => {
    const scores = createInitialScoreResult();

    content.forEach(contentItem => {
        const { score, testCase } = contentItem;
        scores.count++;

        // check if excluded
        const included = testCase?.exclusionReason === 'included';

        if (included && score !== null && score >= 0 && score <= 5) {
            scores.totalScore += score;
            scores.averageCount++;
        }

        if (included && testCase?.ratingType) {
            scores.ratingTypes.add(testCase.ratingType);
        }

        if (testCase?.exclusionReason === 'missing') {
            scores.missingCount++;
        } else if (testCase?.exclusionReason === 'fastfill') {
            scores.fastFillCount++;
        } else if (testCase?.exclusionReason === 'status') {
            scores.nonFinalCount++;
        } else if (testCase?.exclusionReason === 'excluded') {
            scores.excludedCount++;
        }

    });

    return scores;
};

const ScrollSeekPlaceholder = ({ height, type, index, context }) => {

    // if (type === 'group') {
    //     return "Hi";
    // }

    return (
        (<div
            style={{
                height,
                padding: "8px",
                boxSizing: "border-box",
                overflow: "hidden",
            }}
        >
            <TestTileSkeleton />
        </div>)
    );
}

const ListHeader = ({ children }) => {

    const { isHeaderFixed } = useReportDisplayContext();

    return (
        <div className={cn(
            "bg-zinc-300 w-full sticky z-[1] transition-all duration-300 ease-in-out",
            isHeaderFixed && "top-[126px]")}>
            {children}
        </div>
    )
}



// const GListComponent: React.FC<ListProps> = ({ children, ...props }) => {
//     console.debug('GListComponent', props);
//     return <div {...props}>
//         {children}
//     </div>
// }

const GListComponent = React.forwardRef<HTMLDivElement, ListProps>(({
    children,
    style,
    ...props
}, ref) => {

    const { isHeaderFixed } = useReportDisplayContext();

    const marginTop = isHeaderFixed ? '126px' : '0px';

    return <div {...props} style={{ ...style, marginTop }} ref={ref}>
        {children}
    </div>
});

const globalScoreCache = new WeakMap<ReportItem, ScoreResult>();

const calculateScores = (item: ReportItem): ScoreResult => {
    // Check cache first
    if (globalScoreCache.has(item)) {
        return globalScoreCache.get(item)!;
    }

    const scores = createInitialScoreResult();

    // Calculate content scores if present
    if (item.content?.length) {
        accumulateScores(scores, getContentScores(item.content));
    }

    // Calculate children scores if present
    if (item.children?.length) {
        for (const child of item.children) {
            accumulateScores(scores, calculateScores(child));
        }
    }

    // Cache and return result
    globalScoreCache.set(item, scores);
    return scores;
};

const VirtualizedNestedList = React.forwardRef(({
    width, height,
    setIsScrolling,
    setScrollPosition,
    scrollParent

}: {
    width?: number,
    height?: number,
    setIsScrolling: (scrolling: boolean) => void;
    setScrollPosition: (position: ScrollPosition) => void;
    scrollParent?: HTMLDivElement;

}, ref) => {

    const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (scrollParent) {
            setScrollContainer(scrollParent);
        }
    }, [scrollParent]);

    const [flattenedItems, setFlattenedItems] = useState<FlattenedItem[]>([]);

    const { data,
        filters,
        groupMode,
    } = useReportViewContext();

    const { viewMode } = useReportDisplayContext();

    const { visibleTestCount } = useReportViewContext();
    const { expandedItems: expandedIds, setExpandedItems: setExpandedIds
    } = useReportGroupingContext();

    const { headerMode } = useReportDisplayContext();

    const [scrollingRange, setScrollingRange] = useState<[number, number]>([0, 0]);

    const [visibleRange, setVisibleRange] = useState({
        startIndex: 0,
        endIndex: 0,
    })

    const handleSetScrollRange = useCallback((range) => {
        setVisibleRange(range);

        const { startIndex, endIndex } = range;

        const total = filters?.filteredCounts?.filtered?.total || 0;

        const relativePositions = {
            startIndex: startIndex / total * 100,
            endIndex: endIndex / total * 100
        };

        setScrollPosition({
            range,
            relativePositions
        });

    }, [filters?.filteredCounts]);

    // const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set());
    const listRef = useRef<any>(null);

    const flattenData = useMemo(() => {

        const flatten = (
            items: ReportItem[],
            level: number = 0,
            isContent = false,
            parents: readonly string[] = []
        ): FlattenedItem[] => {
            const result: FlattenedItem[] = [];
            const sortOrder = determineSortOrder(groupMode, headerMode);

            for (const item of items) {
                const isExpanded = expandedIds.has(item.id);
                const scores = calculateScores(item);
                const currentParents = isContent
                    ? parents
                    : Object.freeze([...parents, item.title]);

                result.push(createFlattenedItem(
                    item,
                    scores,
                    isExpanded,
                    level,
                    isContent,
                    //@ts-expect-error
                    currentParents
                ));

                if (isExpanded) {
                    if (hasChildren(item)) {
                        result.push(...flatten(
                            item.children!,
                            level + 1,
                            false,
                            currentParents
                        ));
                    }

                    if (hasContent(item)) {
                        const sortedContent = sortContentBy(item.content as ReportItemContent[], sortOrder);
                        result.push(...flatten(
                            //@ts-expect-error
                            sortedContent as unknown as ReportItemContent[],
                            level + 1,
                            true,
                            currentParents
                        ));
                    }

                    if (!hasContent(item) && !hasChildren(item)) {
                        result.push(createEmptyNode(item.id, level + 1));
                    }
                }
            }

            return result;
        };

        return flatten;

    }, [expandedIds, groupMode, headerMode]);

    const { groups, groupCounts, allCollapsed } = useMemo(() => {
        const groups: string[] = [];
        const groupCounts: number[] = [];

        // Start with assumption all are collapsed
        let allCollapsed = true;

        // Use reduce instead of forEach for better performance
        return flattenedItems.reduce((acc, group) => {
            if (!group.content) {  // isGroup check
                groups.push(group.id);

                // Update allCollapsed flag
                if (group.isExpanded) {
                    acc.allCollapsed = false;
                }

                // Calculate count only if expanded and has content
                const itemCount = (group.isExpanded && group.hasContent) ? group.length : 0;
                groupCounts.push(itemCount);
            }

            return { groups, groupCounts, allCollapsed };
        }, { groups, groupCounts, allCollapsed });
    }, [flattenedItems]);

    useEffect(() => {
        console.time('flattenData');
        setFlattenedItems(flattenData(data));
        console.timeEnd('flattenData');
        console.debug('flattenedItems', flattenedItems);
        listRef.current?.resetAfterIndex(0);
    }, [data, expandedIds, flattenData, viewMode]);

    const toggleExpand = useCallback((id: string) => {
        const next = new Set(expandedIds);
        if (next.has(id)) {
            next.delete(id);
        }
        else {
            next.add(id);
        }
        setExpandedIds(next);
    }, [expandedIds, setExpandedIds]);

    return (
        <GroupedVirtuoso
            useWindowScroll={true}
            //@ts-expect-error
            ref={ref}
            // style={{ height, width }}
            style={{ flex: 1 }}
            isScrolling={setIsScrolling}
            rangeChanged={handleSetScrollRange}
            overscan={20}
            customScrollParent={scrollContainer}
            data={flattenedItems}
            groupCounts={groupCounts}
            groupContent={index => {
                // console.debug('groupContent', { index, groups, group: flattenedItems[index] });
                const item = flattenedItems.filter(item => !item.content)[index];
                // if (item.content || item.isExpanded) {
                //     return null;
                // } 
                if ((!item.content)) {
                    return (
                        (<RowHeader
                            item={item}
                            indent={item.level * 24}
                            toggleExpand={toggleExpand}
                            active={false}
                            className="bg-white"
                        />)
                        // <div
                        //     style={{
                        //         paddingLeft: `${item.level * 24}px`,
                        //     }}
                        //     className="bg-zinc-200 py-6"
                        // >{item.level === 0 ? 'Building - ' : item.level === 1 ? 'Level - ' : 'Area - '} {item.title} ({item.length}, {item.count})</div>
                    );
                }
            }}

            // itemContent={(index, item) => (
            //     <div className="relative" data-index={index}>
            //         <ItemContent item={item} index={index} />
            //     </div>
            // )}
            itemContent={(index, groupIndex) => {

                // console.debug('itemContent', { index, groupIndex, flattenedItems });

                //const item = flattenedItems.filter(item => item.groupId === )[index];
                // const groupId = groups[groupIndex];
                // const item = flattenedItems
                //     .filter(item => item.groupId === groupId)[index];
                const item = flattenedItems.filter(item => item.content)[index];
                if (!item || !item.content) {
                    return null;
                }
                // console.debug('itemContent inner', { index, groupIndex, item });
                // return <div>Item {groupIndex} {index}: {groupId}</div>
                return (
                    <div className="relative" data-index={index}>
                        <RowContent
                            item={item}
                            indent={item.level * 24}
                            toggleExpand={toggleExpand}
                        />
                        {/* <div
                            style={{
                                paddingLeft: `${item.level * 24}px`,
                            }}
                        >- Item: {item.title} ({item.id}) - {index}
                        </div> */}
                    </div>
                );
            }}

            components={{
                ScrollSeekPlaceholder,
                TopItemList: ListHeader,
                List: GListComponent
            }}
            scrollSeekConfiguration={{
                enter: (velocity) => Math.abs(velocity) > 2000,
                exit: (velocity) => {
                    const shouldExit = Math.abs(velocity) < 10;
                    if (shouldExit) {
                        setScrollingRange([null, null]);
                    }
                    return shouldExit;
                },
                change: (_velocity, { startIndex, endIndex }) => setScrollingRange([startIndex, endIndex])
            }}

        // computeItemKey={(index, item) => item.id}
        />
    );

});

export const collectAllGroups = (items: ReportItem[], allIds = new Set<string>(), includeContentGroups = true) => {
    items.forEach(item => {
        if (!includeContentGroups && item.content?.length > 0) {
        } else {
            allIds.add(item.id);
        }
        if (item?.children?.length > 0) {
            collectAllGroups(item.children, allIds, includeContentGroups);
        }

    });
    return allIds;
};



type ReportViewerProps = {
    reportId: string;
}

const ReportViewer: React.FC<ReportViewerProps> = ({ reportId }) => {

    const [viewMode, setViewMode] = useState<ReportViewModes>('details');

    const [showFastQA, setShowFastQA] = useState(false);
    const [showMissingData, setShowMissingData] = useState(false);

    const [isScrolling, setIsScrolling] = useState(false);
    const { mainPageElement } = useAppLayoutContext();

    const memoizedScrollParent = useMemo(() => mainPageElement, [mainPageElement]);

    const {
        data,
        reportTotal,
        filteredData,
        visibleTestCount,
        report,
        filters,
        missingDataCount,
        missingSegments,
        bookmarks,
        isEditMode: editMode,
        setEditMode,
    } = useReportViewContext();


    const { data: testSuites, isLoading: isLoadingSpecifications } = useGetReportTestSuitesQuery(report?.id ? Number(report?.id) : skipToken);
    const inspections = report?.assessments || []
    const assets = report?.assets || []

    const {
        isOpen,
        testItem,
        isLoading,
        openEditor,
        closeEditor
    } = useTestEditor({
        onEditorClose: () => {
            //toast.success('Test closed');
        }
    });

    const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
        range: {
            startIndex: 0,
            endIndex: 0
        },
        relativePositions: {
            startIndex: 0,
            endIndex: 0
        }
    });

    const handleScrollRangeChange = useCallback((position) => {
        setScrollPosition(position);
    }, [data]);

    const listRef = useRef<any>(null);

    const handleBookmarkClick = useCallback((bookmark) => {
        console.debug('scroll to', bookmark, listRef)
        listRef.current.scrollToIndex(bookmark.index, 'start');
    }, []);

    const updateTestStatus = useCallback(async (id: string, status: TestStatus) => {
        toast.success(`Updating test ${id} to status ${status}`);
    }, []);

    const handleFilterChange = useCallback((value: string) => {
        filters?.setFilterInput(value);
      }, [filters?.setFilterInput]);

    return (
        (<SubPageLayout
            title="Results"
            rightComponents={
                <>
                    <EditModeToggle editMode={editMode} setEditMode={setEditMode} />
                    <Button variant='default' onClick={() => openEditor(null)}>New Test</Button>
                </>
            }
        >
            <ReportViewerContext.Provider
                value={{
                    activeItems: [], setActiveItems: () => { },
                    showFastFillQA: showFastQA,
                    showTestEditor: openEditor,
                    showMissingData: showMissingData,
                }}>
                <ReportDisplayProvider>
                    <ReportSelectionProvider>
                        <StickyHeader>
                            <ReportToolbar
                                setViewMode={setViewMode}
                                filters={filters}
                            />

                            <div className='flex-1'>
                                {/** Place filter outputs here */}
                            </div>

                            <div className="flex flex-row items-center gap-4 mb-2 pb-2 border-b border-zinc-200">

                                <div className="flex items-center">
                                    <BulkEditDropdown
                                        // onStatusChange={handleBulkStatusChange}
                                        filteredData={filteredData}
                                        updateTestStatus={updateTestStatus}
                                    />
                                </div>

                                {/* <Input
                                    placeholder="Filter..."
                                    value={filters?.filterInput}
                                    onChange={handleFilterInputChange}
                                    className="h-8 w-[150px] lg:w-[250px]"
                                /> */}

                                <FilterInput
                                    onFilterChange={handleFilterChange}
                                />

                                <FilterFacets

                                />


                                <div className="flex-1"></div>

                                <ExcludedTestsBadge counts={filters?.filteredCounts?.tests} />

                                <ToggleWithBadge
                                    label="Fast Fill"
                                    count={missingSegments}
                                    isActive={showFastQA}
                                    onToggle={() => setShowFastQA(prev => !prev)}
                                    badgeClassName="bg-tests-fastfill"
                                    activeClassName="data-[state=on]:bg-tests-fastfill text-white"
                                />

                                <ToggleWithBadge
                                    label="Missing Data"
                                    count={missingDataCount}
                                    isActive={showMissingData}
                                    onToggle={() => setShowMissingData(prev => !prev)}
                                    badgeClassName="bg-red-400"
                                    activeClassName="data-[state=on]:bg-red-400 text-white"
                                />

                            </div>
                        </StickyHeader>
                        <div className="flex flex-row items-center gap-4 mb-2">
                            {visibleTestCount} / {reportTotal}
                        </div>

                        <div className="border-t border-zinc-900 h-full"
                        // style={{ height: 'calc(100vh - 440px)' }}
                        >
                            <VirtualizedNestedList
                                ref={listRef}
                                scrollParent={memoizedScrollParent}
                                setIsScrolling={setIsScrolling}
                                setScrollPosition={handleScrollRangeChange}
                            />

                        </div>

                        <BookmarkScrollbar bookmarks={bookmarks} onBookmarkClick={handleBookmarkClick} scrollPosition={scrollPosition} />
                    </ReportSelectionProvider>
                </ReportDisplayProvider>
            </ReportViewerContext.Provider>

            <TestEditDialog
                isOpen={isOpen}
                onClose={closeEditor}
                testItem={testItem}
                isLoading={isLoading}
                // onSubmit={handleSubmit}
                inspections={inspections}
                assets={assets}
                testSuites={testSuites as any}
            />
        </SubPageLayout>)
    );
};

const App: React.FC<ReportViewerProps> = ({ reportId }) => {
    return (
        <ReportProviders reportId={reportId}>
            <ReportViewer reportId={reportId} />
        </ReportProviders>
    );
}

export default App;

interface FilterInputProps {
    onFilterChange: (value: string) => void;
    placeholder?: string;
    className?: string;
}

const FilterInput = ({
    onFilterChange,
    placeholder = "Filter...",
    className = "h-8 w-[150px] lg:w-[250px]"
}: FilterInputProps) => {
    const [localValue, setLocalValue] = useState("");

    const debouncedCallback = useMemo(
        () =>
            debounce((value: string) => {
                onFilterChange(value);
            }, 300, { leading: false, trailing: true }),
        [onFilterChange]
    );

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debouncedCallback.cancel();
        };
    }, [debouncedCallback]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setLocalValue(newValue);
        debouncedCallback(newValue);
    }, [debouncedCallback]);

    return (
        <Input
            placeholder={placeholder}
            value={localValue}
            onChange={handleChange}
            className={className}
        />
    );
};
