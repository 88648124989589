import { cn } from '@/lib/utils';
import React, { ReactNode } from 'react';

interface TextWithBreaksProps {
  children: ReactNode;
  className? : string;
}

const TextWithBreaks: React.FC<TextWithBreaksProps> = ({ className, children }) => {
  if (!children) {
    return null;
  }

  if (typeof children !== 'string') {

    return children as JSX.Element;
  }

//  console.debug('children',{ children, type: typeof children });

  const lines = (children as string)?.replace(/\\n/g, '\n').trim();

  return (
    <div className={cn(className, 'whitespace-pre-wrap')}>
      {/* {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))} */}
      {lines}
    </div>
  );
};

export default TextWithBreaks;