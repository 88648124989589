import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"

const testCountPillVariants = cva(
  "inline-flex items-center justify-center rounded-md px-2.5 py-1.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        archived: "bg-white text-zinc-500 border border-zinc-400",
        draft: "bg-white text-zinc-950 border border-zinc-950",
        qa: "bg-zinc-500 text-white",
        published: "bg-black text-white",
        inactive: 'border-zinc-300 bg-transparent text-transparent'
      },
    },
    defaultVariants: {
      variant: "archived",
    },
  }
)

export interface TestCountPillProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof testCountPillVariants> {
  value: number
}

const TestCountPill = React.forwardRef<HTMLDivElement, TestCountPillProps>(
  ({ className, variant, value, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(testCountPillVariants({ variant }), "h-7 min-w-[2.5rem]", className)}
        {...props}
      >
        {value}
      </div>
    )
  }
)

TestCountPill.displayName = "TestCountPill"

export type TestCountVariant = "draft" | "qa" | "published" | "archived"

interface TestCountBadgeProps {
  variant: TestCountVariant;
  count: number;
  hiddenIfZero?: boolean;
}

export const testBadgeVariants = {
  archived: "bg-white border text-zinc-500 border-zinc-400",
  draft: "bg-white text-zinc-950 border border-zinc-950",
  qa: "bg-zinc-500 text-white",
  published: "bg-black text-white",
  inactive: 'border-zinc-300 bg-transparent text-transparent'
};

export function TestCountBadge({ variant, count, hiddenIfZero=false }: TestCountBadgeProps) {
  if (count <= 0 && hiddenIfZero) return null

  return (
    <Badge 
      variant="outline" 
      className={cn(
        "px-2.5 py-0.5 rounded-md font-medium",
        testBadgeVariants[variant]
      )}
    >
      {count}
    </Badge>
  )
}


export { TestCountPill, testCountPillVariants }