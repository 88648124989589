import { TestTileSkeleton } from "@/app/features/reports/components/test-tile-skeleton";
import { MainPage } from "@/app/layout2/AppLayout";
import SubPageLayout from "@/components/raytd/sub-page-layout";
import { Skeleton } from "@/components/ui/skeleton";

export const LoadingState = () => {
    return (
        <MainPage>
            <SubPageLayout title="Results">
          {/* Toolbar skeletons */}
          <div className="flex flex-col space-y-4 mb-4">
            {/* Top row - view/group toggles, expand buttons */}
            <div className="flex flex-row gap-6 items-center">
              <Skeleton className="h-8 w-24" /> {/* View toggle */}
              <Skeleton className="h-8 w-48" /> {/* Group toggle */}
              <div className="flex gap-2"> {/* Expand/collapse buttons */}
                <Skeleton className="h-8 w-8" />
                <Skeleton className="h-8 w-8" />
                <div className="bg-zinc-200 w-[1px] my-1" />
                <Skeleton className="h-8 w-8" />
                <Skeleton className="h-8 w-8" />
              </div>
              <div className="flex-1" />
              <Skeleton className="h-8 w-32" /> {/* Filter button */}
            </div>
  
            {/* Filter bar */}
            <div className="flex flex-row gap-6 items-center">
              <Skeleton className="h-8 w-64" /> {/* Search input */}
              <Skeleton className="h-6 w-32 rounded-full" /> {/* Filter pill */}
              <Skeleton className="h-6 w-24 rounded-full" /> {/* Filter pill */}
              <div className="flex-1" />
              <div className="flex gap-4">
                <Skeleton className="h-8 w-32" /> {/* Toggle button */}
                <Skeleton className="h-8 w-32" /> {/* Toggle button */}
              </div>
            </div>
          </div>
  
          {/* Content area */}
          <div className="bg-white w-full border-t border-zinc-900" style={{ height: 'calc(100vh - 428px)' }}>
            <div className="h-full w-full space-y-4 p-4">
              {/* Show more skeleton tiles and vary their widths */}
              {[...Array(5)].map((_, index) => (
                <div key={index} className="animate-pulse">
                  <TestTileSkeleton 
                    width={`${Math.floor(Math.random() * (95 - 75) + 75)}%`} 
                  />
                </div>
              ))}
            </div>
          </div>
        </SubPageLayout>
      </MainPage>
    );
  };