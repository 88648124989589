import defaultValues from "@/app/features/reports/types/form-default-values";
import convertInspectionToFormValues from "@/app/features/reports/utils/convertInspectionToFormValues";
import { ReportTestItem } from "@/app/features/reports/utils/entities";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TestSuite } from "store/src/lib/tests/entity";
import { ValidationErrors } from '../types/missing-data';
import filterActiveCustomFields from './filterActiveCustomFields';
import { useEnchancedDynamicResolver } from './testFormValidator';
import getTestSuiteForSpecification from './getTestSpecification';

export type ValidationResults = {
    values?: Record<string, any>;
    testCase?: ReportTestItem;
    errors: ValidationErrors;
};

// Helper function to compare Maps
const mapEquals = (map1: Map<number, ValidationResults>, map2: Map<number, ValidationResults>): boolean => {
    if (map1.size !== map2.size) return false;
    for (const [key, val] of map1) {
        if (!map2.has(key) || JSON.stringify(val) !== JSON.stringify(map2.get(key))) {
            return false;
        }
    }
    return true;
};

const useValidationResults = (data: ReportTestItem[], testSuites: TestSuite[]) => {
    const resolver = useEnchancedDynamicResolver(testSuites as any);

    const [validationResults, setValidationResults] = useState<Map<number, ValidationResults>>(new Map());

    const [isValidationLoading, setIsValidationLoading] = useState(false);

    const fetchValidationResults = useCallback(async (data) => {
        setIsValidationLoading(true);
        try {
            if (!data?.length || !testSuites) return;

            const results = await Promise.all(
                data.map(async (testCase: ReportTestItem) => {
                    const initialData = convertInspectionToFormValues(testCase);
                    const testSuite = getTestSuiteForSpecification(testSuites, testCase.test);

                    const values = filterActiveCustomFields(initialData, testSuite.suite);

                    const resolverResult = await resolver({
                        ...defaultValues,
                        ...values,
                    });

                    // console.debug('Resolver result', { values, errors: resolverResult?.errors });

                    return [testCase.assessmentTestId, {
                        ...resolverResult,
                        testCase
                    }] as const;
                })
            );

            const validationResults = new Map<number, ValidationResults>(results);

            setValidationResults(prevTests => {
                // Compare stringified maps for equality check
                const prevMap = prevTests instanceof Map ? prevTests : new Map();
                if (mapEquals(prevMap, validationResults)) {
                    return prevTests;
                }
                return validationResults;
            });

        } catch (error) {
            console.error('Error enriching tests:', error);
        } finally {
            setIsValidationLoading(false);
        }

    }, [resolver, testSuites]);

    const revalidateData = useCallback(() => {
        setValidationResults(new Map());
        fetchValidationResults(data);
    }, [data]);

    const missingData = useMemo(() => {
        const result = new Map<number, ValidationErrors>();
        
        validationResults.forEach((validation, id) => {
            const errors = Object.entries(validation.errors).reduce((acc, [type, error]) => {
                if (Array.isArray(error) ? error.length > 0 : !!error) {
                    acc[type] = error;
                }
                return acc;
            }, {} as ValidationErrors);
            if (Object.keys(errors).length > 0) {
                result.set(id, errors);
            }
        });

        console.debug('Missing data', result);
        return result;
    }, [validationResults]);

    useEffect(() => {
        if (!data?.length || !testSuites) return;
        fetchValidationResults(data);
    }, [data, testSuites]);

    return { missingData, validationResults, revalidateData, isValidationLoading };
};

export default useValidationResults;