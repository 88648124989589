import React from "react";
import AuthorBadge from "@/components/raytd/user-avatar"
import formatDateAndTime from "@/utils/formatDateAndTime"
import { UsersEntity } from '@app.raytd.com/store'

const AuthorTimestamp: React.FC<{
    author: UsersEntity;
    timestamp: string;
    label: string;
}> = ({ author, timestamp, label }) => (
    <div className="flex flex-row gap-4 items-center text-xs">
        <AuthorBadge item={author} />
        <div className="text-zinc-500 flex flex-col">
            <span className="text-zinc-300">{label}</span>
            <span className="font-medium">{formatDateAndTime(timestamp)}</span>
        </div>
    </div>
);

export default React.memo(AuthorTimestamp);