import { TestStatus } from "@/app/features/reports/types/test-form";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

const BulkStatusChangeDialog = ({ 
    isOpen, 
    onClose, 
    eligibleTests, 
    ineligibleTests, 
    status, 
    onConfirm 
}: {
    isOpen: boolean;
    onClose: () => void;
    eligibleTests: Array<{ id: string; title: string }>;
    ineligibleTests: Array<{ id: string; title: string; reason: string }>;
    status: TestStatus;
    onConfirm: () => void;
}) => {
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-2xl">
                <DialogHeader>
                    <DialogTitle>Confirm Status Change</DialogTitle>
                    <DialogDescription>
                        The following changes will be made:
                    </DialogDescription>
                </DialogHeader>
                
                {eligibleTests.length > 0 && (
                    <div className="space-y-2">
                        <h3 className="font-medium">Tests to be changed to {status}:</h3>
                        <div className="max-h-40 overflow-y-auto border rounded p-2">
                            {eligibleTests.map(test => (
                                <div key={test.id} className="py-1">
                                    {test.title}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {ineligibleTests.length > 0 && (
                    <div className="space-y-2">
                        <h3 className="font-medium text-red-600">Tests that cannot be changed:</h3>
                        <div className="max-h-40 overflow-y-auto border rounded p-2">
                            {ineligibleTests.map(test => (
                                <div key={test.id} className="py-1 flex justify-between">
                                    <span>{test.title}</span>
                                    <span className="text-red-600">{test.reason}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <DialogFooter>
                    <Button variant="outline" onClick={onClose}>Cancel</Button>
                    <Button onClick={onConfirm} disabled={eligibleTests.length === 0}>
                        Proceed ({eligibleTests.length} tests)
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default BulkStatusChangeDialog;