import { useAuth } from "@/app/_components/privateRoute";
import { useAppDispatch, authActions } from "@app.raytd.com/store";
import { useEffect } from "react";

export const Logout = () => {

  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return <div>Logging out</div>;

}