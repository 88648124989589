import UploadMonitor from "../_components/UploadMonitor";
import { ProfileForm } from './TestAvatarUpload';
import { UploadForm } from './UploadForm';
import TestEditDialog from '../features/reports/components/test-edit-dialog';
import { useGetReportItemsQuery, useGetReportQuery, useGetReportTestSuitesQuery, useGetTestItemQuery } from "@app.raytd.com/store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import TestItem from "@/app/features/reports/components/test-tile";
import { ReportItem, ReportTestItem } from '../features/reports/utils/entities';
import convertSpacesToTree from '../features/reports/utils/convertSpacesToTree';
import { itemsEqual } from "@dnd-kit/sortable/dist/utilities";
import { ReportViewerContext } from '../features/reports/components/report-viewer-context';
import { ExpandedItemsState, ReportGroupModes, ReportHeaderModes, ReportViewModes } from "@/app/features/reports/types";
import { collectAllGroups } from "@/app/features/reports/ReportView";
import RowContent from "@/app/features/reports/components/report-row-content";
import { Toggle } from "@/components/ui/toggle";
import { TextComparisonField, TextEnrichments } from "@/app/utils/TextComparison";
import { MissingData } from "@/app/utils/MissingData";

//2432


const TestEditingDialog = () => {

    const { data: report } = useGetReportQuery("13");
    //2165
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [isTestEditorOpen, setTestEditorIsOpen] = useState(false);
    const { data: testSuites, isLoading: isLoadingSpecifications } = useGetReportTestSuitesQuery(
        report?.id ? Number(report.id) : skipToken
    );

    const inspections = report?.assessments || []
    const assets = report?.assets || []
    const { data: testItem, refetch } = useGetTestItemQuery(
        selectedId ? selectedId : skipToken
    );

    const [editingTest, setEditingTest] = useState();

    useEffect(() => {
        if (testItem && selectedId) {
            setEditingTest(testItem);
            setTestEditorIsOpen(true);
        }
    }, [testItem, selectedId]);

    const handleEditorClose = useCallback(() => {
        setTestEditorIsOpen(false);
        setEditingTest(null);
        setSelectedId(null);
    }, []);


    if (!report || isLoadingSpecifications || !testSuites || !inspections || !assets) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <TooltipProvider>
                <h1>Test Editing Dialog</h1>
                <Button onClick={() => setSelectedId(2322)}>Open Test Editor</Button>
                <TestEditDialog
                    isOpen={isTestEditorOpen}
                    onClose={handleEditorClose}
                    isLoading={false}
                    testItem={editingTest}
                    inspections={inspections}
                    assets={assets}
                    testSuites={testSuites as any}
                    onSubmit={function (data: any): void {
                        // handleEditorClose();
                    }}
                />
            </TooltipProvider>
        </div>
    )

}

export const findFirstContent = (items: ReportItem[]): any | undefined => {
    const traverse = (item: ReportItem): any | undefined => {
        // Check if content exists and has elements
        if (item.content?.length) {
            return item.content[0];
        }

        // Check children recursively
        if (item.children?.length) {
            for (const child of item.children) {
                const result = traverse(child);
                if (result !== undefined) {
                    return result;
                }
            }
        }

        return undefined;
    };

    // Traverse each root item
    for (const item of items) {
        const result = traverse(item);
        if (result !== undefined) {
            return result;
        }
    }

    return undefined;
};

const TestTileTest = () => {
    const { data: report } = useGetReportQuery("13");
    const [selectedId, setSelectedId] = useState<number | null>(2322);


    const { data: items, isLoading } = useGetReportItemsQuery("13", {
        selectFromResult: ({ data, isLoading }) => ({
            data: data?.find(item => item.assessmentTestId === selectedId),
            isLoading
        }),
    });

    console.debug('TestTile report, items', { report, items })

    const [data, setData] = useState<ReportTestItem>(null);

    useEffect(() => {
        if (items) {
            const flattened = convertSpacesToTree([items] as unknown as ReportTestItem[]);
            const content = findFirstContent(flattened);

            console.debug('flattened', content);
            // if (flattened?.length > 0) {
            //     // setData(flattened[0].content[0].testCase);
            //     setData(flattened[0].content[0]);
            // }else {
            setData(content)
            // }
        }
    }, [items]);


    // const [activeItems, setActiveItems] = useState<string[]>([])
    const [expandedItems, setExpandedItems] = useState<ExpandedItemsState>({
        element: new Set(),
        location: new Set(),
        test: new Set(),
        rating: new Set()
    });

    const [headerMode, setHeaderMode] = useState<ReportHeaderModes>('normal');
    const [groupMode, setGroupMode] = useState<ReportGroupModes>('location');
    const [allExpanded, setAllExpanded] = useState<true | false | 'partial'>(false);
    const totalGroups = useRef<number>(0);

    const [viewMode, setViewMode] = useState<ReportViewModes>('details');

    const expandedIds = expandedItems[groupMode];

    const collapseAll = () => {
        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: new Set(),
        }));
    };

    const expandAll = useCallback(() => {

        if (allExpanded === true) {
            collapseAll();
            return;
        }

        //@ts-expect-error
        const allIds = collectAllGroups(data);

        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: allIds,
        }));
    }, [data, groupMode, allExpanded]);


    const switchHeaderMode = useCallback(() => {
        setHeaderMode((prev) => prev === 'normal' ? 'switch' : 'normal');
    }, []);

    const setGroupExpandedItems = useCallback((value: Set<string>) => {
        setExpandedItems((prev) => ({
            ...prev,
            [groupMode]: value,
        }));
    }, [groupMode]);

    // const testSuite = useMemo(() =>
    //     report?.testSuites.find(ts =>
    //         ts.id === item.testCase?.testSpecification?.testSuiteId
    //     ),
    //     [report?.testSuites, item.testCase?.testSpecification?.testSuiteId]
    // );

    // const assessment = useMemo(() =>
    //     report?.assessments.find(assessment =>
    //         assessment.id === item.testCase?.assessmentId
    //     ),
    //     [report?.assessments, item.testCase?.assessmentId]
    // );

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (!data) {
        return <div>No Test data</div>
    }

    if (data) {

        return (
            <ReportViewerContext.Provider
                value={{
                    //@ts-expect-error
                    data,
                    report,
                    activeItems: [], setActiveItems: () => { },
                    expandedItems: expandedIds,
                    setExpandedItems: setGroupExpandedItems,
                    expandAll, collapseAll,
                    headerRefs: { current: {} }, contentRef: { current: null },
                    viewMode,
                    switchHeaders: switchHeaderMode,
                    headerMode,
                    showTestEditor: undefined,
                }}>
                <div className="p-0 flex flex-col gap-4 my-6">
                    <h1 className="font-medium text-lg">Test Tile</h1>
                    <div className="flex flex-row gap-4">
                        <Button onClick={expandAll}>Expand All</Button>
                        <Button onClick={collapseAll}>Collapse All</Button>
                        <Toggle
                            aria-label="Switch Header Mode"
                            variant="outline"
                            onPressedChange={switchHeaderMode}
                        >Switch Header Mode</Toggle>
                    </div>
                    <div className="p-6 bg-white">
                        <RowContent
                            //@ts-expect-error
                            item={data}
                        />
                    </div>
                </div>
            </ReportViewerContext.Provider>
        )
    }


    // return (
    //     <div>
    //         <h1>Test Tile</h1>
    //         <TestItem item={item} testSuite={testSuite} assessment={assessment} />
    //     </div>
    // )
}

export default function Page() {

    return (

        <div className="p-8">
            <MissingData />
            {/* <TextEnrichments /> */}
            {/* <TextComparisonField /> */}
            {/* <TestEditingDialog />
            <TestTileTest /> */}
            {/* <ProfileForm />

            
            <UploadForm formId='8afa46af-8c52-475c-ada9-283b7f7e19d4' /> */}
            <UploadMonitor />
        </div>

    );
}