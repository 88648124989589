import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import FeedbackDialog from '@/components/raytd/feedback-dialog';

const UpgradeCard: React.FC = () => {
  return (
    <Card className="shadow-none border-none bg-zinc-100">
      <CardHeader className="p-2 pt-0 md:p-4">
        <CardDescription>
          You are enrolled in our Raytd.Beta program
        </CardDescription>
      </CardHeader>
      <CardContent className="p-2 pt-0 md:p-4 md:pt-0">
        <FeedbackDialog />
      </CardContent>
    </Card>
  );
};

export default UpgradeCard;
