import { KeyExtractor } from './calculateFilterCounts';
import { FilterItem, ReportTestItem } from './entities';
import { FacetCounts } from './facets';

export const convertTestsToElementFacets = (
  filteredItems: ReportTestItem[],
  allItems: ReportTestItem[],
  counts?: FacetCounts
): FilterItem[] => {
  if (!allItems?.length) return [];

  //preprocess element.path to create a structured hierarchy

  const elements = allItems.reduce((acc, item) => {
    let current = acc;

    if (!item?.element?.id || !item.element.path) {
      const existing = current.find((child) => child.id === 'null');
      if (!existing) {
        // Handle null elements
        const nullElement = {
          id: 'null',
          key: 'element',
          name: '(No Element)',
          count: 0,
          totalCount: 0,
          children: [],
        };
        current.push(nullElement);
        return acc;
      } else {
        existing.count++;
      }
    }

    item.element.path.forEach((element, index) => {
      // id is the string representation of the element id and all the parent ids
      const id = item.element.path
        .slice(0, index + 1)
        .map((p) => p.id)
        .join('.');
      //const id = element.id.toString();
      // const key = elementKeyExtractors[index](item);
      const key = 'element';
      const existing = current.find((child) => child.id === id);
      if (existing) {
        current = existing.children;
      } else {
        const newElement = {
          id,
          key,
          name: element.name,
          count: 0,
          totalCount: 0,
          children: [],
        };
        current.push(newElement);
        current = newElement.children;
      }
    });
    return acc;
  }, []);

  console.debug('elements', elements);

  // Recursively traverse the nested structure stored in extraProps.children
  // to attach counts and sort at every level.
  const buildHierarchy = (item: FilterItem, counts?: any): FilterItem => {
    // Base case: if no children, return the item
    if (!item.children || !item.children.length) {
      return {
        ...item,
        key: item.extraProps?.key || item.key,
        filteredCount: counts?.filtered?.[item.id] ?? item.filteredCount,
        count: counts?.total?.[item.id] ?? item.count,
        children: [],
      };
    }

    // Recursive case: process children
    const processedChildren = item.children
      .map((child) => buildHierarchy(child, counts))
      .sort((a, b) => a.name.localeCompare(b.name));

    return {
      ...item,
      key: item.extraProps?.key || item.key,
      filteredCount: counts?.filtered?.[item.id] ?? item.filteredCount,
      count: counts?.total?.[item.id] ?? item.count,
      children: processedChildren,
    };
  };

  const facets = elements.map(buildHierarchy);

  return facets;
};
export default convertTestsToElementFacets;

// Key extractors for count generation
export const elementKeyExtractors: KeyExtractor[] = [
  // Root level
  (item) => item.element?.path?.[0]?.id.toString() ?? 'null',
  // Subsequent levels
  (item) =>
    item.element?.path?.[0]?.id
      ? item.element?.path?.[1]?.id.toString() ?? 'null'
      : null,
  (item) =>
    item.element?.path?.[1]?.id
      ? item.element?.path?.[2]?.id.toString() ?? 'null'
      : null,
  (item) =>
    item.element?.path?.[2]?.id
      ? item.element?.path?.[3]?.id.toString() ?? 'null'
      : null,
  (item) =>
    item.element?.path?.[3]?.id
      ? item.element?.path?.[4]?.id.toString() ?? 'null'
      : null,
  // Add more levels if needed
];
