import { FilterItem, ReportTestItem } from './entities';
import { CalculatedFilters, Count } from './calculateFilterCounts';
import { FilterCounts, FilterKeys } from '../types/filters';
import sortByName from './sortByName';

export interface FacetChild {
  id: string
  name: string
  key: string
  count: number
  children?: FacetChild[]
  extraProps?: Record<string, any>
}

export interface FacetConfig<T, C = FacetChild> {
  key: string
  getId: (item: T) => string
  getName: (item: T) => string
  getChildren?: (item: T) => C[]
  extraProps?: (item: T) => Record<string, any>
}

// export interface FacetCounts {
//   filtered: Record<string, number>;
//   total: Record<string, number>;
// }

export type FacetCounts = Record<string, Count>

export const createFacet = <T, C extends FacetChild>(
  items: T[],
  allItems: T[],
  config: FacetConfig<T, C>,
  counts?: FacetCounts
): FilterItem[] => {
  const facetMap = new Map<string, FilterItem>();
  const childrenMap = new Map<string, Map<string, FilterItem>>();

  // First pass: collect all possible facets and their total counts
  allItems.forEach((item) => {
    const parentId = config.getId(item);
    
    // Handle parent facet
    if (!facetMap.has(parentId)) {
      facetMap.set(parentId, {
        id: parentId,
        key: config.key as FilterKeys,
        name: config.getName(item),
        filteredCount: 0,
        count: counts?.['total']?.[parentId] ?? 0,
        children: [],
        extraProps: config.extraProps?.(item),
      });
    }

    // Handle children
    if (config.getChildren) {
      if (!childrenMap.has(parentId)) {
        childrenMap.set(parentId, new Map<string, FilterItem>());
      }

      const children = config.getChildren(item);
      children.forEach(child => {
        // Use child's own id directly instead of applying getId
        const childId = child.id;
        const childMap = childrenMap.get(parentId)!;
        
        if (!childMap.has(childId)) {
          childMap.set(childId, {
            id: childId,
            key: `${config.key}` as FilterKeys,
            name: child.name,
            filteredCount: 0,
            count: counts?.['total']?.[childId] ?? 0,
            children: [],
            extraProps: child.extraProps,
          });
        }
      });
    }
  });

  // Second pass: update filtered counts
  items.forEach((item) => {
    const id = config.getId(item);
    const facet = facetMap.get(id);
    
    if (facet) {
      facet.filteredCount = counts?.['filtered']?.[id] ?? (facet.filteredCount + 1);

      // Update children filtered counts
      if (config.getChildren) {
        const children = config.getChildren(item);
        const childMap = childrenMap.get(id);
        
        children.forEach(child => {
          const childId = `${id}.${child.id}`;
          const childFacet = childMap?.get(childId);
          
          if (childFacet) {
            childFacet.filteredCount = counts?.filtered?.[childId] ?? 
              (childFacet.filteredCount + 1);
          }
        });
      }
    }
  });

  // Combine parents with their children
  facetMap.forEach((facet) => {
    const childMap = childrenMap.get(facet.id);
    if (childMap) {
      facet.children = Array.from(childMap.values());
    }
  });

  return Array.from(facetMap.values());
};


const convertFacetsToFilterItems = (
  facets: FilterItem[],
  keyPrefix: string,
  sortFn: (a: FilterItem, b: FilterItem) => number = sortByName
): FilterItem[] => {
  const convertSingle = (facet: FilterItem, parentId?: string): FilterItem => ({
    key: (parentId ? `${keyPrefix}` : keyPrefix) as FilterKeys,
    id: parentId ? `${parentId}.${facet.id}` : facet.id,
    name: facet.name,
    count: facet.count,
    filteredCount: facet.filteredCount,
    children:
      facet.children
        ?.map((child) => convertSingle(child, facet.id))
        .sort(sortFn) ?? [],
    extraProps: facet.extraProps,
  });

  return facets.map((facet) => convertSingle(facet)).sort(sortFn);
};

export const createFacetConverter = <T>(
  config: FacetConfig<T>,
  sortFn?: (a: FilterItem, b: FilterItem) => number
) => {
  return (
    filteredItems: T[],
    allItems: T[],
    counts?: FacetCounts
  ): FilterItem[] => {
    const facets = createFacet(filteredItems, allItems, config, counts);
    return convertFacetsToFilterItems(facets, config.key, sortFn);
  };
};

export interface FilterState {
  filteredItems: ReportTestItem[];
  allItems: ReportTestItem[];
  counts: FilterCounts;
}

export const getFacetCounts = (
  key: FilterKeys,
  state: FilterState
): FacetCounts => state.counts[key];
