import { Button } from "@/components/ui/button";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { ImageType } from "@/types/Images";
import { ChevronLeft, ChevronRight, X, ZoomIn, ZoomOut } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";


interface PhotoViewerDialogProps {
    images: ImageType[];
    isOpen: boolean;
    initialIndex?: number;
    onClose: () => void;
}

export function PhotoViewerDialog({ images, initialIndex = 0, isOpen = false, onClose }: PhotoViewerDialogProps) {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(initialIndex);
    const [scale, setScale] = useState(1);
    const selectedThumbRef = useRef<HTMLButtonElement>(null);

    function Toolbar() {
        const { zoomIn, zoomOut } = useControls();
        
        return (
            <div className="flex justify-end p-2 gap-2 z-10 bg-black/80 backdrop-blur-sm border-b border-white/10">
                <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => zoomOut(0.25)}
                    className="text-white hover:bg-white/20"
                >
                    <ZoomOut className="h-4 w-4" />
                </Button>
                <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => zoomIn(0.25)}
                    className="text-white hover:bg-white/20"
                >
                    <ZoomIn className="h-4 w-4" />
                </Button>
                <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => onClose?.()}
                    className="text-white hover:bg-white/20"
                >
                    <X className="h-4 w-4" />
                </Button>
            </div>
        );
    }


    useEffect(() => {
        setSelectedIndex(initialIndex);
    }, [initialIndex]);

    useEffect(() => {
        if (selectedThumbRef.current) {
            selectedThumbRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    }, [selectedIndex]);

    useEffect(() => {
        console.debug('PhotoViewerDialog', { isOpen, initialIndex });
        if (isOpen) {
            setSelectedIndex(initialIndex);
        } else {
            setSelectedIndex(null);
            setScale(1);
        }
    }, [isOpen, initialIndex]);

    const showNext = useCallback(() => {
        if (!isOpen || selectedIndex === null) return;
        setScale(1);
        setSelectedIndex((selectedIndex + 1) % images.length);
    }, [selectedIndex, images.length]);

    const showPrevious = useCallback(() => {
        if (!isOpen || selectedIndex === null) return;
        setScale(1);
        setSelectedIndex(selectedIndex === 0 ? images.length - 1 : selectedIndex - 1);
    }, [selectedIndex, images.length]);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (!isOpen || selectedIndex === null) return;

        switch (event.key) {
            case 'ArrowRight':
                showNext();
                break;
            case 'ArrowLeft':
                showPrevious();
                break;
            case '+':
                setScale(prev => Math.min(prev + 0.25, 3));
                break;
            case '-':
                setScale(prev => Math.max(prev - 0.25, 0.5));
                break;
        }
    }, [showNext, showPrevious, selectedIndex]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-5xl max-h-[90vh] h-[90vh] p-0">
                <div className="relative  flex flex-col h-full w-full bg-black/95 rounded-lg">
                    {/* Navigation buttons */}
                    <div className="flex justify-end p-1 gap-2 z-10 h-12 pr-10">
                        {/* <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => zoomOut(0.25)}
                            className="text-white hover:bg-white/20"
                        >
                            <ZoomOut className="h-4 w-4" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => zoomIn(0.25)}
                            className="text-white hover:bg-white/20"
                        >
                            <ZoomIn className="h-4 w-4" />
                        </Button> */}
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => onClose?.()}
                            className="text-white hover:bg-white/20"
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    </div>

                    <div className="flex-1 relative min-h-0">
                        {selectedIndex !== null && (
                            <TransformWrapper
                                initialScale={1}
                                minScale={0.5}
                                maxScale={3}
                                centerOnInit={true}
                                wheel={{ step: 0.25 }}
                                onZoomStop={(ref) => {
                                    setScale(ref.state.scale);
                                }}
                            >
                                <TransformComponent
                                    wrapperClass="!w-full !h-full absolute inset-0"
                                    contentClass="!w-full !h-full"
                                >
                                    <img
                                        src={images[selectedIndex]?.uri || images[selectedIndex]?.thumbnail_url}
                                        alt={images[selectedIndex]?.name}
                                        className="max-w-full max-h-full w-auto h-auto object-contain absolute inset-0 m-auto"
                                        draggable={false}
                                    />
                                </TransformComponent>
                            </TransformWrapper>
                        )}
                    </div>
                    {selectedIndex !== null && (
                        <div className="flex flex-col gap-2 bg-black/80 backdrop-blur-sm border-t border-white/10 p-2">
                            {/* Navigation and counter */}
                            <div className="flex items-center justify-center gap-4">
                                {images.length > 1 && (
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="text-white hover:bg-white/20"
                                        onClick={showPrevious}
                                    >
                                        <ChevronLeft className="h-5 w-5" />
                                    </Button>
                                )}
                                <span className="text-white text-sm font-medium">
                                    {selectedIndex + 1} / {images.length}
                                </span>
                                {images.length > 1 && (
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="text-white hover:bg-white/20"
                                        onClick={showNext}
                                    >
                                        <ChevronRight className="h-5 w-5" />
                                    </Button>
                                )}
                            </div>

                            {/* Thumbnail strip */}
                            {images.length > 1 && (
                                <ScrollArea className="w-full whitespace-nowrap" >
                                    <div className="flex w-96 space-x-4 p-4">
                                        {images.map((image, index) => (
                                            <figure key={image.id} className="shrink-0">
                                                <button
                                                    ref={selectedIndex === index ? selectedThumbRef : null}
                                                    key={image.id}
                                                    onClick={() => {
                                                        setSelectedIndex(index);
                                                        setScale(1);
                                                    }}
                                                    className={`relative h-16 w-16 aspect-square rounded-lg overflow-hidden transition-all 
                                                    ${selectedIndex === index
                                                            ? 'ring-1 ring-white ring-offset-2 ring-offset-black'
                                                            : 'opacity-50 hover:opacity-100'}`}
                                                >
                                                    <img
                                                        src={image.thumbnail_url || image.uri}
                                                        alt={image.name}
                                                        className="absolute inset-0 aspect-square h-full w-full object-cover"
                                                    />
                                                </button>
                                            </figure>
                                        ))}
                                    </div>
                                    <ScrollBar orientation="horizontal" />
                                </ScrollArea>
                            )}
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}
