import React from 'react';

import { ValidationErrors } from '@/app/features/reports/types/missing-data';
import { ExclusionReasons, ReportTestItem } from '@/app/features/reports/utils/entities';
import { TestSuite } from 'store/src/lib/tests/entity';

type TestTileContextValue = {
    item: ReportTestItem;
    testSuite: TestSuite;
    exclusionToggleOperable: boolean;
    missingData: ValidationErrors;
    hasMissingData: boolean;
    showMissingData: boolean;
    hasFastFillErrors: boolean;
    showFastFillQA: boolean;
    hasUnmatchedSegments: boolean;
    status: string;
    exclusionReason: ExclusionReasons;
}

export const TestTileContext = React.createContext<TestTileContextValue>({} as TestTileContextValue);

export const useTestTileContext = (): TestTileContextValue => {
    const context = React.useContext(TestTileContext);
    
    if (!context) {
        throw new Error('useTestTileContext must be used within a TestTileContext.Provider');
    }
    
    return context;
};
