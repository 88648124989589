import NotificationBadge from '@/components/raytd/notification-badge'
import { Toggle } from '@/components/ui/toggle'
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import { cn } from '@/lib/utils'
import { FileQuestion, Info, X } from 'lucide-react'
import React, { useState } from 'react'
import { TestCounts } from '../utils/entities'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import CountPill from './excluded-count-pill';

interface ExcludedTestsBadgeProps {
    counts: TestCounts
}

export function ExcludedTestsBadge({ counts }: ExcludedTestsBadgeProps) {
    const excludedCount = counts?.total - counts?.included;
    //    if (excludedCount <= 0) return null

    return (
        <div>

            <Popover>
                <PopoverTrigger>

                    <NotificationBadge
                        className="bg-tests-status text-white font-normal -right-3 z-50"
                        label={excludedCount}
                        show={excludedCount > 0}>
                        <Button
                            aria-label="Excluded Tests"
                            variant="outline"
                            //onPressedChange={() => setShowFastQA(prev => !prev)}
                            className={cn(
                                "flex flex-row justify-between items-center gap-1",
                                excludedCount > 0 && 'text-zinc-600 bg-white',
                                excludedCount == 0 && 'text-zinc-300 bg-white'
                            )}
                        >
                            <FileQuestion className="h-4 w-4" />
                            Excluded Tests
                        </Button>
                    </NotificationBadge>
                </PopoverTrigger>
                <PopoverContent className="bg-white border border-zinc-200 p-2 shadow-lg w-96" align='end'>
                    <ExcludedTestsBanner tests={counts} vertical />
                </PopoverContent>
            </Popover>

        </div>

    )
}

export const ExcludedTestsBanner = ({ tests, vertical = false }: { tests: TestCounts; vertical?: boolean }) => {
    return (
        <div className="">
            <div className="flex justify-between items-center bg-orange-400/25 my-4 p-4 rounded-md">
                <div className="flex-1 flex flex-row justify-between items-center">
                    <div className="text-sm text-foreground">Excluded Tests are not included in data analysis.</div>
                    {!vertical && (
                        <div className="flex flex-row gap-1 items-end justify-center">

                            <CountPill count={tests?.fastFill ?? 0} type="fastfill" />
                            <CountPill count={tests?.missingData ?? 0} type="missing" />
                            <CountPill count={tests?.status} type="status" />
                            <CountPill count={0} type="excluded" />
                            <div className="w-10 flex items-end justify-end">
                            </div>

                        </div>
                    )}
                </div>
            </div>

            {
                vertical && (
                    <div className="flex flex-col space-y-3 text-sm">
                        <div className="flex flex-row gap-3 items-center">
                            <CountPill count={tests?.unmatchedSegments ?? 0} type="fastfill" className="flex-shrink-0" />
                            <div>The Test includes text that does not match a pre-approved Fast Fill response.</div>
                        </div>
                        <div className="flex flex-row gap-3 items-center">
                            <CountPill count={tests?.missingData ?? 0} type="missing" className="flex-shrink-0" />
                            <div>The Test includes compulsory data fields that have not been completed.</div>
                        </div>
                        <div className="flex flex-row gap-3 items-center">
                            <CountPill count={tests?.qa + tests?.archived + tests?.draft} type="status" className="flex-shrink-0" />
                            <div>The Test is currently in QA, Draft or Archived status has not been saved as Final.</div>
                        </div>
                        <div className="flex flex-row gap-3 items-center">
                            <CountPill count={tests?.manuallyExcluded} type="excluded" className="flex-shrink-0" />
                            <div className="flex-1">The Test has been manually excluded from publishing by an Author of this report.</div>
                        </div>
                    </div>
                )
            }

        </div>

    )
}