import React from 'react'
import { FormLabel } from "@/components/ui/form"
import { cn } from "@/lib/utils"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { InfoIcon } from "lucide-react"

interface FormLabelWithIndicatorProps extends React.HTMLAttributes<HTMLLabelElement> {
  label: string
  isValid: boolean
  required: boolean
  info?: string
  className?: string
  tooltipSide?: "top" | "right" | "bottom" | "left"
  validationMessage?: string;
  hideRequiredIndicator?: boolean;
}

export const FormLabelWithIndicator = React.memo(({
  label,
  isValid,
  required,
  info,
  className,
  tooltipSide = "top",
  validationMessage,
  hideRequiredIndicator = false,
  ...props
}: FormLabelWithIndicatorProps) => {
  const indicatorColor = required && !isValid
    ? "bg-destructive"
    : isValid && required
      ? "bg-green-500"
      : "bg-zinc-300"


  // console.debug('FormLabelWithIndicator: ', { label, isValid, required, color: required&&!isValid });

  const getAriaLabel = React.useMemo(() => {
    const states: string[] = [];
    if (required) states.push('required');
    if (validationMessage) {
      states.push(validationMessage);
    } else if (!isValid && required) {
      states.push('invalid');
    } else if (isValid && required) {
      states.push('valid');
    }
    return states.length ? `${label} (${states.join(', ')})` : label;
  }, [label, required, isValid, validationMessage]);

  return (
    <FormLabel
      className={cn(
        "flex items-center gap-2",
        required && !isValid ? "text-foreground" : "text-foreground",
        className
      )}
      {...props}
      onClick={(e) => e.preventDefault()}
      aria-label={getAriaLabel}
    >
      <span
        className={cn(
          "h-2 w-2 rounded-full transition-colors -ml-3",
          indicatorColor
        )}
        aria-hidden="true"
      />
      <span className="truncate">{label}</span>
      {required && !hideRequiredIndicator && (
        <span
          className="text-destructive ml-1 sr-only"
          aria-hidden="true"
        >
          *
        </span>
      )}
      {info && (
        <Tooltip delayDuration={300}>
          <TooltipTrigger asChild>
            <button
              type="button"
              className="inline-flex items-center"
              aria-label={`More information about ${label}`}
              onClick={(e) => e.preventDefault()}
            >
              <InfoIcon
                className="h-4 w-4 text-muted-foreground cursor-help"
                aria-hidden="true"
              />
            </button>
          </TooltipTrigger>
          <TooltipContent side={tooltipSide}>
            {info}
          </TooltipContent>
        </Tooltip>
      )}
    </FormLabel>
  )
});

FormLabelWithIndicator.displayName = 'FormLabelWithIndicator';