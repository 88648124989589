import { TestSuite } from 'store/src/lib/tests/entity';
import { FilterItem, ReportTestItem } from './entities';
import sortByName from './sortByName';
import { convertRatingToScore } from '@/app/features/reports/ratingHelpers';
import { createFacet, FacetChild, FacetConfig, FacetCounts } from './facets';

export const convertTestsToExclusionFacets = (
  filteredItems: ReportTestItem[],
  allItems: ReportTestItem[],
  counts?: FacetCounts
): FilterItem[] => {
  if (!allItems?.length) return [];

    return [
      {
        id: 'included',
        key: 'excluded',
        name: 'Included Tests',
        filteredCount: counts?.filtered?.['included'],
        count: counts?.total?.['included'],
        children: [],
      },{
      id: 'manuallyExcluded',
      key: 'excluded',
      name: 'Excluded by Report Author',
      filteredCount: counts?.filtered?.['excluded'],
      count: counts?.total?.['excluded'],
      children: [],
    },{
        id: 'status',
        key: 'excluded',
        name: 'Excluded by Save Status',
        filteredCount: counts?.filtered?.['status'],
        count: counts?.total?.['status'],
        children: [
          {
            id: 'status.qa',
            key: 'excluded',
            name: 'QA',
            count: 0,
            filteredCount: 0,
            children: [],
          },
          {
            id: 'status.draft',
            key: 'excluded',
            name: 'Draft',
            count: 0,
            filteredCount: 0,
            children: [],
          },
          {
            id: 'status.archived',
            key: 'excluded',
            name: 'Archived',
            count: 0,
            filteredCount: 0,
            children: [],
          },
        ],
    }, {
        id: 'unmatchedSegments',
        key: 'excluded',
        name: 'Excluded by Fast Fill',
        filteredCount: counts?.['total']?.['unmatchedSegments'],
        count: counts?.['total']?.['unmatchedSegments'],
        children: [],

    },{
        id: 'missingData',
        key: 'excluded',
        name: 'Excluded by Missing Data',
        filteredCount: counts?.filtered?.['missingData'],
        count: counts?.total?.['missingData'],
        children: [],
    }]


};
export default convertTestsToExclusionFacets;

export const exclusionKeyExtractors = [
  (item: ReportTestItem) => item.switches?.include !== true ? 'manuallyExcluded' : null,
  (item: ReportTestItem) => item.status !== 'published' ? 'status' : null,
  (item: ReportTestItem) => item.hasUnmatchedSegments ? 'unmatchedSegments' : null,
  (item: ReportTestItem) => item.hasMissingData ? 'missingData' : null,
];