import { cn } from "@/lib/utils";
import { ImageOffIcon, Loader2, UploadIcon, XIcon } from "lucide-react";
import { useCallback, useEffect, forwardRef, useState, useImperativeHandle, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "sonner";
import { UploadPreview } from './image-preview';
import { Progress } from "./progress";
import { MediaInfo, UploadItem } from "./types";
import { useUpload } from './useUpload';
import { formatFileSize } from "@/components/image-upload/utils";
import { ExistingMediaPreview } from './existing-media-preview';
import { rootStore } from "@app.raytd.com/store";
import { ImageType } from "@/types/Images";
import { usePhotoViewer } from "@/app/contexts/PhotoViewerContext";


// Batch availability check
const checkBatchAvailability = async (mediaList: MediaInfo[]) => {
  const checks = await Promise.allSettled(
    mediaList.map(media =>
      // fetch(media.uri, { 
      //   method: 'HEAD',
      //   mode: 'no-cors', // Required for CORS
      //   credentials: 'include' // If using cookies
      // })
      new Promise((resolve, reject) => {
        resolve({ ok: true });
      })
        //@ts-expect-error
        .then(r => r.ok)
        .catch((errr) => {
          console.debug('Error checking media availability', errr);
          return false;
        })
    )
  );

  console.debug('checks', checks);

  return checks.map(check =>
    check.status === 'fulfilled' ? check.value : false
  );
};

interface FileUploaderProps {
  formId: string;
  onChange: (media: MediaInfo[]) => void;
  value?: MediaInfo[];
  maxFiles?: number;
  maxSize?: number;
  acceptedTypes?: string[];
  className?: string;
  disabled?: boolean;
}

export const FileUploader = forwardRef<HTMLDivElement, FileUploaderProps>(({
  formId,
  value = [],
  onChange,
  maxFiles = 10,
  maxSize = 20000000,
  acceptedTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"],
  className,
  disabled = false
}, ref) => {

  const containerRef = useRef<HTMLDivElement>(null);

  const [processedUploads] = useState<Set<string>>(new Set());
  const queueRef = useRef<Set<string>>(new Set());
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  // Forward the ref and add focus handling
  useImperativeHandle(ref, () => ({
    ...containerRef.current,
    focus: () => {
      containerRef.current?.focus();
      // containerRef.current?.querySelector('input')?.click();
    }
  }));


  const { uploads, hasActiveUploads, handleUpload: internalHandleUpload, removeUpload, toggleDeleteUpload, totalProgress } = useUpload(formId);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [previewUrls, setPreviewUrls] = useState<Record<string, string>>({});
  const activeMediaCount = value.filter(media => !media.deleted).length;
  const totalFiles = activeMediaCount + uploads.length;

  const handleUpload = useCallback((file: File) => {
    const uploadId = internalHandleUpload(file);
    queueRef.current.add(uploadId);
    return uploadId;
  }, [internalHandleUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (disabled) return;

      // Handle file rejections
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach(error => {
          switch (error.code) {
            case 'file-too-large':
              toast.error(`${file.name} is too large. Maximum size is ${formatFileSize(maxSize)}`);
              break;
            case 'file-invalid-type':
              toast.error(`${file.name} has an invalid file type. Accepted types: ${acceptedTypes.join(', ')}`);
              break;
            default:
              toast.error(`Error uploading ${file.name}: ${error.message}`);
          }
        });
      });

      // Check total files limit
      if (totalFiles + acceptedFiles.length > maxFiles) {
        toast.error(`Maximum ${maxFiles} files allowed`);
        return;
      }

      // Create preview URLs and start uploads
      acceptedFiles.forEach(file => {
        const previewUrl = URL.createObjectURL(file);
        setPreviewUrls(prev => ({ ...prev, [file.name]: previewUrl }));
        handleUpload(file);
      });

      // console.debug('value', value);
      // onChange(acceptedFiles);
      // onChange(value);
    },
    // accept: {
    //   'image/*': acceptedTypes
    // },
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.webp']
    },
    maxSize,
    maxFiles: maxFiles - uploads.length,
    disabled,
    noClick: disabled,
    noDrag: disabled
  });

  // Cleanup preview URLs on unmount
  useEffect(() => {
    return () => {
      // Cleanup preview URLs
      Object.values(previewUrls).forEach(URL.revokeObjectURL);
      
      // Cleanup pending uploads
      queueRef.current.forEach(uploadId => {
        removeUpload(uploadId);
      });
    };
  }, []);

  // useEffect(() => {
  //   const completedUploads = uploads.filter(upload => upload.status === 'completed');
  //   const newMedia = completedUploads
  //     .map(upload => upload.mediaInfo)
  //     .filter(media => media && !value.some(item => item.id === media.id));

  //   if (newMedia.length > 0) {
  //     //maybe removeUpload to remove it from the uploads list
  //     newMedia.forEach(media => {
  //       removeUpload(media.id.toString());
  //     })
  //     onChange([...value, ...newMedia]);
  //   }
  // }, [uploads, onChange, value]);

   // Enhanced upload processing
   useEffect(() => {
    const processCompletedUploads = async () => {
      // Get unprocessed completed uploads
      const completedBatch = uploads.filter(u => 
        u.status === 'completed' && 
        !processedUploads.has(u.id)
      );

      if (!completedBatch.length) return;

      console.debug('Processing batch:', {
        batchSize: completedBatch.length,
        currentValue: valueRef.current,
        uploads: uploads
      });

      // Prepare new media batch
      const newMedia = completedBatch
        .map(u => ({...u.mediaInfo, uploadId: u.id}))
        .filter((media): media is MediaInfo & {uploadId: string} => {
          if (!media) return false;
          const isDuplicate = valueRef.current.some(m => m.id === media.id);
          console.debug('Media check:', {
            mediaId: media?.id,
            isDuplicate,
            exists: !!media
          });
          return !!media && !isDuplicate;
        });

      if (newMedia.length === 0) {
        console.debug('No new media to process');
        return;
      }

      try {
        // Verify media availability
        const availability = await checkBatchAvailability(newMedia);
        const validMedia = newMedia.filter((_, i) => availability[i]);

        if (validMedia.length > 0) {
          // Prepare atomic update
          const updatedValue = [...valueRef.current, ...validMedia];
          
          // Update form state
          onChange(updatedValue);

          // Clean up processed uploads after state update
          setTimeout(() => {
            validMedia.forEach(media => {
              removeUpload(media.uploadId);
              queueRef.current.delete(media.uploadId);
            });
          }, 100); // Small delay to ensure state updates complete
        }

        // Handle failed verifications
        const failedMedia = newMedia.filter((_, i) => !availability[i]);
        if (failedMedia.length) {
          console.error('Failed media verification:', failedMedia);
          toast.error(`${failedMedia.length} uploads failed verification`);
        }

      } catch (error) {
        console.error('Upload processing error:', error);
        toast.error('Failed to process uploads');
      }
    };

    processCompletedUploads();
  }, [uploads, onChange, removeUpload]);

  // Add this to your component
  useEffect(() => {
    const subscription = rootStore.subscribe(() => {
      console.log('Current uploads:', rootStore.getState().uploads.uploads);
    });
    return () => subscription();
  }, []);

  const handleToggleMediaDelete = useCallback((mediaId: number) => {
    const updatedMedia = value.map(media =>
      media.id === mediaId
        ? { ...media, deleted: !media.deleted }
        : media
    );
    onChange(updatedMedia);
  }, [value, onChange]);

  const handleToggleUploadDelete = useCallback((uploadId: string) => {
    const upload = uploads.find(u => u.id === uploadId);

    if (upload?.mediaInfo) {
      // Dispatch the toggle action
      toggleDeleteUpload(uploadId);

      // If we're managing form state, update it
      if (onChange) {
        const currentImages = value;
        const updatedImages = currentImages.map(img =>
          img.id === upload.mediaInfo?.id
            ? { ...img, deleted: !img.deleted }
            : img
        );
        onChange(updatedImages);
      }
    }
  }, [uploads, onChange, value]);

  const [previewImages, setPreviewImages] = useState<MediaInfo[]>([]);
  const { openPhotoViewer } = usePhotoViewer();

  useEffect(() => {
    setPreviewImages(value?.filter((img: MediaInfo) => !img.deleted) || []);
  }, [value]);


  const handlePreview = useCallback((id: number) => {
    const index = previewImages.findIndex(img => img.id === id);
    openPhotoViewer(previewImages, index);
  }, [openPhotoViewer, previewImages]);

  return (
    <div
      ref={containerRef}
      tabIndex={0}
      className={className}
      onKeyDown={(e) => {
        // Handle space/enter to open file dialog
        if (e.key === ' ' || e.key === 'Enter') {
          e.preventDefault();
          containerRef.current?.querySelector('input')?.click();
        }
      }}
    >
      <div
        {...getRootProps()}
        className={cn(
          "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4",
          "min-h-[120px] p-4 rounded-lg transition-colors",
          {
            "border-2 border-dashed": isDragActive || isDraggingOver,
            "border-primary bg-primary/10": isDraggingOver,
            "border-primary": isDragActive,
          },
          "hover:border-primary/50",
          className
        )}
        style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(64px, 1fr))' }}
      >
        {/* Upload Button Placeholder */}
        <div
          className={cn(
            "aspect-square rounded-md bg-gray-50 cursor-pointer",
            "flex flex-col items-center justify-center gap-2",
            "hover:bg-gray-100 transition-colors"
          )}
        >
          <UploadIcon className="h-6 w-6 text-gray-400" />
          <p className="text-xs text-gray-500">
            {`${totalFiles}/${maxFiles}`}
          </p>
          <input {...getInputProps()} />
        </div>

        {/* Existing Media */}
        {value
          .map((media) => (
            <ExistingMediaPreview
              key={media.id}
              media={media}
              onRemove={() => handleToggleMediaDelete(media.id)}
              onClick={handlePreview}
              disabled={disabled}
            />
          ))}

        {/* Upload Previews */}
        {uploads.map((upload) => (
          <UploadPreview
            key={upload.id}
            upload={upload}
            previewUrl={previewUrls[upload.fileName]}
            onRemove={disabled ? undefined : () => handleToggleUploadDelete(upload.id)}
          />
        ))}

        {/* Upload Progress Indicator */}
        {hasActiveUploads && (
          <div className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4 z-50">
            <Progress
              value={totalProgress}
              size="sm"
              indicateProgress
              className="w-[200px]"
            />
          </div>
        )}
      </div>
    </div>
  );

});

FileUploader.displayName = 'FileUploader';
