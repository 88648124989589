import { FileQuestion } from 'lucide-react';
import { Toggle } from '@/components/ui/toggle';
import NotificationBadge from '@/components/raytd/notification-badge';
import { cn } from '@/lib/utils';

interface ToggleWithBadgeProps {
  label: string;
  count: number;
  isActive: boolean;
  onToggle: () => void;
  badgeClassName?: string;
  activeClassName?: string;
}

const ToggleWithBadge = ({
  label,
  count,
  isActive,
  onToggle,
  badgeClassName,
  activeClassName,
}: ToggleWithBadgeProps) => {
  return (
    <div>
      <NotificationBadge
        className={cn("text-white font-normal -right-3 z-50", badgeClassName)}
        label={count}
        show={count > 0}
      >
        <Toggle
          aria-label={`${label} QA`}
          variant="primary"
          onPressedChange={onToggle}
          className={cn(
            "flex flex-row justify-between items-center gap-1",
            isActive && count > 0 && activeClassName
          )}
        >
          <FileQuestion className="h-4 w-4" />
          {label}
        </Toggle>
      </NotificationBadge>
    </div>
  );
};

export default ToggleWithBadge;