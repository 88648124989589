import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import React from 'react';
import { useTestTileContext } from '../test-tile/context';
import useReportViewerContext from '../report-viewer-context';
const MissingValue = ({ field: field_id }) => {

    const { missingData, hasMissingData } = useTestTileContext();
    const { showMissingData } = useReportViewerContext();

    console.debug('test tile missing data', { field_id, missingData: missingData[field_id], hasMissingData });

    if (showMissingData && hasMissingData && missingData?.[field_id]) {
        return (
            <MissingPlaceHolder />
        )
    }

    return <MissingBadge />
}

export default MissingValue;

export const MissingBadge = React.memo(({ className }: { className?: string; }) => {
    return <Badge variant="outline" className={cn("", className)}>Missing</Badge>
});

export const MissingPlaceHolder = React.memo((className: { className?: string; }) => {
    return <div className={cn("w-full flex-1 bg-red-400 rounded-sm", className)}>&nbsp;</div>
});