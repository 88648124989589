import { Provider } from 'react-redux';
import { rootStore } from '@app.raytd.com/store';
import * as ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import App from './app/app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


Sentry.init({
  dsn: "https://e7819091b37fa0dbea4d0c0b806c5199@o4506079794298880.ingest.us.sentry.io/4508061371400192",
  integrations: [
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
});

root.render(
  <Provider store={rootStore}>
        <App />
  </Provider>
);
