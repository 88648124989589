"use client"

import * as React from "react"
import { Check, ChevronsUpDown, X } from 'lucide-react'
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { FormLabelWithIndicator } from './form-label-with-indicator'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Badge } from "@/components/ui/badge"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Path, type Control } from "react-hook-form"
import type { TestFormValues } from "../types/test-form"

interface Option {
  value: string;
  label: string;
}

interface SelectFieldProps<T> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  options: Option[];
  required?: boolean;
  placeholder?: string;
  info?: string;
  disabled?: boolean;
  labelComponent?: React.ReactNode;
  description?: string;
  className?: string;
  isValid?: boolean;
  multiple?: boolean;
  allowAdd?: boolean;
  onCreateOption?: (value: string) => void;
}

export function SelectField<T>({
  control,
  name,
  label,
  options,
  required = false,
  placeholder = "Select...",
  disabled = false,
  info,
  labelComponent,
  description,
  className,
  isValid = true,
  multiple = false,
  allowAdd = true,
  onCreateOption,
}: SelectFieldProps<T>) {

  const [inputValue, setInputValue] = React.useState("");

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          {labelComponent ||
            <FormLabelWithIndicator
              label={label}
              required={required}
              isValid={isValid}
              info={info}
            />
          }
          <Popover modal={true}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  disabled={disabled}
                  className={cn(
                    "w-full justify-between h-full bg-transparent",
                    !field.value && "text-muted-foreground",
                    "hover:bg-transparent"
                  )}
                >
                  {multiple ? (
                    field.value && Array.isArray(field.value) && field.value.length > 0 ? (
                      <div className="flex flex-col gap-1 items-start">
                        {field.value.map((value) => (
                          <Badge
                            key={value}
                            variant="secondary"
                            className="p-2 pr-1"
                          >
                            {options.find((option) => option.value === value)?.label || value}
                            <Button
                              variant="ghost"
                              className="h-4 w-4 p-0 hover:bg-transparent ml-1"
                              onClick={(e) => {
                                e.stopPropagation();
                                const newValue = (field.value as string[]).filter((v: string) => v !== value);
                                field.onChange(newValue, { shouldValidate: true });
                              }}
                            >
                              <X className="h-3 w-3 text-zinc-500 hover:text-zinc-900" />
                            </Button>
                          </Badge>
                        ))}
                      </div>
                    ) : (
                      <span>{placeholder}</span>
                    )
                  ) : (
                    field.value ?
                      options.find((option) => option.value === field.value)?.label
                      : placeholder
                  )}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="w-[var(--radix-popover-trigger-width)] p-0"
              modal={true}
            >
              <Command>
                <CommandInput
                  placeholder={`Search ${label.toLowerCase()}...`}
                  value={inputValue}
                  onValueChange={setInputValue}
                />

                <CommandEmpty>
                  {allowAdd && onCreateOption ? (
                    <Button
                      variant="ghost"
                      className="w-full justify-start p-2"
                      onClick={() => {
                        onCreateOption(inputValue);
                        setInputValue("");
                      }}
                    >
                      Create "{inputValue}"
                    </Button>
                  ) : (
                    `No matches found.`
                  )}
                </CommandEmpty>

                <CommandGroup>
                  <ScrollArea className="h-[200px]">
                    {options.map((option) => (
                      <CommandItem
                        key={option.value}
                        onSelect={() => {
                          if (multiple) {
                            const currentValue = Array.isArray(field.value) ? field.value : [];
                            const newValue = currentValue.includes(option.value)
                              ? currentValue.filter((value) => value !== option.value)
                              : [...currentValue, option.value];
                            field.onChange(newValue, { shouldValidate: true });
                          } else {
                            field.onChange(option.value === field.value ? "" : option.value, { shouldValidate: true });
                          }
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            multiple
                              ? Array.isArray(field.value) && field.value.includes(option.value)
                                ? "opacity-100"
                                : "opacity-0"
                              : field.value === option.value
                                ? "opacity-100"
                                : "opacity-0"
                          )}
                        />
                        {option.label}
                      </CommandItem>
                    ))}
                  </ScrollArea>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </FormItem>
      )}
    />
  )
}

